import {ChangeEvent, FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, SelectChangeEvent} from '@mui/material';
import {FieldArray, Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';

// Shared
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import NumericInput from 'shared/components/NumericInput';
import TextInput from 'shared/components/TextInput';

// Core
import {sourcesTypeOptions} from 'core/utils/selectOptions/sourcesTypeOptions';
import {dateRange, MaxValidationLimits} from 'core/_consts';
import {Source} from 'core/_types/selectOptionsTypes/SourcesType';
import {SourceTypeValues} from 'core/enums/sourceTypeValues';
import {statusMessage} from 'core/enums/statusMessage';
import {dateChecker} from 'core/helpers/dateChecker';

// Store
import {updateSources} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';
import {setUpdatedSourcesAndUses} from 'store/project-service/projectSlice';

import s from '../SourcesAndUses.module.scss';

type SourcesFormProps = {
  values: {sources: Array<Source>};
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: {sources: Array<Source>};
  totalSources: string;
};

export const SourcesForm: FC<SourcesFormProps> = ({
  values,
  handleChange,
  setFieldValue,
  initialValues,
  totalSources,
}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateSources({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedSourcesAndUses(statusMessage.saving));
    }
  }, [values]);

  return (
    <Form>
      <p className={s.Sources__groupTitle}>Sources</p>
      <FieldArray name="sources">
        {({remove, push}) => (
          <Grid container columnSpacing={2.5} rowSpacing={3} marginBottom="24px">
            {values.sources.length > 0 ? (
              values.sources.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container columnSpacing={2.5} key={index}>
                  <Grid item xs={6} sm={3}>
                    <StepSelect
                      options={sourcesTypeOptions}
                      theme={THEMES.blue}
                      name={`sources.${index}.type`}
                      handleChange={(e?: SelectChangeEvent<any>) =>
                        e &&
                        setFieldValue(`sources.${index}.type`, {
                          value: SourceTypeValues[e.target.value.toUpperCase().replace(' ', '_')],
                          label: e.target.value,
                        })
                      }
                      value={values.sources[index].type.label}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <NumericInput
                      name={`sources.${index}.principal`}
                      onValueChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          `sources.${index}.principal`,
                          `USD ${e.target.value.toString().replace(/[\s,]/g, '')}`
                        )
                      }
                      value={+values.sources[index].principal.split(' ')[1]}
                      theme={THEMES.grey}
                      thousandSeparator
                      fullWidth
                      preventionLimit={MaxValidationLimits.highestLimit}
                      placeholder="$"
                      textAlign="left"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextInput
                      name={`sources.${index}.entity`}
                      value={values.sources[index].entity}
                      placeholder="Entity"
                      onValueChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <input
                      max={dateRange.maxDate}
                      min={dateRange.minDate}
                      name={`sources.${index}.maturityDate`}
                      onChange={handleChange}
                      value={values.sources[index].maturityDate}
                      type="date"
                      className={s.Sources__datePicker}
                    />
                    <div className={s.Sources__dateError}>{dateChecker(values.sources[index].maturityDate)}</div>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                    {values.sources.length > 1 ? (
                      <button className={s.Sources__groupBtn} type="button" onClick={() => remove(index)}>
                        Delete item
                      </button>
                    ) : null}
                    {index === values.sources.length - 1 ? (
                      <>
                        {values.sources.length > 1 && <div className={s.Sources__groupDivider} />}
                        <button
                          className={s.Sources__groupBtn}
                          type="button"
                          onClick={() =>
                            push({type: {value: '', label: ''}, principal: '', entity: '', maturityDate: ''})
                          }
                        >
                          Add an item
                        </button>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <button
                  className={s.Sources__groupBtn}
                  type="button"
                  onClick={() => push({type: {value: '', label: ''}, principal: '', entity: '', maturityDate: ''})}
                >
                  Add an item
                </button>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className={s.Sources__total}>Total Sources: ${totalSources}</p>
            </Grid>
          </Grid>
        )}
      </FieldArray>
    </Form>
  );
};

export default SourcesForm;
