import {useEffect, useState} from 'react';
import {statusMessage} from '../../enums/statusMessage';

const useStatusMessage = (form: string) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    switch (form) {
      case statusMessage.saving:
        return setMessage(statusMessage.saving);
      case statusMessage.success:
        return setMessage(statusMessage.success);
      case statusMessage.error:
        return setMessage(statusMessage.error);
      default:
        return setMessage(statusMessage.default);
    }
  }, [form]);

  return message;
};

export default useStatusMessage;
