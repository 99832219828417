import {FC, useState} from 'react';
import {requiredReservesOptions, QuoteTermsSteps, RequiredReservesSubsteps, CashManagementSubsteps} from 'core/_consts';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {setSelectRequiredReserves} from 'store/quote-terms-service/quoteTermsSlice';
import {selectSelectedRequiredReserves} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';

const handleSelectedValue = (value: Array<string>) =>
  value.length
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.requiredReserves],
        substep: RequiredReservesSubsteps[RequiredReservesSubsteps.reservesDetails],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.cashManagement],
        substep: CashManagementSubsteps[CashManagementSubsteps.isLockboxNeeded],
      };

const SelectRequiredReserves: FC = () => {
  const defaultValue = useSelector(selectSelectedRequiredReserves);
  const [selectedRequiredReserves, setSelectedRequiredReserves] = useState<Array<string>>(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Are there any required reserves?</p>
        <p className="FormSlide__subTitle mb25">Select all types that apply, or skip this step.</p>
      </div>
      <RadioGroup
        maxWidth="500px"
        additionalClassName="requiredReserves"
        isAnyValueSelected={selectedRequiredReserves}
        setIsAnyValueSelected={setSelectedRequiredReserves}
        radioButtonTitles={Object.values(requiredReservesOptions)}
        multiSelect
      />
      <NextButton
        slideData={{
          [RequiredReservesSubsteps[RequiredReservesSubsteps.selectRequiredReserves]]: selectedRequiredReserves,
        }}
        action={setSelectRequiredReserves}
        nextSlide={handleSelectedValue(selectedRequiredReserves)}
      />
    </div>
  );
};

export default SelectRequiredReserves;
