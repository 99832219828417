/* eslint-disable consistent-return */
import {Ava} from '@burnaby/ava';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

// Core
import {PersonType} from 'core/_types/base';
import {ava} from 'core/service/ava';

import {
  BrokerProfilesType,
  CapitalAdvisorProfilesType,
  getBrokerProfilesPropsType,
  getCapitalAdvisorProfilesPropsType,
  getSponsorProfilesPropsType,
  SponsorProfilesType,
  UserSettingsType,
} from './interfaces';

import 'react-toastify/dist/ReactToastify.css';

export const getCapitalAdvisorProfiles = createAsyncThunk(
  'user/getCapitalAdvisorProfiles',
  async ({usersSyms, ava}: getCapitalAdvisorProfilesPropsType): Promise<CapitalAdvisorProfilesType | void> => {
    try {
      const userProfiles = await Promise.all(usersSyms.map((item) => ava.getUserProfile(item)));
      return userProfiles;
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  }
);

export const getSponsorProfiles = createAsyncThunk(
  'user/getSponsorProfiles',
  async ({emails, ava}: getSponsorProfilesPropsType): Promise<SponsorProfilesType | void> => {
    try {
      return await Promise.all(emails.map((item) => ava.people.getByEmail(item)));
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  }
);

export const getBrokerProfiles = createAsyncThunk(
  'user/getBrokerProfiles',
  async ({usersSyms, ava}: getBrokerProfilesPropsType): Promise<BrokerProfilesType> => {
    try {
      const userProfiles = await Promise.all(usersSyms.map((item) => ava.getUserProfile(item)));
      return userProfiles;
    } catch (error: any) {
      toast.error(`${error.message}`);
      throw new Error('No users');
    }
  }
);

export const getUserSettings = createAsyncThunk(
  'user/getUserSettings',
  async (ava: Ava): Promise<UserSettingsType | void> => {
    try {
      const userSettings = await ava.getUserSettings();
      return userSettings;
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  }
);
