import {useState} from 'react';
import {useFormikContext} from 'formik';
import {Slider, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// Core
import {amortizationOptions} from 'core/utils/selectOptions/amortizationOptions';

// Shared
import {THEMES} from 'shared/helpers';
import TextArea from 'shared/components/TextArea';
import {StepSelect} from 'shared/components/StepSelect';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';

import s from './Eighth.module.scss';

const selectOptionsMock = ['Option 1', 'Option 2', 'Option 3'];

const useStyles = makeStyles({
  colorPrimary: {
    color: '#fff',
  },
});

const Eighth = () => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const [years, setYears] = useState<number[]>([5, 100]);

  const classes = useStyles();

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  return (
    <div className={s.eighth}>
      <div className={s.eighth__header}>
        <p className={s.eighth__title}>Financing Request Details</p>
        <p className={s.eighth__subtitle}>
          Total Project Budget: <span className={s.eighth__budget}>$800,000</span>
        </p>
      </div>

      <div className={s.eighth__inputsContainer}>
        <div className={s.eighth__selects}>
          <StepSelect
            placeholder="Select option"
            label="Loan Term*"
            name="loanTerm"
            value={values.loanTerm}
            options={selectOptionsMock}
            theme="white"
            handleChange={handleChange}
          />
          <StepSelect
            placeholder="Select option"
            label="Amortization"
            name="amortization"
            value={values.amortization}
            setValue={setFieldValue}
            labelTooltip={
              <>
                <h4 className={s.eighth__tooltipHeading}>Self Liquidation</h4>
                <p className={s.eighth__tooltipParagraph}>The amortization matches the loan term.</p>
                <h4 className={s.eighth__tooltipHeading}>I/O</h4>
                <p className={s.eighth__tooltipParagraph}>
                  Interest only, a period when you are paying interest but not paying down the principal balance of your
                  loan.
                </p>
              </>
            }
            options={amortizationOptions}
            theme="white"
          />
        </div>
        <div className={s.eighth__range}>
          <p className={s.eighth__years}>
            {years[0]} to {years[1]} years
          </p>
          <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Slider classes={classes} size="small" value={years} onChange={handleRangeChange} />
          </Box>
        </div>
        <div className={s.eighth__selects}>
          <StepSelect
            placeholder="Select option"
            label="Recourse"
            name="recourse"
            value={values.recourse}
            handleChange={handleChange}
            labelTooltip="Recourse means being personally liable for the loan amount."
            options={selectOptionsMock}
          />
          <StepSelect
            placeholder="Select option"
            label="Interest Rate"
            name="interestRate"
            value={values.interestRate}
            handleChange={handleChange}
            options={selectOptionsMock}
          />
        </div>
        <div className={s.eighth__textarea}>
          <TextArea
            width="620px"
            height="82px"
            name="notes"
            value={values.notes}
            onValueChange={handleChange}
            label="Financing Expectations & Notes"
            theme={THEMES.white}
          />
        </div>
      </div>
      <UnsavedChangesPrompt />
    </div>
  );
};

export {Eighth};
