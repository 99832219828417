import {useState} from 'react';
import NumericInput from 'shared/components/NumericInput';
import RadioInput from 'shared/components/RadioInput';
import NextButton from 'shared/components/NextButton';
import {QuoteTermsSteps, RecourseSubsteps, dateOptions, AmortizationSubsteps} from 'core/_consts';
import {getMaxLimit, validateNumericInput} from 'core/utils';
import {setInterestOnlyPeriodDuration} from 'store/quote-terms-service/quoteTermsSlice';
import {selectInterestOnlyPeriodDuration} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';

const PeriodDuration = () => {
  const defalutValues = useSelector(selectInterestOnlyPeriodDuration);
  const [dateValue, setDateValue] = useState(defalutValues.dateValue);
  const [dateFormat, setDateFormat] = useState(defalutValues.dateFormat);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How long is the interest only period?</p>
      <div className="FormSlide__section">
        <NumericInput
          width="60px"
          decimalScale={0}
          defaultValue={dateValue || ''}
          preventionLimit={getMaxLimit(dateFormat)}
          onValueChange={(values) => setDateValue(Number(values.value))}
          isValid={validateNumericInput(dateValue, getMaxLimit(dateFormat))}
        />
        <RadioInput
          controlledInputValue={dateValue}
          defaultValue={dateFormat}
          setRadioInputValue={setDateFormat}
          values={Object.values(dateOptions)}
        />
      </div>
      <NextButton
        isDisabled={!validateNumericInput(dateValue, getMaxLimit(dateFormat))}
        slideData={{[AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriodDuration]]: {dateValue, dateFormat}}}
        action={setInterestOnlyPeriodDuration}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.recourse],
          substep: RecourseSubsteps[RecourseSubsteps.availableRecourse],
        }}
      />
    </div>
  );
};

export default PeriodDuration;
