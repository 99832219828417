export enum QuoteTermsStepTitles {
  'Loan Amount',
  'Loan Term',
  'Interest Rate',
  'Amortization',
  'Recourse',
  'Prepay Penalty',
  'Initial Deposit',
  'Loan Fees',
  'Required Reserves',
  'Cash Management',
  'Borrower Stipulations',
  'Other Requirements',
}

export enum QuoteTermsSteps {
  loanAmount,
  loanTerm,
  interestRate,
  amortization,
  recourse,
  prepayPenalty,
  initialDeposit,
  loanFees,
  requiredReserves,
  cashManagement,
  borrowerStipulations,
  otherRequirements,
}

export enum LoanAmountSubsteps {
  loanSizingConstraints,
  dollarLoanAmount,
}

export enum LoanTermSubsteps {
  loanTermDuration,
  lengthAndFee,
  anyExtensions,
  extensionsAmount,
  customLoanTerm,
}

export enum InterestRateSubsteps {
  rateResets,
  resetYear,
  rateDetails,
  rateCalculation,
}

export enum AmortizationSubsteps {
  amortizationPeriod,
  interestOnlyPeriod,
  interestOnlyPeriodDuration,
  amount,
}

export enum RecourseSubsteps {
  availableRecourse,
  burnOffTrigger,
  burnOffAvailable,
}

export enum PrepayPenaltySubsteps {
  anyPenalty,
  penaltyType,
  anyLockout,
  lockoutDuration,
  prepaymentPenalty,
  feeAmount,
  minimumInterestPerion,
  penaltyDetails,
}

export enum LoanFeesSubsteps {
  selectLoanFees,
  feesDetails,
}

export enum RequiredReservesSubsteps {
  selectRequiredReserves,
  reservesDetails,
}

export enum CashManagementSubsteps {
  isLockboxNeeded,
  selectLockboxType,
}

export enum QuoteTermsFormStatus {
  declined = 'declined',
  closed = 'closed',
  topOpened = 'topOpened',
  bottomOpened = 'bottomOpened',
}

export enum MaxValidationLimits {
  percent = 100,
  months = 999,
  years = 999,
  thousand = 1000,
  oneHundredThousand = 100_000,
  tenMillion = 10_000_000,
  highestLimit = 999_999_999,
}

export enum SizingConstrainsLabels {
  LTC = 'LTC',
  LTV = 'LTV',
  DSCR = 'DSCR',
  DebtYield = 'Debt Yield',
}

export enum rateDetailsOptions {
  fixedPercent = 'fixed - percent',
  fixedSpread = 'fixed - spread',
  floating = 'floating',
}

export enum loanTermDurationOptions {
  oneYear = '1 year',
  twoYears = '2 years',
  threeYears = '3 years',
  fiveYears = '5 years',
  sevenYears = '7 years',
  tenYears = '10 years',
  thirtyYears = '30 years',
  fivePlusFive = '5 + 5',
  twoPlusOne = '2 + 1',
  threePlusOnePlusOne = '3 + 1 + 1',
}

export const SelectInputIndexOptions = [
  'Libor (1M)',
  'Libor (3M)',
  'Libor (6M)',
  'Libor (12M)',
  'Libor Swap (1Y)',
  'Prime',
  'US Treasury (1Y)',
  'US Treasury (3Y)',
  'US Treasury (5Y)',
  'US Treasury (7Y)',
  'US Treasury Swap (1Y)',
  'US Treasury Swap (3Y)',
  'US Treasury Swap (5Y)',
  'US Treasury Swap (7Y)',
  'US Treasury Swap (10Y)',
  'SOFR',
];

export const SelectInputFixedAtOptions = ['Signed Term Sheet', 'Commitment Letter', 'Day of Close'];
