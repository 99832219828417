import {InterestRateSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import RateCalculation from './RateCalculation';
import RateDetails from './RateDetails';
import Resets from './Resets';
import ResetYear from './ResetYear';

type InterestRateSubstepsKeys = keyof typeof InterestRateSubsteps;

const InterestRate: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<InterestRateSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as InterestRateSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case InterestRateSubsteps[InterestRateSubsteps.rateResets]:
      return <Resets />;

    case InterestRateSubsteps[InterestRateSubsteps.resetYear]:
      return <ResetYear />;

    case InterestRateSubsteps[InterestRateSubsteps.rateDetails]:
      return <RateDetails />;

    case InterestRateSubsteps[InterestRateSubsteps.rateCalculation]:
      return <RateCalculation />;

    default:
      return null;
  }
};

export default InterestRate;
