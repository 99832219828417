import {useMemo} from 'react';
import NextButton from 'shared/components/NextButton';
import {Formik, Form} from 'formik';
import {dateOptions, signOptions, windowSizes} from 'core/_consts';
import {getMaxLimit, getSuffixOf} from 'core/utils';
import {DOLLAR_TYPE, MONTH_TYPE, PERCENT_TYPE, YEAR_TYPE} from 'core/helpers/yupSchemas';
import {LengthAndFeeValues} from 'core/_types/quoteTermsTypes';
import * as yup from 'yup';
import {InterestRateSubsteps, LoanTermSubsteps, QuoteTermsSteps} from 'core/_consts/quoteTerms';
import {setLengthAndFee} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {
  selectExtensionsAmount,
  selectLengthAndFee,
  selectLoanTermDuration,
  selectTermDuration,
} from 'store/quote-terms-service/quoteTermsSelector';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';

const LengthAndFee = () => {
  const defaultValue = useSelector(selectLengthAndFee);
  const windowWidth = useWindowWidth();
  const extensionsAmount = useSelector(selectExtensionsAmount);
  const customLoanTerm = useSelector(selectTermDuration);
  const selectedTerm = useSelector(selectLoanTermDuration)[0];

  const inputSize = windowWidth > windowSizes.mobileSmall ? '100px' : '140px';

  const extensions = useMemo(
    () =>
      Array(
        (selectedTerm?.split(' + ').length && selectedTerm?.split(' + ').length - 1) ||
          (extensionsAmount && Number(extensionsAmount[0])) ||
          0
      )
        .fill(null)
        .map((_, index) =>
          defaultValue.extensions[index]
            ? {
                suffix: defaultValue.extensions[index].suffix,
                extensionDateValue:
                  defaultValue.extensions[index].extensionDateValue ||
                  (selectedTerm?.split(' + ')[index + 1] && Number(selectedTerm?.split(' + ')[index + 1])),
                extensionDateFormat: defaultValue.extensions[index].extensionDateFormat || dateOptions.years,
                extensionFeeValue: defaultValue.extensions[index].extensionFeeValue,
                extensionFeeFormat: defaultValue.extensions[index].extensionFeeFormat || signOptions.dollar,
              }
            : {
                suffix: `${getSuffixOf(index + 1)}`,
                extensionDateValue:
                  (selectedTerm?.split(' + ')[index + 1] && Number(selectedTerm?.split(' + ')[index + 1])) || '',
                extensionDateFormat: dateOptions.years,
                extensionFeeValue: '',
                extensionFeeFormat: signOptions.dollar,
              }
        ),
    []
  );

  const initialValues: LengthAndFeeValues = useMemo(
    () => ({
      initialTerm: defaultValue.initialTerm || customLoanTerm.termValue || Number(selectedTerm?.split(' ')[0]) || null,
      initialTermDateFormat: defaultValue.initialTermDateFormat || customLoanTerm.dateValue || dateOptions.years,
      originationFee: defaultValue.originationFee,
      originationFeeValueFormat: defaultValue.originationFeeValueFormat || signOptions.dollar,
      extensions,
    }),
    [extensions]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        initialTermDateFormat: yup.string().required(),
        initialTerm: yup
          .number()
          .when('initialTermDateFormat', (initialTermDateFormat) =>
            initialTermDateFormat === dateOptions.years ? YEAR_TYPE : MONTH_TYPE
          ),
        originationFeeValueFormat: yup.string().required(),
        originationFee: yup
          .number()
          .when('originationFeeValueFormat', (originationFeeValueFormat) =>
            originationFeeValueFormat === signOptions.dollar ? DOLLAR_TYPE : PERCENT_TYPE
          ),
        extensions: yup.array().of(
          yup.object().shape({
            suffix: yup.string().required(),
            extensionDateFormat: yup.string().required(),
            extensionDateValue: yup
              .number()
              .when('extensionDateFormat', (extensionDateFormat) =>
                extensionDateFormat === dateOptions.years ? YEAR_TYPE : MONTH_TYPE
              ),
            extensionFeeFormat: yup.string().required(),
            extensionFeeValue: yup
              .number()
              .when('extensionFeeFormat', (extensionFeeFormat) =>
                extensionFeeFormat === signOptions.dollar ? DOLLAR_TYPE : PERCENT_TYPE
              ),
          })
        ),
      }),
    [extensions]
  );

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Enter the length of each term, and fee (if applicable).</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        // TODO: add handle logic on submit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({isValid, values, setFieldValue}) => (
          <Form className="FormSlide__form gap25">
            <div className="FormSlide__section flexWrap FormSlide__section_divided">
              <div className="FormSlide__section">
                <Controller
                  name="initialTerm"
                  control={FormikElement.NUMERIC_INPUT}
                  preventionLimit={getMaxLimit(values.initialTermDateFormat)}
                  width={inputSize}
                  label="Initial Term"
                  textAlign="center"
                  setFieldValue={setFieldValue}
                  decimalScale={0}
                />
                <Controller
                  name="initialTermDateFormat"
                  control={FormikElement.RADIO_INPUT}
                  controlledInputValue={values.initialTerm}
                  setFieldValue={setFieldValue}
                  values={Object.values(dateOptions)}
                />
              </div>
              <div className="FormSlide__section">
                <Controller
                  name="originationFee"
                  control={FormikElement.NUMERIC_INPUT}
                  preventionLimit={getMaxLimit(values.originationFeeValueFormat)}
                  width={inputSize}
                  label="Origination Fee"
                  setFieldValue={setFieldValue}
                  thousandSeparator
                  textAlign="center"
                />
                <Controller
                  name="originationFeeValueFormat"
                  control={FormikElement.RADIO_INPUT}
                  controlledInputValue={values.originationFee}
                  setFieldValue={setFieldValue}
                  values={Object.values(signOptions)}
                />
              </div>
            </div>
            {extensions.map((_, currentExtension) => (
              <div
                key={`${values.extensions[currentExtension].suffix} section`}
                className="FormSlide__section flexWrap"
              >
                <div className="FormSlide__section">
                  <Controller
                    name={`extensions[${currentExtension}].extensionDateValue`}
                    control={FormikElement.NUMERIC_INPUT}
                    preventionLimit={getMaxLimit(values.extensions[currentExtension].extensionDateFormat)}
                    width={inputSize}
                    label={`${values.extensions[currentExtension].suffix} extension`}
                    textAlign="center"
                    setFieldValue={setFieldValue}
                    decimalScale={0}
                  />
                  <Controller
                    name={`extensions[${currentExtension}].extensionDateFormat`}
                    control={FormikElement.RADIO_INPUT}
                    controlledInputValue={values.extensions[currentExtension].extensionDateValue}
                    setFieldValue={setFieldValue}
                    values={Object.values(dateOptions)}
                  />
                </div>
                <div className="FormSlide__section">
                  <Controller
                    name={`extensions[${currentExtension}].extensionFeeValue`}
                    control={FormikElement.NUMERIC_INPUT}
                    preventionLimit={getMaxLimit(values.extensions[currentExtension].extensionFeeFormat)}
                    width={inputSize}
                    label={`${values.extensions[currentExtension].suffix} extension fee`}
                    thousandSeparator
                    setFieldValue={setFieldValue}
                    textAlign="center"
                  />
                  <Controller
                    name={`extensions[${currentExtension}].extensionFeeFormat`}
                    controlledInputValue={values.extensions[currentExtension].extensionFeeValue}
                    control={FormikElement.RADIO_INPUT}
                    setFieldValue={setFieldValue}
                    values={Object.values(signOptions)}
                  />
                </div>
              </div>
            ))}
            <NextButton
              isDisabled={!isValid}
              slideData={{[LoanTermSubsteps[LoanTermSubsteps.lengthAndFee]]: values}}
              action={setLengthAndFee}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
                substep: InterestRateSubsteps[InterestRateSubsteps.rateResets],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default LengthAndFee;
