import {useEffect, useState, useRef, ChangeEvent} from 'react';
import {useFormikContext} from 'formik';

// Components
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import StepTextInput from 'shared/components/StepTextInput';

import s from './Fourth.module.scss';

const Fourth = () => {
  const {values, setFieldValue}: any = useFormikContext();

  const [mainAddress, setMainAddress] = useState(values.line1);
  const [state, setState] = useState(values.state);
  const [city, setCity] = useState(values.city);
  const [zip, setZip] = useState(values.zip);

  const mainAddressField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!mainAddressField.current) {
      return;
    }

    const autoComplete = new window.google.maps.places.Autocomplete(mainAddressField.current, {
      componentRestrictions: {country: 'us'},
      fields: ['formatted_address', 'address_components', 'geometry'],
      types: ['address'],
      strictBounds: false,
    });

    autoComplete.addListener('place_changed', () => {
      const addressObject = autoComplete.getPlace();
      const responseQuery = addressObject.formatted_address;

      if (responseQuery) {
        setMainAddress(responseQuery.split(',')[0]);
        setFieldValue('line1', responseQuery.split(',')[0]);
      }

      addressObject?.address_components?.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            setZip(component.short_name);
            setFieldValue('zip', component.short_name);
            break;
          case 'locality':
            setCity(component.long_name);
            setFieldValue('city', component.long_name);
            break;
          case 'administrative_area_level_1':
            setState(component.short_name);
            setFieldValue('state', component.short_name);
            break;
          default:
            break;
        }
      });
    });
  }, [mainAddress]);

  const mainAddressUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setMainAddress(e.target.value);
  };

  const stateUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setState(e.target.value);
  };

  const cityUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setCity(e.target.value);
  };

  const zipUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    setZip(e.target.value);
  };

  return (
    <div className={s.fourth}>
      <div className={s.fourth__header}>
        <p className={s.fourth__title}>What is the address of your project?</p>
        <p className={s.fourth__subtitle}>
          If you are financing multiple properties together, input the address of one of the properties.
        </p>
      </div>
      <div className={s.fourth__inputsContainer}>
        <div className={s.fourth__inputs}>
          <StepTextInput
            autoCompleteRef={mainAddressField}
            id="line1"
            name="line1"
            value={mainAddress}
            onValueChange={mainAddressUpdate}
            label="Street Address*"
            placeholder="Enter street address"
            width="100%"
            required
          />
          <div className={s.fourth__inputsRow}>
            <StepTextInput
              value={city}
              onValueChange={cityUpdate}
              id="city"
              name="city"
              label="City*"
              placeholder="Enter city"
              required
            />
            <StepTextInput
              value={state}
              onValueChange={stateUpdate}
              id="state"
              name="state"
              label="State*"
              placeholder="Enter state"
              required
            />
            <StepTextInput
              value={zip}
              onValueChange={zipUpdate}
              id="zip"
              name="zip"
              label="Zip Code*"
              placeholder="Enter zip code"
              required
            />
          </div>
          <UnsavedChangesPrompt />
        </div>
      </div>
    </div>
  );
};

export {Fourth};
