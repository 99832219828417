import {Address} from '../_types/base';

export const getNumbersFromString = (string: string): number => +string.replace(/[^0-9]/g, '');

export const formattedAddress = (address: Array<string>): Address => ({
  line1: address[0],
  line2: '',
  city: address[1].split(',')[0],
  state: address[1].split(',')[1].split(' ')[1],
  zip: address[1].split(',')[1].split(' ')[2],
});
