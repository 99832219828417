import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {getCurrentUser, getOrgDetails} from './asyncActions';

const initialState: any = {
  userSettings: null,
  email: false,
  password: '',
  profile: null,
  org: null,
  profileUpdate: null,
  isAuth: true,
};

const avaSlice = createSlice({
  name: 'ava',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    },
    setUpdateProfile: (state, action: PayloadAction<string>) => {
      state.profileUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
    });
    builder.addCase(getOrgDetails.fulfilled, (state, action: PayloadAction<any>) => {
      state.org = action.payload;
    });
  },
});

export const {setProfile, setUpdateProfile} = avaSlice.actions;

export default avaSlice.reducer;
