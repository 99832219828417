import {Dispatch, FC, SetStateAction, useState} from 'react';

// Shared
import {StepSelect} from 'shared/components/StepSelect';
import CheckboxButton from 'shared/components/CheckboxButton';
import BasicButton from 'shared/components/BasicButton';
import {THEMES} from 'shared/helpers';

// Core
import {searchBarFilters} from 'core/helpers/searchBarFilters';

import s from './DashboardSort.module.scss';

enum Filters {
  'OPEN',
  'WON',
  'LOST',
}

type DashboardSortProps = {
  handleSearch: (filters: any) => void;
  reset: boolean;
  handleClose: () => void;
  setSearchText?: Dispatch<SetStateAction<string>>;
};

const DashboardSort: FC<DashboardSortProps> = ({handleSearch, reset, handleClose, setSearchText}) => {
  const [filters, setFilters] = useState<Array<string>>([]);

  const addFilters = (filter: string): void => {
    if (filters.includes(filter)) {
      const newFilters = filters.slice();
      newFilters.splice(filters.indexOf(filter), 1);
      setFilters(newFilters);
    } else {
      setFilters([...filters, filter]);
    }
  };

  const handleReset = () => {
    setFilters([]);
    if (setSearchText) setSearchText('');
    handleSearch({});
    handleClose();
  };

  return (
    <>
      <div className={s.dashboardSort__container}>
        <div>
          <label htmlFor="choose" className={s.dashboardSort__label}>
            Status
          </label>
          <div className={s.dashboardSort__buttons}>
            <CheckboxButton
              text={searchBarFilters.open}
              handleClick={() => addFilters(Filters[Filters.OPEN])}
              isActive={filters.includes(Filters[Filters.OPEN])}
            />
            <CheckboxButton
              text={searchBarFilters.won}
              handleClick={() => addFilters(Filters[Filters.WON])}
              isActive={filters.includes(Filters[Filters.WON])}
            />
            <CheckboxButton
              text={searchBarFilters.lost}
              handleClick={() => addFilters(Filters[Filters.LOST])}
              isActive={filters.includes(Filters[Filters.LOST])}
            />
          </div>
        </div>
        <div className={s.dashboardSort__select}>
          <StepSelect
            name="dealTeam"
            options={['Option 1', 'Option 2', 'Option 3']}
            theme={THEMES.blue}
            label="Deal Team"
            placeholder="Select"
          />
        </div>
        <div className={s.dashboardSort__select}>
          <StepSelect
            name="sortBy"
            options={['Option 1', 'Option 2', 'Option 3']}
            theme={THEMES.blue}
            label="Sort By"
            placeholder="Select"
          />
        </div>
      </div>
      <footer className={s.footer}>
        <BasicButton
          className="blue"
          onClick={() => {
            handleSearch({status: filters});
            handleClose();
          }}
        >
          View Results
        </BasicButton>
        {reset && (
          <BasicButton className="text" onClick={handleReset}>
            Reset
          </BasicButton>
        )}
      </footer>
    </>
  );
};

export default DashboardSort;
