import {FC} from 'react';
import {useSelector} from 'react-redux';

// Core
import {DealType} from 'core/_types/base';

// Store
import {selectorGetProjects} from 'store/project-service/selector';

import CreateNewProj from '../CreateNewProj';
import ProjectCard from '../ProjectCard';

import s from '../Dashboard.module.scss';

type ProjectListProps = {
  setProjectSelected: (value: boolean) => void;
};

const ProjectList: FC<ProjectListProps> = ({setProjectSelected}) => {
  const deals = useSelector(selectorGetProjects);

  return (
    <div className={s.dashboard__cardsView}>
      {deals &&
        deals.map((deal: DealType) => (
          <ProjectCard key={deal.sym} deal={deal} setProjectSelected={setProjectSelected} />
        ))}
      <CreateNewProj />
    </div>
  );
};

export default ProjectList;
