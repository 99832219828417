import {useFormikContext} from 'formik';
import {Button, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, styled, Grid, Box} from '@mui/material';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import cx from 'classnames';

import {createProject} from 'store/project-service/asyncActions';

// Shared
import StepTextInput from 'shared/components/StepTextInput';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';

// Core
import {privacyPolicyUrl, termsConditionsUrl} from 'core/_consts';

import s from './Thirteenth.module.scss';

const contactOptions = [
  {value: 'email', label: 'Email'},
  {value: 'phone', label: 'Phone Call'},
  {value: 'text', label: 'Text'},
];

const useStyles = makeStyles({
  root: {
    color: '#fff',
    '&.Mui-checked': {
      color: '#fff',
    },
  },
});

const ContactLabel = styled(FormLabel)({
  color: '#fff',
  font: 'normal 600 14px/18px "Source Sans Pro"',
});

const ContactControlLabel = styled(FormControlLabel)({
  color: '#fff',
  font: '500 14px "Source Sans Pro"',
  '.MuiFormControlLabel-label': {
    font: '500 14px "Source Sans Pro"',
  },
});

const Thirteenth = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {values, handleChange}: any = useFormikContext();

  const handleProjectCreation = () => {
    dispatch(createProject({fields: values}));
    localStorage.removeItem('projectInitialValues');
    history.push('/deals');
  };

  return (
    <div className={s.thirteenth}>
      <div className={s.thirteenth__header}>
        <p className={s.thirteenth__title}>You’re almost done!</p>
        <p className={s.thirteenth__subtitle}>Share your preferred contact method.</p>
      </div>

      <div className={s.thirteenth__inputsRow}>
        <div className={s.thirteenth__input}>
          <StepTextInput
            id="firstName"
            name="firstName"
            label="First Name*"
            value={values.firstName}
            placeholder="First name"
            handleChange={handleChange}
            fullWidth
            required
          />
        </div>
        <div className={s.thirteenth__input}>
          <StepTextInput
            id="lastName"
            name="lastName"
            label="Last Name*"
            value={values.lastName}
            placeholder="Last Name"
            handleChange={handleChange}
            fullWidth
            required
          />
        </div>
        <div className={cx(s.thirteenth__input, s.thirteenth__inputWide)}>
          <StepTextInput
            id="email"
            name="email"
            label="Email*"
            value={values.email}
            handleChange={handleChange}
            placeholder="Email"
            fullWidth
            required
          />
        </div>
      </div>
      <div className={s.thirteenth__inputsRow}>
        <div className={s.thirteenth__input}>
          <StepTextInput
            id="phone"
            name="phone"
            label="Phone Number*"
            value={values.phone}
            placeholder="Phone Number"
            handleChange={handleChange}
            fullWidth
            required
          />
        </div>
        <div className={s.thirteenth__input}>
          <StepTextInput
            id="extension"
            name="extension"
            label="Extension*"
            value={values.extension}
            placeholder="Extension"
            handleChange={handleChange}
            fullWidth
            required
          />
        </div>
        <div className={cx(s.thirteenth__input, s.thirteenth__inputWide)}>
          <FormControl>
            <ContactLabel>Preferred Contact Method*</ContactLabel>
          </FormControl>
          <RadioGroup row>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>
              {contactOptions.map((option) => (
                <ContactControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio classes={classes} />}
                  label={option.label}
                />
              ))}
            </Grid>
          </RadioGroup>
        </div>
      </div>
      <div className={s.thirteenth__terms}>
        By sharing your information, you agree to our{' '}
        <a className={s.thirteenth__termsLink} href={termsConditionsUrl} target="_blank" rel="noreferrer">
          Terms
        </a>{' '}
        and{' '}
        <a className={s.thirteenth__termsLink} href={privacyPolicyUrl} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
      <Box textAlign="center">
        <div className={s.thirteenth__saveBtn}>
          <Button disableRipple size="small">
            <p className={s.thirteenth__saveText} onClick={handleProjectCreation}>
              Save Project
            </p>
          </Button>
        </div>
      </Box>
      <UnsavedChangesPrompt />
    </div>
  );
};

export {Thirteenth};
