import {MaxValidationLimits} from 'core/_consts';
import * as yup from 'yup';

export const PERCENT_TYPE = yup.number().min(0.01).max(MaxValidationLimits.percent).required();

export const DOLLAR_TYPE = yup.number().min(1).max(MaxValidationLimits.highestLimit).required();

export const YEAR_TYPE = yup.number().min(1).max(MaxValidationLimits.years).required();

export const MONTH_TYPE = yup.number().min(1).max(MaxValidationLimits.months).required();

export const sizingConstraintsSchema = yup.object().shape({
  LTC: PERCENT_TYPE,
  DSCR: PERCENT_TYPE,
  LTV: PERCENT_TYPE,
  DebtYield: PERCENT_TYPE,
});

export const rateDetailsSchema = yup.object().shape({
  fixedPercent: PERCENT_TYPE,
  fixedSpread: yup.object().shape({
    index: yup.string().required(),
    spread: PERCENT_TYPE,
    fixedAt: yup.string().required(),
    rateFloor: PERCENT_TYPE,
    indexFloor: PERCENT_TYPE,
  }),
  floating: yup.object().shape({
    index: yup.string().required(),
    spread: PERCENT_TYPE,
    rateFloor: PERCENT_TYPE,
    indexFloor: PERCENT_TYPE,
  }),
});

export const prepaymentPenaltySchema = yup.object().shape({
  years: yup.array().of(
    yup.object().shape({
      name: yup.string().required(),
      value: PERCENT_TYPE,
    })
  ),
});

export const borrowerStipulationsSchema = yup.object().shape({
  creditScore: yup.number().required(),
  netWorth: PERCENT_TYPE,
  liquidityAfterClosing: PERCENT_TYPE,
});
