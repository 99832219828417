import {useState, MouseEvent, FC} from 'react';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';

// Shared
import {THEMES} from 'shared/helpers';

type StarButtonProps = {
  handleClick: () => void;
};

const StarButton: FC<StarButtonProps> = ({handleClick}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsClicked(!isClicked);
    handleClick();
  };

  return (
    <IconButton onClick={handleButtonClick} sx={{padding: 0}}>
      <StarIcon sx={{padding: 0, color: isClicked ? THEMES.blue : THEMES.grey}} />
    </IconButton>
  );
};

export default StarButton;
