import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import * as yup from 'yup';

// Core
import {ava} from 'core/service/ava';
import {ROUTERS} from 'core/_consts';
import {SignInType} from 'core/_types/base';

// Store
import {getProjects} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';
import {selectorGetProfile} from 'store/ava-service/selector';
import {getUserSettings} from 'store/user-service/asyncActions';
import {authenticateWithPassword, getCurrentUser} from 'store/ava-service/asyncActions';

// Components
import {ReactComponent as LogoIcon} from 'shared/media/Logo.svg';
import {StyledCircularProgress} from 'shared/components/CircularLoader';
import BasicButton from 'shared/components/BasicButton';
import TextInput from 'shared/components/TextInput';

import s from './Login.module.scss';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectorGetProfile);
  const project = useSelector(selectorGetProject);
  const [loaderState, setLoaderState] = useState(false);

  const initialValues: SignInType = {
    email: '',
    password: '',
    remember: false,
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required!').email('Email is invalid!'),
    password: yup.string().required('Password is required!'),
  });

  const onSubmit = async (values: SignInType) => {
    setLoaderState(true);
    dispatch(authenticateWithPassword({values, dispatch}));
  };

  useEffect(() => {
    if (user) {
      setLoaderState(false);
      dispatch(getProjects({}));
      history.push(`${ROUTERS.DASHBOARD}`);
      dispatch(getUserSettings(ava));
    } else {
      dispatch(getCurrentUser());
    }
  }, [ava, user]);

  if (loaderState && !project) return <StyledCircularProgress />;

  return (
    <div className={s.login}>
      <div className={s.login__container}>
        <div className={s.header}>
          <LogoIcon className={s.header__logo} />
          <p className={s.header__title}>StackSource</p>
        </div>
        <div className={s.login__form}>
          <h2 className={s.login__heading}>Sign in</h2>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, {resetForm}) => {
            onSubmit(values);
            resetForm();
          }}
        >
          {({values, errors, dirty, setFieldValue, handleChange}) => (
            <>
              <Form className={s.form}>
                <div className={s.form__field}>
                  <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    onValueChange={handleChange}
                    value={values.email}
                    isValid={!!errors.email}
                    error={errors.email}
                    width="280px"
                  />
                </div>
                <div className={s.form__field}>
                  <TextInput
                    label="Password"
                    name="password"
                    type="password"
                    onValueChange={handleChange}
                    value={values.password}
                    isValid={!!errors.password}
                    error={errors.password}
                    width="280px"
                  />
                </div>
                <div className={s.form__actions}>
                  <label htmlFor="remember" className={s.form__actions__container}>
                    <input
                      id="remember"
                      type="checkbox"
                      name="remember"
                      className={s.form__checkbox}
                      onChange={() => setFieldValue('remember', !values.remember)}
                    />
                    Stay signed in
                  </label>
                  <p className={s.login__link}>Forgot password?</p>
                </div>
                <BasicButton
                  type="submit"
                  variant="contained"
                  className={s.form__submit}
                  disabled={!dirty || !!errors.email || !!errors.password}
                >
                  Sign in
                </BasicButton>
              </Form>
              <div className={s.footer}>
                <h2 className={s.login__heading}>New to StackSource?</h2>
                <BasicButton
                  type="button"
                  variant="outlined"
                  className={s.footer__button}
                  onClick={() => history.push(`${ROUTERS.CREATE_NEW_PROJECT}/new-user-email`)}
                >
                  Get a loan
                </BasicButton>
                <a className={s.login__link} href="https://stacksource.com/">
                  Learn more
                </a>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
