import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CapitalProvider, Person} from '@burnaby/ava';

import {ShareType} from 'core/_types/base';

import {getShares} from './asyncActions';

const initialState: any = {
  shares: [],
  loading: false,
  isLinkCopied: false,
};

const sharesSlice = createSlice({
  name: 'shares',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<{shareSym: string; isLoading: boolean}>) {
      const share = state.shares.find((s: ShareType) => s.share.sym === action.payload.shareSym);
      share.isLoading = action.payload.isLoading;
    },
    setShareContacts(state, action: PayloadAction<{shareSym: string; contacts: Person[]}>) {
      const {shareSym, contacts} = action.payload;
      const share = state.shares.find((s: ShareType) => s.share.sym === shareSym);
      share.contacts = contacts;
      share.isLoading = false;
    },
    setShareProvider(state, action: PayloadAction<{shareSym: string; provider: CapitalProvider}>) {
      const {shareSym, provider} = action.payload;
      const share = state.shares.find((s: ShareType) => s.share.sym === shareSym);
      share.provider = provider;
      share.isLoading = false;
    },
    setIsLinkCopied(state, action: PayloadAction<boolean>) {
      state.isLinkCopied = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShares.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getShares.fulfilled, (state, action: PayloadAction<Array<ShareType>>) => {
      state.shares = action.payload;
      state.loading = false;
    });
  },
});

export const {setShareContacts, setShareProvider, setIsLinkCopied, setLoading} = sharesSlice.actions;

export default sharesSlice.reducer;
