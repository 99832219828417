import {FC} from 'react';
import cx from 'classnames';
import s from './Description.module.scss';

type DescriptionProps = {
  title: string;
  variant: string;
};

const Description: FC<DescriptionProps> = ({title, variant}) => (
  <div className={s.description}>
    <div className={cx(s.description__view, s[`description__view-${variant}`])} />
    <p className={s.description__title}>{title}</p>
  </div>
);

export default Description;
