import {FC, useState} from 'react';

// Core
import {UserType} from 'core/_types/base';

// Components
import ModalWin from 'shared/components/Modal/ModalWin';
import BasicButton from 'shared/components/BasicButton';
import TeamMember from './TeamMember';

import {ModalTypes} from '../helpers';

import s from './DealTeam.module.scss';

type DealTeamProps = {
  brokers: Array<UserType>;
  sponsors: UserType[];
  capitalAdvisors: UserType[];
  userSettings: any;
  setSponsorsEmails: any;
};

const DealTeam: FC<DealTeamProps> = ({brokers, sponsors, capitalAdvisors, userSettings, setSponsorsEmails}) => {
  const [isOpen, setOpen] = useState(false);
  const [modal, setModal] = useState<ModalTypes>();

  const handleClick = (modalType: ModalTypes): void => {
    setOpen(true);
    setModal(modalType);
  };

  return (
    <div className={s.DealTeam}>
      <ModalWin
        isOpen={isOpen}
        setOpen={setOpen}
        modal={modal}
        title="Add User"
        sponsors={sponsors}
        setSponsorsEmails={setSponsorsEmails}
      />
      <h2 className={s.DealTeam__heading}>Deal Team</h2>
      <BasicButton className="text bold" onClick={() => handleClick(ModalTypes.newUser)}>
        + Add user
      </BasicButton>
      <div className={s.DealTeam__list}>
        {brokers &&
          brokers.map((member) => (
            <TeamMember key={member.email} data={member} memberRole="Broker" userSettings={userSettings} />
          ))}
        {sponsors &&
          sponsors.map((member, index) => (
            <TeamMember
              key={member.email}
              sponsors={sponsors}
              data={member}
              memberRole="Sponsor"
              index={index}
              userSettings={userSettings}
              setSponsorsEmails={setSponsorsEmails}
            />
          ))}
        {capitalAdvisors &&
          capitalAdvisors.map((member) => (
            <TeamMember key={member.email} data={member} memberRole="Capital Advisor" userSettings={userSettings} />
          ))}
      </div>
    </div>
  );
};

export default DealTeam;
