import {FC, Fragment, DragEvent, Dispatch, SetStateAction} from 'react';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import {Modal} from '@mui/material';

import {deleteImage, updateImages} from 'store/project-service/asyncActions';

import s from './Pictures.module.scss';

type PicturesProps = {
  projectSym: string;
  pictures: Array<any>;
  preview: string | null;
  setPictures: Dispatch<SetStateAction<Array<any>>>;
  setPreview: Dispatch<SetStateAction<null>>;
  setGalleryItemNumber: Dispatch<SetStateAction<number>>;
};

const Pictures: FC<PicturesProps> = ({
  projectSym,
  pictures,
  preview,
  setPictures,
  setPreview,
  setGalleryItemNumber,
}) => {
  const dispatch = useDispatch();

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnd = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const {files} = event.dataTransfer;

    if (files) {
      const pictureSrc: Array<string> = [];
      Array.from(files).forEach((file) => {
        pictureSrc.push(URL.createObjectURL(file));
      });

      dispatch(updateImages({sym: projectSym, images: Array.from(files), dispatch}));

      setPictures([...pictures, ...pictureSrc]);
    }
  };

  const handlePicturesUpload = (files: FileList | null) => {
    if (files) {
      const pictureSrc: Array<string> = [];
      Array.from(files).forEach((file) => {
        pictureSrc.push(URL.createObjectURL(file));
      });

      dispatch(updateImages({sym: projectSym, images: Array.from(files), dispatch}));

      setPictures([...pictures, ...pictureSrc]);
    }
  };

  const handlePictureRemove = (picture: any, event: any) => {
    event.stopPropagation();

    dispatch(deleteImage({sym: projectSym, imageSym: picture?.sym || picture, dispatch}));

    setPictures(pictures.filter((item) => item !== picture));
  };

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={!!preview}
        onClose={() => setPreview(null)}
      >
        <div className={s.preview} onClick={() => setPreview(null)}>
          <img src={preview || ''} alt="Preview" />
        </div>
      </Modal>
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={(values) => {
          // eslint-disable-next-line no-console
          console.log(values);
        }}
      >
        {() => (
          <Form onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDragEnd={handleDragEnd} onDrop={handleDrop}>
            <div className={s.pictures__row}>
              <p className={s.pictures__heading}>Upload pictures of your property.</p>
              <label className={s.pictures__upload}>
                Choose File
                <input
                  id="picturesUpload"
                  type="file"
                  name="picturesUpload"
                  accept="image/*"
                  onChange={(e) => handlePicturesUpload(e.target.files)}
                  multiple
                />
              </label>
            </div>
            <div className={s.gallery}>
              <div className={s.gallery__content}>
                {pictures.map((image, index) => (
                  <Fragment key={image.sym || image}>
                    <div className={s.gallery__item}>
                      <img
                        onClick={() => setGalleryItemNumber(index)}
                        className={s.gallery__image}
                        src={image.url || image}
                        alt="Galery item"
                      />
                      <div className={s.gallery__removeBtn} onClick={(event) => handlePictureRemove(image, event)} />
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Pictures;
