import {useFormikContext} from 'formik';

// Core
import {privacyPolicyUrl, termsConditionsUrl} from 'core/_consts';
import {startPageOptions} from 'core/utils/selectOptions/startPageOptions';

// Components
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import StepTextInput from 'shared/components/StepTextInput';
import {StepSelect} from 'shared/components/StepSelect';

import s from './StartPage.module.scss';

const StartPage = () => {
  const {values, handleChange}: any = useFormikContext();

  return (
    <div className={s.startPage}>
      <div className={s.startPage__header}>
        <p className={s.startPage__title}>Let’s get started!</p>
        <p className={s.startPage__subtitle}>Tell us about yourself, and we’ll get you financing matches in minutes.</p>
      </div>

      <div className={s.startPage__content}>
        <div className={s.startPage__form}>
          <div className={s.startPage__input}>
            <StepTextInput
              id="firstName"
              name="firstName"
              label="First Name*"
              placeholder="Enter first name"
              fullWidth
              value={values.firstName}
              onValueChange={handleChange}
              required
            />
          </div>
          <div className={s.startPage__input}>
            <StepTextInput
              id="email"
              name="email"
              label="Email*"
              placeholder="Enter email address"
              fullWidth
              value={values.email}
              onValueChange={handleChange}
              required
            />
          </div>
          <div className={s.startPage__input}>
            <StepSelect
              width="220px"
              id="experienceLevel"
              name="experienceLevel"
              label="What’s your CRE experience level?*"
              placeholder="Select"
              options={startPageOptions}
              value={values.experienceLevel}
              handleChange={handleChange}
            />
          </div>
          <UnsavedChangesPrompt />
        </div>

        <div className={s.startPage__agreements}>
          By sharing your information, you agree to our{' '}
          <a className={s.startPage__agreement_link} href={termsConditionsUrl} target="_blank" rel="noreferrer">
            Terms
          </a>{' '}
          and{' '}
          <a className={s.startPage__agreement_link} href={privacyPolicyUrl} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
};

export {StartPage};
