import {FormikElement} from 'core/enums/formik-controller.enum';
import {MaxValidationLimits} from 'core/_consts';
import Controller from 'shared/components/FormikController';
import {useFormikContext} from 'formik';

const FixedPercent = () => {
  const {setFieldValue} = useFormikContext();

  return (
    <Controller
      control={FormikElement.NUMERIC_INPUT}
      width="80px"
      label="Rate"
      preventionLimit={MaxValidationLimits.percent}
      name="fixedPercent"
      setFieldValue={setFieldValue}
      sign="%"
    />
  );
};

export default FixedPercent;
