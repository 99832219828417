import {Dispatch, FC, SetStateAction, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';

// Shared
import TextInput from 'shared/components/TextInput';
import NumericInput from 'shared/components/NumericInput';
import {THEMES} from 'shared/helpers';
import {StepSelect} from 'shared/components/StepSelect';

// Core
import {creditScoreOptions} from 'core/utils/selectOptions/guarantorsOptions';
import useGoogleAutocomplete from 'core/utils/hooks/useGoogleAutocomplete';
import {SponsorCreditScore} from 'core/enums/sponsorCreditScore';
import {statusMessage} from 'core/enums/statusMessage';
import {formattedAddress, getNumbersFromString} from 'core/helpers/formatingFunctions';
import {GuarantorsType} from 'core/_types/UnderwritingTypes';

// Store
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {createGuarantor, updateGuarantors} from 'store/project-service/asyncActions';
import {
  setUpdatedGuarantors,
  setUpdatedGuarantorsAddCompleted,
  setUpdatedGuarantorsEditCompleted,
} from 'store/project-service/projectSlice';

// Styles
import s from './Guarantors.module.scss';

type GuarantorsFormProps = {
  values: GuarantorsType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  setOpen: (open: boolean) => void;
  guarantors?: Array<GuarantorsType>;
  setGuarantors?: Dispatch<SetStateAction<GuarantorsType[]>>;
  completeStatus?: string;
  guarantorEdit?: number;
};

const GuarantorsForm: FC<GuarantorsFormProps> = ({
  values,
  handleChange,
  setFieldValue,
  setOpen,
  guarantors,
  setGuarantors,
  completeStatus,
  guarantorEdit,
}) => {
  const dispatch = useDispatch();
  const project = useSelector(selectorGetProject);
  const {guarantorsUpd} = useSelector(selectorGetUpdate);

  const mainAddressField = useRef<HTMLInputElement>(null);
  const {line1} = values.address;
  useGoogleAutocomplete({mainAddressField, setFieldValue, line1});

  useEffect(() => {
    if (guarantorsUpd.addCompleted && guarantors) {
      setOpen(false);

      if (setGuarantors) {
        setGuarantors([
          ...guarantors,
          {
            ...values,
            address: [
              `${values.address.line1}`,
              `${values.address.city}, ${values.address.state} ${values.address.zip}`,
            ],
            netWorth: `$${values.netWorth}`,
            liquidity: `$${values.liquidity}`,
            creditScore:
              SponsorCreditScore[creditScoreOptions.map((item) => item.value as string).indexOf(values.creditScore)],
          },
        ]);
      }
      dispatch(setUpdatedGuarantorsAddCompleted(false));
    }
  }, [guarantorsUpd.addCompleted]);

  useEffect(() => {
    if (guarantorsUpd.editCompleted && guarantors) {
      setOpen(false);
    }
    dispatch(setUpdatedGuarantorsEditCompleted(false));
  }, [guarantorsUpd.editCompleted]);

  const handleClick = () => {
    if (guarantors && guarantorEdit !== undefined) {
      if (guarantorEdit < 0) {
        dispatch(
          createGuarantor({
            sym: project.sym,
            fields: values,
            guarantors: guarantors.map((guarantor, index) => ({
              ...guarantor,
              address: formattedAddress(guarantors[index].address),
              netWorth: `USD ${guarantor.netWorth.replace(/[\s,$]/g, '')}`,
              liquidity: `USD ${guarantor.liquidity.replace(/[\s,$]/g, '')}`,
              creditScore: creditScoreOptions.map((item) => item.label as string).indexOf(guarantor.creditScore),
            })),
            dispatch,
          })
        );
        dispatch(setUpdatedGuarantors(statusMessage.saving));
      } else {
        const editedGuarantor: GuarantorsType = {
          name: values.name,
          address: [`${values.address.line1}`, `${values.address.city}, ${values.address.state} ${values.address.zip}`],
          netWorth: `$${getNumbersFromString(values.netWorth)}`,
          liquidity: `$${getNumbersFromString(values.liquidity)}`,
          creditScore:
            SponsorCreditScore[
              creditScoreOptions.map((item) => item.value as string).indexOf(values.creditScore.toUpperCase())
            ],
        };

        guarantors.splice(guarantorEdit, 1, editedGuarantor);
        if (setGuarantors) {
          setGuarantors(JSON.parse(JSON.stringify(guarantors)));
        }

        dispatch(
          updateGuarantors({
            sym: project.sym,
            guarantors: guarantors.map((guarantor, index) => ({
              ...guarantor,
              address: formattedAddress(guarantors[index].address),
              netWorth: `USD ${guarantor.netWorth.replace(/[\s,$]/g, '')}`,
              liquidity: `USD ${guarantor.liquidity.replace(/[\s,$]/g, '')}`,
              creditScore: guarantor.creditScore.toUpperCase(),
            })),
            dispatch,
          })
        );
        dispatch(setUpdatedGuarantors(statusMessage.saving));
      }
    }
  };

  return (
    <Form className={s.guarantors__container}>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <TextInput name="name" value={values.name} onValueChange={handleChange} fullWidth label="Name or Entity*" />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoCompleteRef={mainAddressField}
            name="address.line1"
            value={values.address.line1}
            onValueChange={handleChange}
            fullWidth
            label="Address"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.line2"
            value={values.address.line2}
            onValueChange={handleChange}
            fullWidth
            label="Address Line 2 (optional)"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.city"
            value={values.address.city}
            onValueChange={handleChange}
            fullWidth
            label="City"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="address.state"
            value={values.address.state}
            onValueChange={handleChange}
            fullWidth
            label="State"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput name="address.zip" value={values.address.zip} onValueChange={handleChange} fullWidth label="ZIP" />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            id="netWorth"
            name="netWorth"
            defaultValue={values.netWorth}
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            fullWidth
            placeholder="$"
            textAlign="left"
            label="Net Worth"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            id="liquidity"
            name="liquidity"
            defaultValue={values.liquidity}
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            fullWidth
            placeholder="$"
            textAlign="left"
            label="Liquidity"
          />
        </Grid>
        <Grid item xs={12}>
          <StepSelect
            name="creditScore"
            value={values.creditScore}
            setValue={setFieldValue}
            options={creditScoreOptions}
            theme={THEMES.blue}
            label="Credit Score"
          />
        </Grid>
      </Grid>
      <footer className={s.guarantors__footer}>
        {completeStatus !== statusMessage.success && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575', marginTop: '15px'}}>
            <p>{completeStatus}</p>
          </Grid>
        )}

        <button className={s.guarantors__saveBtn} onClick={handleClick}>
          Save
        </button>
        <button className={s.guarantors__cancelBtn} onClick={() => setOpen(false)}>
          Cancel
        </button>
      </footer>
    </Form>
  );
};

export default GuarantorsForm;
