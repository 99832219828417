import {FC} from 'react';
import {Form, Formik} from 'formik';
import {Box} from '@mui/material';

// Core
import {tenantOptions} from 'core/utils/selectOptions/tenantOptions';

// Assets
import {ReactComponent as RemoveIcon} from 'assets/icons/trash.svg';

// Components
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';

import s from './TenantInfo.module.scss';

type TenantInfoProps = {
  project: any;
};

const TenantInfo: FC<TenantInfoProps> = () => {
  const initialValues = {
    tenantType: '',
  };

  return (
    <div className={s.tenantInfo}>
      <p className={s.tenantInfo__heading}>List of Tenant</p>
      <div className={s.list}>
        <div className={s.list__header}>
          <p>Name</p>
          <p>Tenant Type</p>
          <p className={s.list__header__action}>Action</p>
        </div>
        <div className={s.list__body}>
          <div className={s.list__row}>
            <p>Michael Foly</p>
            <p>Single Tenant</p>
            <div className={s.list__action}>
              <RemoveIcon className={s.list__icon} />
            </div>
          </div>
          <div className={s.list__row}>
            <p>Michael Foly</p>
            <p>Multi Tenant</p>
            <div className={s.list__action}>
              <RemoveIcon className={s.list__icon} />
            </div>
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          // eslint-disable-next-line no-alert
          alert(JSON.stringify(values));
        }}
      >
        {({values, setFieldValue}) => (
          <Form className={s.tenantInfo__type}>
            <Box display="flex" alignItems="flex-end" minHeight="36px">
              <p className={s.tenant__label}>Tenancy type</p>
            </Box>
            <StepSelect
              name="tenantType"
              value={values.tenantType}
              setValue={setFieldValue}
              options={tenantOptions}
              theme={THEMES.blue}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TenantInfo;
