import {FC, useMemo} from 'react';
import {selectorGetProject} from 'store/project-service/selector';
import {useSelector} from 'react-redux';
import cx from 'classnames';

import s from './FormaTable.module.scss';

const FormaTable: FC = () => {
  const project = useSelector(selectorGetProject);
  const tableTitles = useMemo(() => ['Value', 'LTV', 'LTC', 'Debt Yield'], []);

  return (
    <div className={s.formaTable}>
      <div className={s.formaTable__titles}>
        {!!tableTitles.length &&
          tableTitles.map((item) => (
            <div className={s.formaTable__title} key={item}>
              {item}
            </div>
          ))}
      </div>
      <div className={s.formaTable__content}>
        <div className={s.formaTable__cell}>
          <p>{project.capRateValuation?.formatAbbr()}</p>
          <span className={s['formaTable__cell-addition']}>Cap Rate Valuation</span>
        </div>
        <div className={s.formaTable__cell}>
          {project.stabilizedCapRateValuation?.formatAbbr()}
          <span className={s['formaTable__cell-addition']}>Stabilized</span>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.ltv?.upperBound.formatMaxDecimalPlaces(1)}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>LTV</p>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.stabilizedLtv?.upperBound.formatMaxDecimalPlaces(1)}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>LTV</p>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.ltc?.upperBound.formatMaxDecimalPlaces(1)}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>LTC</p>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.stabilizedLtv?.upperBound.formatMaxDecimalPlaces(1) || '-'}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>LTC</p>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.debtYield?.lowerBound.formatMaxDecimalPlaces(1)}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>Debt Yield</p>
        </div>
        <div className={s.formaTable__cell}>
          <p>{project.stabilizedDebtYield?.lowerBound.formatMaxDecimalPlaces(1)}</p>
          <p className={cx(s.formaTable__cell, s['formaTable__cell-highlighted'])}>Debt Yield</p>
        </div>
      </div>
    </div>
  );
};

export default FormaTable;
