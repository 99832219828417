import {FC, useState} from 'react';
import {Form, FormikHelpers, FormikValues} from 'formik';
import cx from 'classnames';

// Material
import {Box, Checkbox, FormControlLabel, FormGroup, Slider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// Shared
import NumericInput from 'shared/components/NumericInput';
import BasicButton from 'shared/components/BasicButton';
import CheckboxButton from 'shared/components/CheckboxButton';
import {THEMES} from 'shared/helpers';

// Core
import {MaxValidationLimits} from 'core/_consts';
import {CurrencyUnit, CurrencyValue, Percent} from 'core/service/ava';
import {Filters, SeniorButtonFilters} from 'core/_types/Filters';
import {getNumbersFromString} from 'core/helpers/formatingFunctions';

// Store
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';

import {seniorFilters} from './helpers';

// Styles
import s from './Senior.module.scss';

const useStyles = makeStyles({
  slider: {
    color: THEMES.blue,
  },
});

const percent100 = Percent.fromNumber(1);

let updateAmountFieldsTm = 0;

type SeniorFormProps = {
  values: Filters;
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  resetForm: Pick<FormikHelpers<FormikValues>, 'resetForm'>['resetForm'];
};

const SeniorForm: FC<SeniorFormProps> = ({values, setFieldValue, resetForm}) => {
  const project = useSelector(selectorGetProject);
  const maxLoanAmount = CurrencyValue.max(project.totalProjectBudget, project.estimatedValuation.value).toNumber();

  const savedFilters = JSON.parse(localStorage.getItem('seniorFilters') || '{}');

  const [years, setYears] = useState<number[]>(
    'term' in savedFilters
      ? [getNumbersFromString(savedFilters.term.lowerBound), getNumbersFromString(savedFilters.term.upperBound)]
      : [0, 50]
  );
  const [amortization, setAmortization] = useState<number[]>(
    'amortization' in savedFilters
      ? [
          getNumbersFromString(savedFilters.amortization.lowerBound),
          getNumbersFromString(savedFilters.amortization.upperBound),
        ]
      : [0, 50]
  );
  const [amount, setAmount] = useState<number>(
    'loanAmount' in savedFilters ? getNumbersFromString(savedFilters.loanAmount) : maxLoanAmount
  );
  const [allowsSubordinateFinancing, setAllowsSubordinateFinancing] = useState<boolean>(
    !!savedFilters?.allowsSubordinateFinancing
  );
  const [allowsCPace, setAllowsCPace] = useState<boolean>(!!savedFilters?.allowsCPace);

  const loanAmount = CurrencyValue.fromNumber(CurrencyUnit.USD, amount);
  const valuation = project.estimatedValuation.value;
  const stableValuation = project.stabilizedEstimatedValuation.value;
  const budget = project.totalProjectBudget;
  const noi = project.currentNetOperatingIncome;
  const stableNoi = project.proFormaNetOperatingIncome;

  const classes = useStyles();

  const handleRangeSlider = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  const handleAmortizationSlider = (event: Event, newValue: number | number[]) => {
    setAmortization(newValue as number[]);
  };

  const handleAmountSlider = (setFieldValue: (field: string, value: any) => void, newValue: number | number[]) => {
    setAmount(newValue as number);
    clearTimeout(updateAmountFieldsTm);
    updateAmountFieldsTm = setTimeout(() => updateAmountFields(setFieldValue), 50) as unknown as number;
  };

  const updateAmountFields = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('loanAmount', `USD ${amount}`);

    if (!budget.isZero()) {
      const ltc = Percent.min(percent100, loanAmount.divide(budget, percent100));
      setFieldValue('ltc', ltc.formatMaxDecimalPlaces(0));
    }

    if (!valuation.isZero()) {
      const ltv = Percent.min(percent100, loanAmount.divide(valuation, percent100));
      setFieldValue('ltv', ltv.formatMaxDecimalPlaces(0));
    }

    if (!stableValuation.isZero()) {
      const ltv = Percent.min(percent100, loanAmount.divide(stableValuation, percent100));
      setFieldValue('asStabilizedLtv', ltv.formatMaxDecimalPlaces(0));
    }

    if (!noi.isZero()) {
      const debtYield = Percent.min(percent100, noi.divide(loanAmount, percent100));
      setFieldValue('debtYield', debtYield.formatMaxDecimalPlaces(0));
    }

    if (!stableNoi.isZero()) {
      const debtYield = Percent.min(percent100, stableNoi.divide(loanAmount, percent100));
      setFieldValue('asStabilizedDebtYield', debtYield.formatMaxDecimalPlaces(0));
    }
  };

  const handleFilter = (
    setFieldValue: (field: keyof Filters, value: SeniorButtonFilters[]) => void,
    arr: SeniorButtonFilters[] | undefined,
    field: keyof Filters,
    value: SeniorButtonFilters
  ): void => {
    if (!arr) {
      setFieldValue(field, [value]);
    } else if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
      setFieldValue(field, arr);
    } else {
      setFieldValue(field, [...arr, value]);
    }
  };

  const handleReset = (): void => {
    localStorage.removeItem('seniorFilters');
    resetForm({values: {}});
    setYears([0, 50]);
    setAmortization([0, 50]);
    setAmount(0);
    setAllowsSubordinateFinancing(false);
    setAllowsCPace(false);
  };

  const adjustLoanAmount = (
    setFieldValue: (field: string, value: any) => void,
    value: CurrencyValue,
    percent: string
  ) => {
    // Don't modify the loan amount if the percent is < 11% because it's likely the
    // edit is incomplete (the user may have typed "7" but be attempting to enter "70%").
    if (!value.isZero() && +percent >= 20) {
      const newLoanAmount = value.multiplyByPercent(Percent.fromNumber(+percent / 100));
      if (newLoanAmount.lessThan(loanAmount)) {
        const newAmount = Math.ceil(newLoanAmount.toNumber());
        setFieldValue('loanAmount', `USD ${newAmount}`);
        setAmount(newAmount);
      }
    }
  };

  const adjustLoanAmountByNoi = (
    setFieldValue: (field: string, value: any) => void,
    noi: CurrencyValue,
    percent: string
  ) => {
    // Don't modify the loan amount if the percent is < 11% because it's likely the
    // edit is incomplete (the user may have typed "7" but be attempting to enter "70%").
    if (!noi.isZero() && +percent > 20) {
      const newLoanAmount = noi.divideByPercent(Percent.fromNumber(+percent / 100));
      if (newLoanAmount.lessThan(loanAmount)) {
        const newAmount = Math.ceil(newLoanAmount.toNumber());
        setFieldValue('loanAmount', `USD ${newAmount}`);
        setAmount(newAmount);
      }
    }
  };

  return (
    <Form>
      <div className={s.senior}>
        <div className={s.senior__container}>
          <h2 className={s.senior__title}>Senior Loan</h2>
          <h3 className={s.senior__subtitle}>Program</h3>
          <div className={s.senior__btnRows}>
            <CheckboxButton
              text={seniorFilters.bank.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.bank.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.bank.value)}
            />
            <CheckboxButton
              text={seniorFilters.creditUnion.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.creditUnion.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.creditUnion.value)}
            />
            <CheckboxButton
              text={seniorFilters.agency.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.agency.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.agency.value)}
            />
            <CheckboxButton
              text={seniorFilters.lifeCo.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.lifeCo.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.lifeCo.value)}
            />
            <CheckboxButton
              text={seniorFilters.SBA.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.SBA.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.SBA.value)}
            />
            <CheckboxButton
              text={seniorFilters.CMBS.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.CMBS.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.CMBS.value)}
            />
            <CheckboxButton
              text={seniorFilters.debtFund.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.debtFund.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.debtFund.value)}
            />
            <CheckboxButton
              text={seniorFilters.other.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(setFieldValue, values.programType, 'programType', seniorFilters.other.value)
              }
              isActive={values.programType && values.programType.includes(seniorFilters.other.value)}
            />
          </div>
        </div>
        <div className={s.senior__container}>
          <h3 className={s.senior__subtitle}>Recourse</h3>
          <div className={s.senior__btnGroup}>
            <CheckboxButton
              text={seniorFilters.nonRecourse.label}
              variant="modalFilterStatic"
              handleClick={() =>
                handleFilter(setFieldValue, values.recourse, 'recourse', seniorFilters.nonRecourse.value)
              }
              isActive={values.recourse && values.recourse.includes(seniorFilters.nonRecourse.value)}
            />
            <CheckboxButton
              text={seniorFilters.partialRecourse.label}
              variant="modalFilterStatic"
              handleClick={() =>
                handleFilter(setFieldValue, values.recourse, 'recourse', seniorFilters.partialRecourse.value)
              }
              isActive={values.recourse && values.recourse.includes(seniorFilters.partialRecourse.value)}
            />
          </div>
        </div>
        <div className={s.senior__container}>
          <h3 className={s.senior__subtitle}>Loan Term</h3>
          <div className={s.senior__sliderWrapper}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '65%',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider
                className={classes.slider}
                size="small"
                min={0}
                max={50}
                value={years}
                onChange={handleRangeSlider}
                onChangeCommitted={() => {
                  setFieldValue('term.lowerBound', `${years[0]} years`);
                  setFieldValue('term.upperBound', `${years[1]} years`);
                }}
              />
            </Box>
            <div className={s.senior__sliderContent}>
              <p className={s.senior__boxValue}>{years[0]}</p>
              <span>-</span>
              <p className={s.senior__boxValue}>{years[1]}</p>
              <span>years</span>
            </div>
          </div>
        </div>
        <div className={s.senior__container}>
          <h3 className={s.senior__subtitle}>Amortization</h3>
          <div className={s.senior__sliderWrapper}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '65%',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider
                className={classes.slider}
                size="small"
                min={0}
                max={50}
                value={amortization}
                onChange={handleAmortizationSlider}
                onChangeCommitted={() => {
                  setFieldValue('amortization.lowerBound', `${amortization[0]} years`);
                  setFieldValue('amortization.upperBound', `${amortization[1]} years`);
                }}
              />
            </Box>
            <div className={s.senior__sliderContent}>
              <p className={s.senior__boxValue}>{amortization[0]}</p>
              <span>-</span>
              <p className={s.senior__boxValue}>{amortization[1]}</p>
              <span>years</span>
            </div>
          </div>
          <div className={cx(s.senior__btnGroup, s.senior__btnGroup_amortization)}>
            <CheckboxButton
              text={seniorFilters.interestOnly.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(
                  setFieldValue,
                  values.amortizationType,
                  'amortizationType',
                  seniorFilters.interestOnly.value
                )
              }
              isActive={values.amortizationType && values.amortizationType.includes(seniorFilters.interestOnly.value)}
            />
            <CheckboxButton
              text={seniorFilters.partialInterestOnly.label}
              variant="modalFilter"
              handleClick={() =>
                handleFilter(
                  setFieldValue,
                  values.amortizationType,
                  'amortizationType',
                  seniorFilters.partialInterestOnly.value
                )
              }
              isActive={
                values.amortizationType && values.amortizationType.includes(seniorFilters.partialInterestOnly.value)
              }
            />
          </div>

          <div className={s.senior__container}>
            <h3 className={s.senior__subtitle}>Loan Amount</h3>
            <div className={s.senior__sliderWrapper_third}>
              <Box
                sx={{
                  width: '65%',
                  display: 'flex',
                  alignItems: 'center',
                  '@media (max-width: 425px)': {
                    paddingLeft: '5px',
                    width: '100%',
                  },
                }}
              >
                <Slider
                  className={classes.slider}
                  size="small"
                  min={0}
                  max={maxLoanAmount}
                  step={50000}
                  value={amount}
                  onChange={(e: Event, newValue: number | number[]) => handleAmountSlider(setFieldValue, newValue)}
                />
              </Box>
              <div className={s.senior__sliderContent}>
                <p className={s.senior__sliderContent_value}>{`$${Intl.NumberFormat('en-US').format(amount)}`}</p>
              </div>
            </div>
            {!budget.isZero() && (
              <div className={s.senior__percentEquity}>
                <div>
                  <h3 className={s.senior__percentPartyTitle}>LTC</h3>
                  <div className={s.senior__boxWrapper}>
                    <NumericInput
                      name="ltc"
                      value={values.ltc ? getNumbersFromString(values.ltc) : ''}
                      width="60px"
                      preventionLimit={MaxValidationLimits.percent}
                      onValueChange={(e) => {
                        setFieldValue('ltc', `${e.target.value}%`);
                        adjustLoanAmount(setFieldValue, budget, e.target.value);
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
            )}
            {!valuation.isZero() && (
              <div className={s.senior__percentEquity}>
                <div>
                  <h3 className={s.senior__percentPartyTitle}>LTV</h3>
                  <div className={s.senior__boxWrapper}>
                    <NumericInput
                      name="ltv"
                      value={values.ltv ? getNumbersFromString(values.ltv) : ''}
                      width="60px"
                      preventionLimit={MaxValidationLimits.percent}
                      onValueChange={(e) => {
                        setFieldValue('ltv', `${e.target.value}%`);
                        adjustLoanAmount(setFieldValue, valuation, e.target.value);
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
                {!stableValuation.isZero() && (
                  <div className={s.senior__secondCol}>
                    <h3 className={s.senior__percentPartyTitle}>As-stabilized LTV</h3>
                    <div className={s.senior__boxWrapper}>
                      <NumericInput
                        name="asStabilizedLtv"
                        value={values.asStabilizedLtv ? getNumbersFromString(values.asStabilizedLtv) : ''}
                        width="60px"
                        preventionLimit={MaxValidationLimits.percent}
                        onValueChange={(e) => {
                          setFieldValue('asStabilizedLtv', `${e.target.value}%`);
                          adjustLoanAmount(setFieldValue, stableValuation, e.target.value);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!noi.isZero() && (
              <div className={s.senior__percentEquity}>
                <div>
                  <h3 className={s.senior__percentPartyTitle}>Debt Yield</h3>
                  <div className={s.senior__boxWrapper}>
                    <NumericInput
                      name="debtYield"
                      value={values.debtYield ? getNumbersFromString(values.debtYield) : ''}
                      width="60px"
                      preventionLimit={MaxValidationLimits.percent}
                      onValueChange={(e) => {
                        setFieldValue('debtYield', `${e.target.value}%`);
                        adjustLoanAmountByNoi(setFieldValue, noi, e.target.value);
                      }}
                    />
                    <span>%</span>
                  </div>
                </div>
                {!stableNoi.isZero() && (
                  <div className={s.senior__secondCol}>
                    <h3 className={s.senior__percentPartyTitle}>As-stabilized Debt Yield</h3>
                    <div className={s.senior__boxWrapper}>
                      <NumericInput
                        name="asStabilizedDebtYield"
                        value={values.asStabilizedDebtYield ? getNumbersFromString(values.asStabilizedDebtYield) : ''}
                        width="60px"
                        preventionLimit={MaxValidationLimits.percent}
                        onValueChange={(e) => {
                          setFieldValue('asStabilizedDebtYield', `${e.target.value}%`);
                          adjustLoanAmountByNoi(setFieldValue, stableNoi, e.target.value);
                        }}
                      />
                      <span>%</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={s.senior__checkBoxContainer}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setAllowsSubordinateFinancing(e.target.checked);
                        setFieldValue('allowsSubordinateFinancing', e.target.checked);
                      }}
                      checked={allowsSubordinateFinancing}
                    />
                  }
                  label="Allows subordinate financing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setAllowsCPace(e.target.checked);
                        setFieldValue('allowsCPace', e.target.checked);
                      }}
                      checked={allowsCPace}
                    />
                  }
                  label="Allows C-PACE"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <footer className={s.footer}>
        <BasicButton className="darkBlue" type="submit">
          View Results
        </BasicButton>
        <BasicButton onClick={handleReset}>Reset</BasicButton>
      </footer>
    </Form>
  );
};

export default SeniorForm;
