import {SourceType} from '../../_types/selectOptionsTypes/SourcesType';

export const sourcesTypeOptions: Array<SourceType> = [
  {value: 'OTHER', label: 'Other'},
  {value: 'COMMON_EQUITY', label: 'Common equity'},
  {value: 'PREFERRED_EQUITY', label: 'Preferred equity'},
  {value: 'MEZZANINE_DEBT', label: 'Mezzanine debt'},
  {value: 'SENIOR_DEBT', label: 'Senior debt'},
  {value: 'LAND_VALUE', label: 'Land value'},
  {value: 'PACE_FINANCING', label: 'PACE financing'},
  {value: 'OPEN', label: 'Open'},
  {value: 'GROUND_LEASE', label: 'Ground lease'},
];
