import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';

// Shared
import Header from 'shared/components/Header';

// Core
import {ProfileType} from 'core/_types/ProfileType';

// Store
import {selectorGetProfile} from 'store/ava-service/selector';
import {getOrgDetails} from 'store/ava-service/asyncActions';

// Components
import ProfileForm from './ProfileForm';

// Styles
import s from './Profile.module.scss';

const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectorGetProfile);

  useEffect(() => {
    dispatch(getOrgDetails(profile.organization));
  }, []);

  const initialValues: ProfileType = {
    photo: profile?.photo,
    name: profile?.name,
    nationalNumber: profile?.phoneNumber?.nationalNumber,
    extension: '',
    preferredContactMethod: profile?.preferredContactMethod,
    address: {
      line1: profile?.officeAddress?.line1,
      line2: profile?.officeAddress?.line2,
      city: profile?.officeAddress?.city,
      state: profile?.officeAddress?.state,
      zip: profile?.officeAddress?.zip,
    },
    bio: profile?.bio?.content,
  };

  return (
    <div className={s.profile}>
      <Header />
      <div className={s.profile__container}>
        <h1 className={s.profile__title}>Profile</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            // eslint-disable-next-line no-console
            console.log('Values: ', values);
          }}
        >
          {(props) => (
            <ProfileForm
              values={props.values}
              handleChange={props.handleChange}
              setFieldValue={props.setFieldValue}
              initialValues={initialValues}
            />
          )}
        </Formik>
        <p className={s.profile__sessions}>You have two active sessions.</p>
      </div>
    </div>
  );
};

export default Profile;
