import {useSelector} from 'react-redux';

import {selectorGetProfile} from 'store/ava-service/selector';

// Components
import {CreateNewOfferWrapper, UserCreateNewOfferWrapper} from './CreateNewOfferWrapper';

import s from './styles.module.scss';

const CreateNewOffer = () => {
  const userProfile = useSelector(selectorGetProfile);

  return (
    <div className={s.createNewOffer}>{userProfile ? <UserCreateNewOfferWrapper /> : <CreateNewOfferWrapper />}</div>
  );
};

export default CreateNewOffer;
