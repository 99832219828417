import {useRef, useState} from 'react';
import TextArea from 'shared/components/TextArea';
import {useDispatch, useSelector} from 'react-redux';
import {setOtherRequirements} from 'store/quote-terms-service/quoteTermsSlice';
import {ReactComponent as AttachmentIcon} from 'shared/media/Attachment.svg';
import {vaultService} from 'core/service/storage';
import {QuoteTermsSteps, storageKey} from 'core/_consts';
import BasicButton from 'shared/components/BasicButton';
import {makeStyles} from '@mui/styles';
import {useHistory, useLocation} from 'react-router-dom';
import {selectOtherRequirements} from 'store/quote-terms-service/quoteTermsSelector';
import {queryParams} from 'core/utils';

const useStyles = makeStyles({
  button: {
    display: 'none',
    padding: 0,
    color: '#848484',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: 'flex',
    },
  },
});

const OtherRequirements = () => {
  const defaultValue = useSelector(selectOtherRequirements);
  const dispatch = useDispatch();

  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(defaultValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();
  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || '';

  const classes = useStyles();

  const handleSubmit = () => {
    vaultService.updateItem(storageKey.quoteTermsData, {
      [QuoteTermsSteps[QuoteTermsSteps.otherRequirements]]: textAreaValue,
    });
    dispatch(setOtherRequirements(textAreaValue));
    history.push(`${location.pathname}/non-binding-offer${queryParams({formPosition})}${location.hash}`);
  };

  const removeFile = () => {
    setIsFileUploaded(false);
    if (inputRef.current) inputRef.current.value = '';
  };

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Are there any other requirements?</p>
        <p className="FormSlide__subTitle mb35">
          You can enter any additional datails we didn&apos;t cover, or skip it and submit.
        </p>
      </div>
      <TextArea
        defaultValue={defaultValue}
        isValid={!!textAreaValue.length}
        onValueChange={(e) => setTextAreaValue(e.target.value)}
        width="460px"
        height="85px"
      />
      {isFileUploaded ? (
        <div className="FormSlide__file">
          <AttachmentIcon />
          <span className="FormSlide__fileName">{inputRef.current?.value}</span>
          <span className="link" onClick={removeFile}>
            remove file
          </span>
        </div>
      ) : (
        <p className="FormSlide__redirect mt25 alignCenter">
          You can also&nbsp;
          <label htmlFor="inputFile" className="link">
            upload your term sheet
          </label>
          .
        </p>
      )}
      <input className="d-none" ref={inputRef} id="inputFile" type="file" onChange={() => setIsFileUploaded(true)} />
      <div className="FormSlide__navigation">
        <BasicButton className={classes.button} disableRipple onClick={() => history.go(-1)}>
          Back
        </BasicButton>
        <BasicButton onClick={handleSubmit} className="nextButton blue" variant="contained">
          Submit
        </BasicButton>
      </div>
    </div>
  );
};
export default OtherRequirements;
