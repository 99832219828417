import {FC} from 'react';
import cx from 'classnames';

import s from './DataCell.module.scss';

export interface DataCellProps {
  title: string;
  data: string | number | (() => string);
  isTruthy?: boolean;
}

const DataCell: FC<DataCellProps> = ({data, title, isTruthy = true}) => (
  <>
    {!!data && isTruthy && (
      <div className={s.dataCell}>
        <p className={cx(s.dataCell, s.dataCell_highlighted)}>{title}</p>
        <p className={cx(s.dataCell, s.dataCell_bolded)}>{data}</p>
      </div>
    )}
  </>
);

export default DataCell;
