import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';

// Core
import useStatusMessage from 'core/utils/hooks/useStatusMessage';
import {statusMessage} from 'core/enums/statusMessage';
import {GuarantorsType} from 'core/_types/UnderwritingTypes';

// Shared
import ModalWin from 'shared/components/Modal/ModalWin';
import BasicButton from 'shared/components/BasicButton';

// Store
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {setUpdatedGuarantors} from 'store/project-service/projectSlice';
import {updateGuarantors} from 'store/project-service/asyncActions';

import {ModalTypes} from 'pages/ProjectView/helpers';

import s from './Guarantors.module.scss';

type GuarantorsProps = {
  guarantors: Array<GuarantorsType>;
  setGuarantors: Dispatch<SetStateAction<GuarantorsType[]>>;
};

const Guarantors: FC<GuarantorsProps> = ({guarantors, setGuarantors}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {guarantorsUpd} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(guarantorsUpd.message);

  const [isOpen, setOpen] = useState(false);
  const [modal, setModal] = useState<ModalTypes>();
  const [completeStatus, setCompleteStatus] = useState<string>('');
  const [guarantorEdit, setGuarantorEdit] = useState<number>();

  useEffect(() => {
    setCompleteStatus(message);
  }, [message]);

  useEffect(
    () => () => {
      dispatch(setUpdatedGuarantors(''));
    },
    []
  );

  const handleDelete = (index: number): void => {
    dispatch(setUpdatedGuarantors(statusMessage.saving));

    guarantors.splice(index, 1);
    setGuarantors(JSON.parse(JSON.stringify(guarantors)));

    dispatch(
      updateGuarantors({
        sym: project.sym,
        guarantors: guarantors.map((guarantor) => ({
          ...guarantor,
          netWorth: `USD ${guarantor.netWorth.replace(/[\s,$]/g, '')}`,
          liquidity: `USD ${guarantor.liquidity.replace(/[\s,$]/g, '')}`,
          creditScore: guarantor.creditScore.toUpperCase(),
        })),
        dispatch,
      })
    );
  };

  return (
    <div>
      <ModalWin
        isOpen={isOpen}
        setOpen={setOpen}
        modal={modal}
        title="Add Guarantors"
        guarantors={guarantors}
        setGuarantors={setGuarantors}
        completeStatus={completeStatus}
        guarantorEdit={guarantorEdit}
      />
      <div className={s.guarantors__row}>
        <button
          className={s.guarantors__button}
          onClick={() => {
            setModal(ModalTypes.guarantors);
            setOpen(true);
            setGuarantorEdit(-1);
          }}
        >
          Add Guarantors
        </button>
      </div>
      <div className={s.guarantors__data}>
        {guarantors.map((guarantor: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <p className={s.guarantors__header}>Guarantor {index + 1}</p>
            <div className={s.guarantors__buttons_wrapper}>
              <BasicButton
                className="link"
                onClick={() => {
                  setModal(ModalTypes.guarantors);
                  setOpen(true);
                  setGuarantorEdit(index);
                }}
              >
                Edit
              </BasicButton>
              <span className={s.guarantors__buttons_divider}>|</span>
              <BasicButton className="link" onClick={() => handleDelete(index)}>
                Delete
              </BasicButton>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Name</p>
              <p className={s.guarantors__subtitle}>{guarantor.name}</p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Address</p>
              <p className={s.guarantors__subtitle}>{guarantor.address.join(', ')}</p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Status</p>
              <p className={s.guarantors__subtitle}> - </p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Citizenship</p>
              <p className={s.guarantors__subtitle}> - </p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Net Worth</p>
              <p className={s.guarantors__subtitle}>{guarantor.netWorth}</p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Liquidity</p>
              <p className={s.guarantors__subtitle}>{guarantor.liquidity}</p>
            </div>
            <div className={s.guarantors__container}>
              <p className={s.guarantors__title}>Credit Score</p>
              <p className={s.guarantors__subtitle}>{guarantor.creditScore}</p>
            </div>
          </div>
        ))}
      </div>
      {message !== statusMessage.error && (
        <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575', marginTop: '20px'}}>
          <p>{message}</p>
        </Grid>
      )}
    </div>
  );
};

export default Guarantors;
