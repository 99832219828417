import {useState} from 'react';
import NextButton from 'shared/components/NextButton';
import {PrepayPenaltySubsteps, QuoteTermsSteps} from 'core/_consts';
import {setPenaltyDetails} from 'store/quote-terms-service/quoteTermsSlice';
import TextArea from 'shared/components/TextArea';
import {selectPenaltyDetails} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';

const PenaltyDetails = () => {
  const defaultValue = useSelector(selectPenaltyDetails);
  const [detailsValue, setDetailsValue] = useState(defaultValue);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Add details about your other/limited penalty.</p>
      <TextArea
        defaultValue={detailsValue}
        isValid={!!detailsValue.length}
        onValueChange={(e) => setDetailsValue(e.target.value)}
        height="125px"
        width="460px"
      />
      <NextButton
        isDisabled={!detailsValue.length}
        slideData={{
          [PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyDetails]]: detailsValue,
        }}
        action={setPenaltyDetails}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
          substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyLockout],
        }}
      />
    </div>
  );
};

export default PenaltyDetails;
