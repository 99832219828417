import {FC} from 'react';
import cx from 'classnames';

import s from './SelectionCard.module.scss';

type SelectionCardProps = {
  selectedCard: string;
  setSelectedCard?: (value: string) => void;
  iconName: string;
  title: string;
  maxWidth?: string;
  handleCardSelection?: () => void;
};

const SelectionCard: FC<SelectionCardProps> = ({
  iconName,
  title,
  selectedCard,
  setSelectedCard,
  maxWidth,
  handleCardSelection,
}) => (
  <div
    className={cx(s.selectionCard, {[s.selectionCard__selected]: selectedCard === title})}
    style={{flexBasis: maxWidth}}
    onClick={handleCardSelection}
  >
    <div className={s.selectionCard__top}>
      <img className={s.selectionCard__icon} src={`/images/createNewOffer/${iconName}.svg`} alt="icon" />
    </div>
    <p className={s.selectionCard__title}>{title}</p>
  </div>
);

export default SelectionCard;
