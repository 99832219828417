import {FC} from 'react';
import {useSelector} from 'react-redux';
import AccordionWrapper from 'shared/components/Accordion';
import {selectorGetProject} from 'store/project-service/selector';
import {IsMobileViewProps} from '../helpers';

const Summary: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);

  const content = (
    <div className="LenderView__section fs18">
      {!isMobileView && <p className="LenderView__infoContentTitle">Summary</p>}
      <div dangerouslySetInnerHTML={{__html: project.projectSummary.formatAsHtml()}} />
    </div>
  );

  if (!project.projectSummary) return null;
  if (isMobileView) return <AccordionWrapper title="Summary">{content}</AccordionWrapper>;

  return content;
};

export default Summary;
