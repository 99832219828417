import {ChangeEvent, FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FieldArray, Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';
import {Grid, SelectChangeEvent} from '@mui/material';

// Shared
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import TextInput from 'shared/components/TextInput';
import NumericInput from 'shared/components/NumericInput';

// Core
import {usesTypeOptions} from 'core/utils/selectOptions/usesTypeOptions';
import {MaxValidationLimits} from 'core/_consts';
import {Use} from 'core/_types/selectOptionsTypes/UsesType';
import {statusMessage} from 'core/enums/statusMessage';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';

// Store
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {updateUses} from 'store/project-service/asyncActions';
import {setUpdatedSourcesAndUses} from 'store/project-service/projectSlice';

// Styles
import s from '../SourcesAndUses.module.scss';

type UsesFormProps = {
  values: {uses: Array<Use>};
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: {uses: Array<Use>};
  totalUses: string;
};

const UsesForm: FC<UsesFormProps> = ({values, handleChange, setFieldValue, initialValues, totalUses}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {sourcesAndUses} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(sourcesAndUses);

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateUses({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedSourcesAndUses(statusMessage.saving));
    }
  }, [values]);

  useEffect(
    () => () => {
      dispatch(setUpdatedSourcesAndUses(''));
    },
    []
  );

  return (
    <Form>
      <p className={s.Sources__groupTitle}>Uses</p>
      <FieldArray name="uses">
        {({remove, push}) => (
          <Grid container columnSpacing={2.5} rowSpacing={3}>
            {values.uses.length > 0 ? (
              values.uses.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item container columnSpacing={2.5} key={index}>
                  <Grid item xs={6} sm={3}>
                    <StepSelect
                      name={`uses.${index}.use`}
                      options={usesTypeOptions}
                      value={
                        usesTypeOptions.includes(values.uses[index].use.toString()) || !values.uses[index].use.length
                          ? values.uses[index].use.toString()
                          : 'Custom'
                      }
                      handleChange={handleChange}
                      theme={THEMES.blue}
                    />
                  </Grid>
                  {(!usesTypeOptions.includes(values.uses[index].use.toString()) ||
                    values.uses[index].use.toString() === 'Custom') &&
                    !!values.uses[index].use.length && (
                      <Grid item xs={6} sm={3}>
                        <TextInput
                          name={`uses.${index}.customUse`}
                          value={values.uses[index].customUse}
                          onValueChange={handleChange}
                          placeholder="Custom Use"
                          fullWidth
                        />
                      </Grid>
                    )}
                  <Grid item xs={6} sm={3}>
                    <NumericInput
                      name={`uses.${index}.principal`}
                      onValueChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(
                          `uses.${index}.principal`,
                          `USD ${e.target.value.toString().replace(/[\s,]/g, '')}`
                        )
                      }
                      value={+values.uses[index].principal.split(' ')[1]}
                      theme={THEMES.grey}
                      thousandSeparator
                      fullWidth
                      preventionLimit={MaxValidationLimits.highestLimit}
                      placeholder="$"
                      textAlign="left"
                    />
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                    {values.uses.length > 1 ? (
                      <button className={s.Sources__groupBtn} type="button" onClick={() => remove(index)}>
                        Delete item
                      </button>
                    ) : null}
                    {index === values.uses.length - 1 ? (
                      <>
                        {values.uses.length > 1 && <div className={s.Sources__groupDivider} />}
                        <button
                          className={s.Sources__groupBtn}
                          type="button"
                          onClick={() => push({use: '', customUse: '', principal: ''})}
                        >
                          Add an item
                        </button>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <button className={s.Sources__groupBtn} type="button" onClick={() => push({use: '', principal: ''})}>
                  Add an item
                </button>
              </Grid>
            )}
            <Grid item xs={12}>
              <p className={s.Sources__total}>Total Uses: ${totalUses}</p>
            </Grid>
            <Grid item xs={12}>
              <p className={s.Sources__alert}>The total from sources and uses should match.</p>
            </Grid>
            {!!message.length && (
              <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
                <p>{message}</p>
              </Grid>
            )}
          </Grid>
        )}
      </FieldArray>
    </Form>
  );
};

export default UsesForm;
