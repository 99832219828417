import {createNewOfferStorageType} from 'core/_types/createNewOfferTypes';
import {
  IAssetType,
  IAttachmentsPictures,
  IFinancePurpose,
  IFinancingRequest,
  IFinancingRequestDetails,
  IMultifamilyAcquisition,
  IMultiFamilyType,
  IProjectAddress,
  IProjectBudget,
  IPropertyIncome,
  IRole,
  ISummary,
  IUserData,
} from 'store/create-new-offer-service/interfaces';

export class CreateNewOfferInitialValues {
  userData: IUserData;

  assetType: IAssetType;

  multiFamilyType: IMultiFamilyType;

  financePurpose: IFinancePurpose;

  projectAddress: IProjectAddress;

  propertyIncome: IPropertyIncome;

  projectBudget: IProjectBudget;

  financingRequest: IFinancingRequest;

  financingRequestDetails: IFinancingRequestDetails;

  attachmentsPictures: IAttachmentsPictures;

  projectSummary: ISummary;

  userRole: IRole;

  multiFamilyAcquisition: IMultifamilyAcquisition;

  constructor(data?: createNewOfferStorageType) {
    this.userData = {
      email: data?.email || '',
      name: data?.name || '',
    };

    this.assetType = {
      assetType: data?.assetType,
    };

    this.multiFamilyType = {
      multiFamilyType: data?.multiFamilyType,
    };

    this.financePurpose = {
      purpose: data?.purpose,
      acquisitionStatus: data?.acquisitionStatus || '',
      closingDate: data?.closingDate || '',
    };

    this.projectAddress = {
      address: data?.address || '',
      city: data?.city || '',
      state: data?.state || '',
      zip: data?.zip || '',
    };

    this.propertyIncome = {
      category: data?.category || '',
      currentNetIncome: data?.currentNetIncome || 0,
      proFormaNetIncome: data?.proFormaNetIncome || 0,
      proFormaRate: data?.proFormaRate || 0,
    };

    this.projectBudget = {
      uses: data?.uses || '',
      amount: data?.amount || 0,
    };

    this.financingRequest = {
      ltc: data?.ltc || 0,
    };

    this.financingRequestDetails = {
      loanTerm: data?.loanTerm || '',
      amortization: data?.amortization || '',
      years: data?.years || {start: 5, end: 100},
      recourse: data?.recourse || '',
      interestRate: data?.interestRate || '',
      notes: data?.notes,
    };

    this.attachmentsPictures = {
      attachments: data?.attachments || [],
      pictures: data?.pictures,
    };

    this.projectSummary = {
      summary: data?.summary,
    };

    this.userRole = {
      role: data?.role || '',
    };

    this.multiFamilyAcquisition = {
      assistType: data?.assistType || '',
    };
  }
}
