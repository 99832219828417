import React, {FC, useState, useEffect} from 'react';
import BasicButton from 'shared/components/BasicButton';
import {Stack} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import {ava} from 'core/service/ava';
import {IAttachmentDocument} from 'core/_models/lenderView';
import AccordionWrapper from 'shared/components/Accordion';
import s from './Attachments.module.scss';
import Document from './Document';
import {IsMobileViewProps} from '../helpers';

interface IFormatedAttachmentsProps {
  name: string;
  type: string;
  url: string;
  creationDate: string;
}

const downloadAttachments = async (attachmentList: any) => {
  ava.downloadAttachments(attachmentList, '1');
};

const othersSectionTitle = 'Others';

const Attachments: FC<IsMobileViewProps> = ({isMobileView}) => {
  const [attachments, setAttachments] = useState<Array<IAttachmentDocument>>();
  const [selectedDocuments, setSelectedDocuments] = useState<Array<string>>([]);
  const [uniqueDocSections, setUniqueDocSections] = useState<Array<string>>([]);

  const project = useSelector(selectorGetProject);

  useEffect(() => {
    if (!project.attachmentList) return;
    const formatedAttachments = project.attachmentList.reduce(
      (res: any, item: any) => [
        ...res,
        {
          name: item.name,
          url: item.url,
          type: item.formatTypes().reduce((_: any, item: any) => item),
          creationDate: item.creationTime.formatHumanAbbr(),
        },
      ],
      []
    );
    const uniqueDocSections: any = new Set([
      ...formatedAttachments.map((item: IFormatedAttachmentsProps) => item.type),
    ]);
    if (uniqueDocSections.has(undefined)) {
      uniqueDocSections.delete(undefined);
      uniqueDocSections.add(othersSectionTitle);
    }
    setUniqueDocSections(Array.from(uniqueDocSections));
    setAttachments(formatedAttachments);
  }, [project]);

  const content = (
    <div className="LenderView__section">
      <div className={s.attachments__head}>
        <p className="LenderView__infoContentTitle">Attachments</p>
        <div className={s.attachments__buttons}>
          <Stack direction="row" spacing={2}>
            <BasicButton
              disabled={!selectedDocuments.length}
              className="white"
              variant="contained"
              onClick={() => downloadAttachments(selectedDocuments)}
            >
              Download Selected
            </BasicButton>
            <BasicButton
              className="blue"
              variant="contained"
              onClick={() => downloadAttachments(project.attachmentList)}
            >
              Download All
            </BasicButton>
          </Stack>
        </div>
      </div>
      {uniqueDocSections.map((sectionTitle) => (
        <React.Fragment key={sectionTitle || othersSectionTitle}>
          <p className={s.attachments__sectionTitle}>{sectionTitle}</p>
          <div className={s.attachments__documents}>
            {!!attachments?.length &&
              attachments?.map(
                (document, index) =>
                  (document.type ? document.type === sectionTitle : sectionTitle === othersSectionTitle) && (
                    <Document
                      selectedDocuments={selectedDocuments}
                      setSelectedDocuments={setSelectedDocuments}
                      key={document.name}
                      name={document.name}
                      creationDate={document.creationDate}
                      attachmentData={Array.from(project.attachmentList)[index]}
                      url={document.url}
                      type={document.type}
                    />
                  )
              )}
          </div>
        </React.Fragment>
      ))}
      <BasicButton className="blue" variant="contained" onClick={() => downloadAttachments(project.attachmentList)}>
        Download All
      </BasicButton>
    </div>
  );

  if (!attachments?.length) return null;
  if (isMobileView) return <AccordionWrapper title="Attachments">{content}</AccordionWrapper>;

  return content;
};

export default Attachments;
