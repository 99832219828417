import {FC, useState} from 'react';
import {Box, Slider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BasicButton from 'shared/components/BasicButton';
import {THEMES} from 'shared/helpers';
import CheckboxButton from 'shared/components/CheckboxButton';

import s from './Subordinate.module.scss';

const useStyles = makeStyles({
  slider: {
    color: THEMES.blue,
  },
  sliderSecondary: {
    color: THEMES.blue,
    marginTop: '3px',
  },
});

const Subordinate: FC = () => {
  const [years, setYears] = useState<number[]>([0, 50]);
  const [amount, setAmount] = useState<number>(0);

  const classes = useStyles();

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  const handleAmountChange = (event: Event, newValue: number | number[]) => {
    setAmount(newValue as number);
  };

  return (
    <>
      <div className={s.subordinate}>
        <div className={s.subordinate__container}>
          <h2 className={s.subordinate__title}>Subordinate Debt</h2>
          <h3 className={s.subordinate__subtitle}>Sub-debt Type</h3>
          <div className={s.subordinate__btnGroup}>
            <CheckboxButton text="Second Mortgage" variant="modalFilterStatic" />
            <CheckboxButton text="Mezzanine Loan" variant="modalFilterStatic" />
          </div>
        </div>
        <div className={s.subordinate__container}>
          <h3 className={s.subordinate__subtitle}>Recourse</h3>
          <div className={s.subordinate__btnGroup}>
            <CheckboxButton text="Non-Recourse" variant="modalFilterStatic" />
            <CheckboxButton text="Partial Recourse" variant="modalFilterStatic" />
          </div>
        </div>
        <div className={s.subordinate__container}>
          <h3 className={s.subordinate__subtitle}>Loan Term</h3>
          <div className={s.subordinate__sliderWrapper}>
            <Box
              sx={{
                width: '63%',
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider className={classes.slider} size="small" value={years} onChange={handleRangeChange} />
            </Box>
            <div className={s.subordinate__sliderContent}>
              <p className={s.subordinate__boxValue}>{years[0]}</p>
              <span>-</span>
              <p className={s.subordinate__boxValue}>{years[1]}</p>
              <span>years</span>
            </div>
          </div>
        </div>
        <div className={s.subordinate__container}>
          <h3 className={s.subordinate__subtitle}>Senior Loan</h3>
          <div className={s.subordinate__sliderWrapper}>
            <Box
              sx={{
                width: '63%',
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider
                className={classes.slider}
                defaultValue={0}
                size="small"
                step={1}
                min={0}
                max={100}
                color="primary"
                value={amount}
                onChange={handleAmountChange}
              />
            </Box>
            <div className={s.subordinate__sliderContent}>
              <p className={s.subordinate__sliderContent_value}>$30,000,000</p>
              <p className={s.subordinate__sliderContent_subValue}>LTV: 90%</p>
              <p className={s.subordinate__sliderContent_subValue}>LTC: 75%</p>
            </div>
          </div>
          <div className={s.subordinate__container}>
            <div className={s.subordinate__subtitleGroup}>
              <h3 className={s.subordinate__subtitle}>Subordinate Debt</h3>
              <h3 className={s.subordinate__subtitle}>Equity</h3>
            </div>
            <div className={s.subordinate__sliderWrapper_third}>
              <p className={s.subordinate__sliderContent_value}>$4,000,000</p>
              <Box sx={{width: '60%', display: 'flex', alignItems: 'center'}}>
                <Slider
                  className={classes.sliderSecondary}
                  defaultValue={0}
                  size="small"
                  step={1}
                  min={0}
                  max={100}
                  color="primary"
                />
              </Box>
              <p className={s.subordinate__sliderContent_value}>$6,000,000</p>
            </div>
            <div className={s.subordinate__percentEquity}>
              <div>
                <h3 className={s.subordinate__percentPartyTitle}>CLTC</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>85</p>
                  <span>%</span>
                </div>
              </div>
              <div className={s.subordinate__secondCol}>
                <h3 className={s.subordinate__percentPartyTitle}>Aggregate DSCR</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>1.10</p>
                  <span>x</span>
                </div>
              </div>
            </div>
            <div className={s.subordinate__percentEquity}>
              <div>
                <h3 className={s.subordinate__percentPartyTitle}>CLTV</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>105</p>
                  <span>%</span>
                </div>
              </div>
              <div className={s.subordinate__secondCol}>
                <h3 className={s.subordinate__percentPartyTitle}>As-stabilized CLTV</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>70</p>
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={s.subordinate__percentEquity}>
              <div>
                <h3 className={s.subordinate__percentPartyTitle}>Aggregate Debt Yield</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>6</p>
                  <span>%</span>
                </div>
              </div>
              <div className={s.subordinate__secondCol}>
                <h3 className={s.subordinate__percentPartyTitle}>As-stabilized Aggregate Debt Yield</h3>
                <div className={s.subordinate__boxWrapper}>
                  <p className={s.subordinate__boxValue}>8.5</p>
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className={s.footer}>
        <BasicButton className="darkBlue">View Results</BasicButton>
        <BasicButton>Reset</BasicButton>
      </footer>
    </>
  );
};

export default Subordinate;
