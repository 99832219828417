import {FC} from 'react';

import {ReactComponent as UploadIcon} from 'assets/icons/upload.svg';

import {ChecklistItemType} from 'core/_models/projectOverview';

import s from './Checklist.module.scss';

type ChecklistItemProps = {
  item: ChecklistItemType;
  index: number;
};

const ChecklistItem: FC<ChecklistItemProps> = ({item, index}: ChecklistItemProps) => (
  <div className={s.Checklist__item} key={index}>
    <input type="checkbox" name={item.label} id={item.label} checked={item.isChecked} readOnly />
    <label htmlFor={item.label} className={s.Checklist__label}>
      {item.label} {item.link}
    </label>
    {item.isWithUpload && (
      <div className={s.Checklist__upload}>
        <label>
          <input type="file" name={`uploaded${item.id}`} id="" />
          <UploadIcon className={s.Checklist__icon} />
        </label>
      </div>
    )}
  </div>
);

export default ChecklistItem;
