import {CapitalSource} from 'core/_types/base';
import {RootState} from 'store/store';
import {DealType} from './interfaces';

export const selectorGetProject = (store: RootState): DealType => store.projectRedux.project;

export const selectorGetProjects = (store: RootState): any => store.projectRedux.projects;

export const selectorGetOffers = (store: RootState): any => store.projectRedux.offers;

export const selectorGetNewProjectSym = (store: RootState): string => store.projectRedux.newProjectSym;

export const selectorGetNextProjects = (store: RootState): any => store.projectRedux.next;

export const selectorGetProjectFilters = (store: RootState): any => store.projectRedux.filters;

export const selectorGetCapitalSources = (store: RootState): Array<CapitalSource> => store.projectRedux.capitalSources;

export const selectorGetLoading = (store: RootState): boolean => store.projectRedux.loading;

export const selectorGetUpdate = (store: RootState): any => store.projectRedux.updated;
