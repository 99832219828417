import {FC, useEffect, useRef} from 'react';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';
import {Grid} from '@mui/material';

// Shared
import TextInput from 'shared/components/TextInput';
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import NumericInput from 'shared/components/NumericInput';
import TextArea from 'shared/components/TextArea';

// Core
import {sponsorTypeOptions} from 'core/utils/selectOptions/sponsorTypeOptions';
import {SponsorProfileType} from 'core/_types/UnderwritingTypes';
import {statusMessage} from 'core/enums/statusMessage';
import {questionaryOptions} from 'core/utils/selectOptions/questionaryOptions';
import {creditScoreOptions} from 'core/utils/selectOptions/guarantorsOptions';
import useGoogleAutocomplete from 'core/utils/hooks/useGoogleAutocomplete';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';
import {sponsorRoleOptions} from 'core/utils/selectOptions/sponsorRoleOptions';

// Store
import {useDispatch, useSelector} from 'react-redux';
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {updateSponsorProfile} from 'store/project-service/asyncActions';
import {setUpdatedSponsorProfile} from 'store/project-service/projectSlice';

// Styles
import s from './SponsorProfile.module.scss';

type SponsorProfileFormProps = {
  values: SponsorProfileType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: SponsorProfileType;
};

const SponsorProfileForm: FC<SponsorProfileFormProps> = ({values, handleChange, setFieldValue, initialValues}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {sponsorProfile} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(sponsorProfile);

  const mainAddressField = useRef<HTMLInputElement>(null);
  const {line1} = values.address;
  useGoogleAutocomplete({mainAddressField, setFieldValue, line1});

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateSponsorProfile({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedSponsorProfile(statusMessage.saving));
    }
  }, [values]);

  useEffect(
    () => () => {
      dispatch(setUpdatedSponsorProfile(''));
    },
    []
  );

  return (
    <Form>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={6}>
          <StepSelect
            name="sponsorRole"
            options={sponsorRoleOptions}
            theme={THEMES.blue}
            label="Sponsor Role"
            value={values.sponsorRole}
            setValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="sponsorType"
            options={sponsorTypeOptions}
            theme={THEMES.blue}
            label="Sponsor Type"
            value={values.sponsorType}
            setValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput name="name" value={values.name} onValueChange={handleChange} fullWidth label="Your Name*" />
        </Grid>
        <Grid item xs={6}>
          <TextInput name="website" value={values.website} onValueChange={handleChange} fullWidth label="Website" />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoCompleteRef={mainAddressField}
            name="address.line1"
            value={values.address.line1}
            onValueChange={handleChange}
            fullWidth
            label="Address"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.line2"
            value={values.address.line2}
            onValueChange={handleChange}
            fullWidth
            label="Address Line 2 (optional)"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.city"
            value={values.address.city}
            onValueChange={handleChange}
            fullWidth
            label="City"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="address.state"
            value={values.address.state}
            onValueChange={handleChange}
            fullWidth
            label="State"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput name="address.zip" value={values.address.zip} onValueChange={handleChange} fullWidth label="ZIP" />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="assetsUnderOwnership"
            value={values.assetsUnderOwnership}
            onValueChange={handleChange}
            theme={THEMES.grey}
            fullWidth
            label="Assets Under Ownership"
            preventionLimit={1000}
            textAlign="left"
            placeholder="$"
          />
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="sponsorCreditScore"
            options={creditScoreOptions}
            label="Credit Score"
            value={values.sponsorCreditScore === 'Unset' ? '' : values.sponsorCreditScore}
            theme={THEMES.blue}
            setValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="sponsorLawsuits"
            options={questionaryOptions}
            theme={THEMES.blue}
            label="Lawsuits"
            value={values.sponsorLawsuits === 'Unset' ? '' : values.sponsorLawsuits}
            setValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={6}>
          <p className={s.sponsorProfile__text}>
            Has the project sponsor been involved in any past or pending lawsuits, judgements, liens, foreclosure or
            bunkrupcies?
          </p>
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="sponsorFelony"
            options={questionaryOptions}
            theme={THEMES.blue}
            label="Felony"
            value={values.sponsorFelony === 'Unset' ? '' : values.sponsorFelony}
            setValue={setFieldValue}
          />
        </Grid>
        <Grid item xs={6}>
          <p className={s.sponsorProfile__text}>Has the project sponsor been charged or convicted of a felony?</p>
        </Grid>
        <Grid item xs={12}>
          <TextArea
            width="100%"
            height="126px"
            name="bio"
            value={values.bio}
            onValueChange={handleChange}
            theme={THEMES.blue}
            label="Bio"
            resize="vertical"
          />
        </Grid>
        {!!message.length && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
            <p>{message}</p>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default SponsorProfileForm;
