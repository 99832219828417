import {FC, useEffect, useState} from 'react';
import {useHistory, useLocation, matchPath, match} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {CircularProgress} from '@mui/material';

// Core
import {ROUTERS} from 'core/_consts';
import {ava} from 'core/service/ava';
import {SymType, ImageType} from 'core/_types/base';

// Store
import {selectorGetOffers, selectorGetProject} from 'store/project-service/selector';
import {selectorGetVerifyEmail} from 'store/ava-service/selector';
import {getCapitalSources, getOffers} from 'store/project-service/asyncActions';
import {
  selectorGetBrokerProfiles,
  selectorGetCapitalAdvisorProfiles,
  selectorGetSponsorProfiles,
  selectorGetUserSettings,
} from 'store/user-service/selector';
import {getBrokerProfiles, getCapitalAdvisorProfiles, getSponsorProfiles} from 'store/user-service/asyncActions';
import {getCurrentUser} from 'store/ava-service/asyncActions';
import {getShares} from 'store/shares-service/asyncActions';

// Components
import ProjectNameHeader from 'pages/LenderView/ProjectNameHeader';
import ProjectInfoCard from 'pages/LenderView/ProjectInfoCard';
import LoanRequest from 'pages/LenderView/LoanRequest';
import PhotoSlider from 'pages/LenderView/PhotoSlider';
import GoogleMap from 'pages/LenderView/GoogleMap';
import Header from 'shared/components/Header';

import {ProjectViewTabs} from './helpers';
import CapitalSources from './CapitalSources';
import Underwriting from './Underwriting';
import Checklist from './Checklist';
import DealTeam from './DealTeam';
import Navbar from './Navbar';
import Offers from './Offers';

import s from './ProjectView.module.scss';

const ProjectView: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  // Initial data
  const project = useSelector(selectorGetProject);
  const userSettings = useSelector(selectorGetUserSettings);

  // Routing
  const [tab, setTab] = useState<string>(location.pathname.split('/')[2]);

  // Project gallery
  const [images, setImages] = useState<Array<any>>([]);

  // Deal Team
  const [capitalAdvisorSyms, setCapitalAdvisorSyms] = useState<Array<string>>([]);
  const [sponsorsEmails, setSponsorsEmails] = useState<Array<string>>([]);
  const [brokerSyms, setBrokerSyms] = useState<Array<string>>([]);
  const brokerProfiles = useSelector(selectorGetBrokerProfiles);
  const sponsorProfiles = useSelector(selectorGetSponsorProfiles);
  const capitalAdvisorProfiles = useSelector(selectorGetCapitalAdvisorProfiles);

  // Checklist
  const emailVerified = useSelector(selectorGetVerifyEmail);

  // Your offers
  const offers = useSelector(selectorGetOffers);
  const [offersSyms, setOffersSyms] = useState<Array<string>>([]);

  // Shares
  const [sharesSyms, setSharesSyms] = useState<Array<string>>([]);

  useEffect(() => {
    const matchedUrl: match<{dealSym: string; tab: string}> | null = matchPath(location.pathname, {
      path: ROUTERS.PROJECT_VIEW,
    });
    // If the project is loaded but the URL doesn't match the project's sym, it
    // may be because the URL refers to the project's ID instead and it needs to
    // be redirected.
    if (matchedUrl && project && matchedUrl.params.dealSym !== project.sym) {
      history.replace(`${ROUTERS.PROJECT}:${project.sym}/${matchedUrl.params.tab}`);
    }
  }, [project]);

  // Setting up data
  useEffect(() => {
    if (project.capitalAdvisorList) {
      const newUsersSyms = project.capitalAdvisorList.reduce((res: any, item: string) => [...res, item], []);
      setCapitalAdvisorSyms(newUsersSyms);
    }

    if (project.sponsorUserList) {
      const usersEmails = Array.from(project.sponsorUserList).map((item) => item.string);
      setSponsorsEmails(usersEmails);
    }

    if (project.brokerUserList) {
      const newUsersSyms = project.brokerUserList.reduce((res: any, item: SymType) => [...res, item.sym], []);
      setBrokerSyms(newUsersSyms);
    }

    if (project.offerList) {
      const newOffersSyms = Array.from(project.offerList);
      setOffersSyms(newOffersSyms as string[]);
    }

    if (project.shareList) {
      const sharesSyms = project.shareList.toArray();
      setSharesSyms(sharesSyms as string[]);
    }

    if (project && Object.getOwnPropertyNames(project).length) {
      setImages(Array.from(project.imageList));
    }
  }, [project]);

  console.log('Images: ', images);

  // Getting syms (symbol-id)
  useEffect(() => {
    if (ava) {
      if (brokerSyms.length) {
        dispatch(getBrokerProfiles({usersSyms: brokerSyms, ava}));
      }

      if (sponsorsEmails.length) {
        dispatch(getSponsorProfiles({emails: sponsorsEmails, ava}));
      }

      if (capitalAdvisorSyms.length) {
        dispatch(getCapitalAdvisorProfiles({usersSyms: capitalAdvisorSyms, ava}));
      }

      if (offersSyms.length) {
        dispatch(getOffers({offersSyms, ava}));
      }

      if (sharesSyms && sharesSyms.length) {
        dispatch(getShares({ava, sharesSyms}));
      }
    }
  }, [capitalAdvisorSyms, sponsorsEmails, brokerSyms, offersSyms, sharesSyms, ava]);

  // Tab (routing)
  useEffect(() => {
    setTab(location.pathname.split('/')[2]);
  }, [location]);

  useEffect(() => {
    if (ava) {
      dispatch(getCapitalSources({projectSym: project?.sym, filters: {}}));
    }
  }, [ava]);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const renderTab = () => {
    switch (tab) {
      case ProjectViewTabs[ProjectViewTabs.underwriting]:
        return <Underwriting project={project} />;
      case ProjectViewTabs[ProjectViewTabs['capital-sources']]:
        return <CapitalSources project={project} />;
      case ProjectViewTabs[ProjectViewTabs.offers]:
        return <Offers offers={offers} />;
      default:
        return <div> </div>;
    }
  };

  if (!project) return <CircularProgress />;

  return (
    <>
      <Header />
      <div className={s.app}>
        <div className={s.app__content}>
          <div className={s.projectView}>
            <div className={s.projectView__wrapper}>
              <ProjectNameHeader project={project} />
              <LoanRequest project={project} />
              <div className={s.projectView__preview}>
                <ProjectInfoCard project={project} />
                {images[0] ? (
                  <PhotoSlider images={images} />
                ) : project?.address?.line1 ? (
                  <GoogleMap project={project} />
                ) : (
                  ''
                )}
              </div>
              <Navbar tab={tab} />
              {tab === ProjectViewTabs[ProjectViewTabs.underwriting] && (
                <Checklist project={project} emailVerified={!!emailVerified} />
              )}
              <div className={s.projectView__cols}>
                {renderTab()}
                <div>
                  <DealTeam
                    brokers={brokerProfiles as any}
                    sponsors={sponsorProfiles}
                    capitalAdvisors={capitalAdvisorProfiles}
                    userSettings={userSettings}
                    setSponsorsEmails={setSponsorsEmails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectView;
