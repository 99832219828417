import {FC, useMemo} from 'react';
import {Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles({
  button: {
    boxSizing: 'border-box',
    height: '42px',
    minWidth: '120px',
    padding: '0 20px',
    border: '1px solid #848484',
    borderRadius: '21px',
    color: '#848484',
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    textTransform: 'none',
    transition: 'background-color 0.5s',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 375px)': {
      padding: 0,
      fontSize: '16px',
    },
  },
  reduced: {
    minWidth: '30px',
    padding: 0,
    borderRadius: '5px',
    color: '#848484',
  },
  selected: {
    border: '1px solid #B3D5F8',
    color: 'black',
    backgroundColor: '#B3D5F8',
    '&:hover': {
      backgroundColor: '#B3D5F8',
    },
  },
});

type RadioButtonProps = {
  title: string;
  activeValues: Array<string>;
  setActiveValues: (values: Array<string>) => void;
  multiSelect: boolean;
  width?: string;
  nextButtonTrigger?: () => void;
};

const RadioButton: FC<RadioButtonProps> = ({
  title,
  activeValues,
  setActiveValues,
  multiSelect,
  width,
  nextButtonTrigger,
}) => {
  const isButtonSelected = useMemo(() => activeValues.includes(title), [activeValues]);
  const isButtonReduced = useMemo(() => title.length === 1, [title]);

  const classes = useStyles();

  const handleClick = () => {
    if (isButtonSelected && !nextButtonTrigger) {
      const newActiveValues = activeValues.filter((item) => item !== title);
      setActiveValues(newActiveValues);
    } else if (isButtonSelected && nextButtonTrigger) {
      nextButtonTrigger();
    } else if (!isButtonSelected) {
      const newActiveValues = multiSelect ? [...activeValues, title] : [title];
      setActiveValues(newActiveValues);
    }
  };

  return (
    <Button
      className={cx(classes.button, {[classes.reduced]: isButtonReduced, [classes.selected]: isButtonSelected})}
      onClick={handleClick}
      style={{minWidth: width}}
      disableRipple
    >
      {title}
    </Button>
  );
};

export default RadioButton;
