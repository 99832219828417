import {CircularProgress} from '@mui/material';
import {styled} from '@mui/material/styles';

export const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginLeft: '-20px',
  marginTop: '-20px',
  color: '#1c81ff',
});
