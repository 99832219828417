import {FC, ReactElement, useMemo, useState} from 'react';
import {InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BasicTooltip from '../BasicTooltip';

import s from './StepSelect.module.scss';
import {THEMES} from '../../helpers';

type Option = {
  value: string;
  label: string;
};

type StepSelectProps = {
  label?: string;
  labelTooltip?: ReactElement | string;
  options?: Array<string | Option>;
  theme?: string;
  value?: string;
  name?: string;
  id?: string;
  handleChange?: (event?: SelectChangeEvent) => void;
  setValue?: (field: string, value: string) => void;
  placeholder?: string;
  width?: string;
};

export const StepSelect: FC<StepSelectProps> = ({
  label,
  labelTooltip,
  options,
  theme,
  value,
  name,
  id,
  handleChange,
  setValue,
  placeholder,
  width,
}) => {
  const useStyles = useMemo(
    () =>
      makeStyles({
        select: {
          height: '42px',
          border: theme === THEMES.blue ? '1px solid #757575' : '1px solid #fff',
          borderRadius: '6px',
          color: theme === THEMES.blue ? '#000' : '#fff',
          font: '500 14px "Source Sans Pro"',
          '& div': {
            outline: 'none',
          },
          '& fieldset': {
            border: 'none',
          },
          '& svg': {
            color: theme === THEMES.blue ? '#757575' : '#fff',
          },
        },
        label: {
          marginBottom: theme === THEMES.blue ? '5px' : '10px',
          color: theme === THEMES.blue ? '#757575' : '#fff',
          font: '500 14px "Source Sans Pro"',
        },
        option: {
          width,
          whiteSpace: 'pre-wrap',
          margin: '0 10px 10px',
          background: 'none',
          borderRadius: '6px',
          font: '500 14px "Source Sans Pro"',
          '&:hover': {
            backgroundColor: '#eee',
          },
        },
        hint: {
          alignItems: 'center',
          display: 'inline-flex',
          justifyContent: 'center',
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          backgroundColor: '#fff',
          color: '#000',
          cursor: 'default',
          marginLeft: '4px',
          marginTop: '2px',
        },
      }),
    [theme]
  );

  const classes = useStyles();
  const [internalValue, setInternalValue] = useState('');

  return (
    <div className={s.stepSelect}>
      {label && (
        <InputLabel className={classes.label}>
          {label}
          {labelTooltip && (
            <span>
              <BasicTooltip title={labelTooltip} arrow placement="bottom">
                <p className={classes.hint}> ?</p>
              </BasicTooltip>
            </span>
          )}
        </InputLabel>
      )}
      <Select
        displayEmpty
        defaultValue=""
        renderValue={(value) => (value === '' ? placeholder : value)}
        className={classes.select}
        value={internalValue || value}
        name={name}
        id={id}
        onChange={handleChange}
      >
        {options &&
          options.map((option) =>
            typeof option === 'string' ? (
              <MenuItem key={option} className={classes.option} value={option}>
                {option}
              </MenuItem>
            ) : (
              <MenuItem
                key={option.value}
                className={classes.option}
                value={option.label}
                onClick={() => {
                  if (setValue && name) {
                    setValue(name, option.value);
                  }
                  if (option.value === 'UNSET') {
                    setInternalValue(' ');
                  } else setInternalValue(option.label);
                }}
              >
                {option.label}
              </MenuItem>
            )
          )}
      </Select>
    </div>
  );
};
