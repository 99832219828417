import {FC, useEffect, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useFormikContext} from 'formik';
import {Box, Button, CircularProgress} from '@mui/material';

// Store
import {createProject, getProject} from 'store/project-service/asyncActions';
import {selectorGetNewProjectSym, selectorGetProject} from 'store/project-service/selector';

// Components
import TextArea from 'shared/components/TextArea';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import {THEMES} from 'shared/helpers';

import s from './Tenth.module.scss';

type TenthProps = {
  isFinal?: boolean;
};

const Tenth: FC<TenthProps> = ({isFinal}) => {
  const {values, handleChange}: any = useFormikContext();

  const [loading, setLoading] = useState(false);

  const newProjectSym = useSelector(selectorGetNewProjectSym);
  const project = useSelector(selectorGetProject);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleProjectCreation = () => {
    dispatch(createProject({fields: values}));
    localStorage.removeItem('projectInitialValues');
    setLoading(true);
  };

  useEffect(() => {
    if (newProjectSym) {
      dispatch(getProject({projectSym: newProjectSym}));

      if (project) {
        history.push(`/deal:${newProjectSym}/underwriting`);
        setLoading(false);
      }
    }
  }, [newProjectSym, project]);

  if (loading)
    return (
      <div className={s.tenth} style={{alignItems: 'center'}}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={s.tenth}>
      <div className={s.tenth__header}>
        <p className={s.tenth__title}>Project Summary</p>
        <p className={s.tenth__subtitle}>
          Time to shine! This is your chance to make a first impression with lenders by telling the story of your deal.
          Don’t write a book here, but do put on your marketing hat and tell them why you have a great plan of this
          asset.
        </p>
      </div>

      <div className={s.tenth__textarea}>
        <TextArea
          width="620px"
          height="132px"
          name="summary"
          value={values.summary}
          onValueChange={handleChange}
          label="Summary"
          theme={THEMES.white}
        />
      </div>

      {isFinal && (
        <Box textAlign="center">
          <div className={s.saveBtn}>
            <Button disableRipple size="small">
              <p className={s.saveText} onClick={handleProjectCreation}>
                Save Project
              </p>
            </Button>
          </div>
        </Box>
      )}
      <UnsavedChangesPrompt />
    </div>
  );
};

export {Tenth};
