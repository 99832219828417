import {createAsyncThunk, Dispatch} from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

import {Org, UserProfile} from '@burnaby/ava';

import {ava} from 'core/service/ava';
import {SignInType} from 'core/_types/base';
import {statusMessage} from 'core/enums/statusMessage';

import {AVA_AUTH_PASSWORD} from 'store/action-types';

import {setProfile, setUpdateProfile} from './avaSlice';

export const authenticateWithPassword = createAsyncThunk(
  AVA_AUTH_PASSWORD,
  async ({values, dispatch}: {values: SignInType; dispatch: Dispatch}): Promise<void> => {
    const {email, password, remember} = values;
    try {
      await ava.auth.signIn(email, password, remember);

      const sym = await ava.getCurrentUser();
      const user = await ava.getUserProfile(sym.sym);

      dispatch(setProfile(user));
    } catch (e: any) {
      toast.error(`${e.message}`);
    }
  }
);

export const logout = createAsyncThunk('AVA_DEACTIVATE_SESSION', async (dispatch: Dispatch): Promise<void> => {
  await ava.auth.signOut();
  dispatch(setProfile(null));
});

export const getCurrentUser = createAsyncThunk('AVA_GET_CURRENT_USER', async (): Promise<UserProfile | null> => {
  try {
    const sym = await ava.getCurrentUser();
    return await ava.getUserProfile(sym.sym);
  } catch (e: any) {
    return null;
  }
});

export const updateUserProfile = createAsyncThunk(
  'AVA_UPDATE_USER_PROFILE',
  async ({sym, fields, dispatch}: {sym: string; fields: any; dispatch: Dispatch}): Promise<void> => {
    const {name, nationalNumber, extension, preferredContactMethod, bio, address} = fields;
    try {
      await ava.userProfiles.update({
        sym,
        firstName: name.split(' ')[0],
        lastName: name.split(' ')[1],
        phoneNumber: {
          countryCode: 1,
          nationalNumber: nationalNumber && +nationalNumber.toString().replace(/\D/g, ''),
          extension,
        },
        preferredContactMethod,
        bio: {
          type: 'PLAIN',
          content: bio,
        },
        officeAddress: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          zip: address.zip,
        },
      });
      dispatch(setUpdateProfile(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdateProfile(statusMessage.error));
    }
  }
);

export const getOrgDetails = createAsyncThunk('AVA_GET_ORG_DETAILS', async (sym: string): Promise<Org | null> => {
  try {
    return await ava.orgs.get(sym);
  } catch (e: any) {
    return null;
  }
});
