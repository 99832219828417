import {Org, Person, Share} from '@burnaby/ava';
import {createAsyncThunk, Dispatch} from '@reduxjs/toolkit';

import {toast} from 'react-toastify';

import {AvaType, ShareType} from 'core/_types/base';
import {ava} from 'core/service/ava';

import {setIsLinkCopied, setLoading, setShareContacts, setShareProvider} from './sharesSlice';

export const createShare = createAsyncThunk(
  'share/createShare',
  async ({ava, projectSym, providerSym}: {ava: AvaType; projectSym: string; providerSym: string}): Promise<string> => {
    try {
      const share = ava.shares.createFromProvider(projectSym, providerSym);
      return share;
    } catch (err: any) {
      toast.error(err.message);
      return '';
    }
  }
);

export const getShares = createAsyncThunk(
  'share/getShares',
  async ({ava, sharesSyms}: {ava: AvaType; sharesSyms: string[]}): Promise<Array<ShareType>> => {
    try {
      const providers = await Promise.all(sharesSyms.map((sym) => ava.shares.get(sym)));

      console.log('providers', providers);

      const shares: Array<ShareType> = await Promise.all(
        providers.map(async (provider) => {
          const org = await ava.orgs.get(provider.org);

          return {share: provider, org};
        })
      );

      return shares;
    } catch (err: any) {
      toast.error(err.message);
      return [];
    }
  }
);

export const getProviders = createAsyncThunk(
  'share/getProviders',
  async ({share, dispatch}: {share: ShareType; dispatch: Dispatch}): Promise<any> => {
    try {
      dispatch(setLoading({shareSym: share.share.sym, isLoading: true}));

      const provider = await ava.providers.get(share?.share?.provider);

      if (provider) {
        dispatch(setShareProvider({shareSym: share?.share?.sym, provider}));
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const getContacts = createAsyncThunk(
  'share/getContacts',
  async ({share, dispatch}: {share: ShareType; dispatch: Dispatch}): Promise<void> => {
    try {
      if (!share.contacts) {
        dispatch(setLoading({shareSym: share.share.sym, isLoading: true}));

        const contacts = Array.from(await ava.shares.getContacts(share?.share?.sym));
        const persons = await Promise.all(contacts.map((contact) => ava.people.getByEmail(contact?.email)));

        if (persons) {
          dispatch(setShareContacts({shareSym: share?.share?.sym, contacts: persons}));
        }
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const declineShare = createAsyncThunk(
  'share/decline',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        await ava.shares.decline(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const undeclineShare = createAsyncThunk(
  'share/decline',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        await ava.shares.decline(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const removeShare = createAsyncThunk(
  'share/delete',
  async ({ava, shareSym}: {ava: AvaType; shareSym?: string}) => {
    try {
      if (shareSym) {
        ava.shares.delete(shareSym);
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const sendInvites = createAsyncThunk(
  'share/sendInvite',
  async ({ava, shareSyms}: {ava: AvaType; shareSyms?: Array<string>}) => {
    try {
      if (shareSyms && shareSyms.length) {
        Promise.all(shareSyms.map((sym) => ava.shares.sendInvites(sym)));
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);

export const generateCopyLink = createAsyncThunk(
  'share/generateCopyLink',
  async ({shareSym, dispatch}: {shareSym: string; dispatch: Dispatch}): Promise<void> => {
    try {
      const link = await ava.shares.createInviteUrl(shareSym);
      if (link) {
        navigator.clipboard.writeText(link).then(() => dispatch(setIsLinkCopied(true)));
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  }
);
