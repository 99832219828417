/* eslint-disable consistent-return */
import {createAsyncThunk, Dispatch} from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify';

// Core
import {ava} from 'core/service/ava';
import {AssetType, SearchResponse} from '@burnaby/ava';
import {DealType} from 'core/_types/base';
import {Filters} from 'core/_types/Filters';
import {
  AssetInfoType,
  FinancialInfoType,
  GuarantorsType,
  OverviewType,
  SponsorProfileType,
} from 'core/_types/UnderwritingTypes';
import {statusMessage} from 'core/enums/statusMessage';
import {AcquisitionStatus} from 'core/enums/acquisitionStatus';
import {PropertyTitle} from 'core/enums/propertyTitle';
import {FinancingPurpose} from 'core/enums/financingPurpose';
import {InvestmentCategory} from 'core/enums/investmentCategory';
import {Source} from 'core/_types/selectOptionsTypes/SourcesType';
import {dateChecker} from 'core/helpers/dateChecker';
import {Use} from 'core/_types/selectOptionsTypes/UsesType';

import {GetCapitalSources, GetDeals, getDealsTypes, getOffersPropsType, getProjectPropsType} from './interfaces';
import {
  setUpdatedAssetInfo,
  setUpdatedFinancialInfo,
  setUpdatedGuarantors,
  setUpdatedGuarantorsAddCompleted,
  setUpdatedGuarantorsEditCompleted,
  setUpdatedOverview,
  setUpdatedSourcesAndUses,
  setUpdatedSponsorProfile,
} from './projectSlice';

export const getProject = createAsyncThunk(
  'project/getProject',
  async ({projectSym}: getProjectPropsType): Promise<DealType | null | undefined> => {
    try {
      let sym = projectSym;
      // If it's only numbers, it's a project ID instead of a sym.
      if (/^\d+$/.test(sym)) {
        const id = +projectSym;
        sym = await ava.deals.getSymById(id);
      }
      return await ava.deals.get(sym);
    } catch (e: any) {
      toast.error(`${e.message}`);
    }
  }
);

export const getProjects = createAsyncThunk(
  'project/getProjects',
  async ({filters}: getDealsTypes): Promise<GetDeals | undefined> => {
    try {
      const syms = await ava.deals.search(filters);
      const projectsIds = Array.from(syms.syms);
      const projects = await Promise.all(projectsIds.map((sym: string) => ava.deals.getSummary(sym)));

      return {projects, next: syms?.next, filters};
    } catch (e: any) {
      toast.error(`${e.message}`);
    }
  }
);

export const getNextProjects = createAsyncThunk(
  'project/getNextProjects',
  async ({
    nextProjects,
    projects,
  }: {
    nextProjects: () => Promise<SearchResponse>;
    projects: Array<DealType>;
  }): Promise<GetDeals | undefined> => {
    try {
      const syms = await nextProjects();
      const newProjectsIds = Array.from(syms.syms);
      const moreProjects = await Promise.all(newProjectsIds.map((sym: string) => ava.deals.get(sym)));

      const newProjects: Array<DealType> = [...projects, ...moreProjects];

      return {newProjects, next: syms?.next};
    } catch (e: any) {
      toast.error(`${e.message}`);
    }
  }
);

export const getOffers = createAsyncThunk(
  'project/getOffers',
  async ({offersSyms, ava}: getOffersPropsType): Promise<any | void> => {
    try {
      return await Promise.all(offersSyms.map((item) => ava.getOffer(item)));
    } catch (error: any) {
      toast.error(`${error.message}`);
    }
  }
);

export const concealedShares = createAsyncThunk(
  'project/update',
  async ({sym, showConcealedShares}: {sym: string; showConcealedShares: boolean}): Promise<void> => {
    try {
      await ava.deals.update({
        sym,
        showConcealedShares,
      });
      // dispatch(setUpdatedOverview(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      // dispatch(setUpdatedOverview(statusMessage.error));
    }
  }
);

export const updateOverview = createAsyncThunk(
  'project/update',
  async ({sym, fields, dispatch}: {sym: string; fields: OverviewType; dispatch: Dispatch}): Promise<void> => {
    const {name, financingPurpose, projectSummary} = fields;
    try {
      await ava.deals.update({
        sym,
        name,
        financingPurpose: FinancingPurpose[financingPurpose.value],
        projectSummary: {
          type: 'PLAIN',
          content: projectSummary,
        },
      });
      dispatch(setUpdatedOverview(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedOverview(statusMessage.error));
    }
  }
);

export const updateAssetInfo = createAsyncThunk(
  'project/update',
  async ({sym, fields, dispatch}: {sym: string; fields: AssetInfoType; dispatch: Dispatch}): Promise<void> => {
    const {
      assetType,
      propertyTitle,
      numberOfUnits,
      occupancy,
      totalSize,
      footprintSize,
      lotSize,
      address,
      architect,
      structuralEngineer,
      constructionManager,
      generalContractor,
      leasingRepresentative,
      propertyManager,
      completedYear,
      renovatedYear,
      floorsAboveGround,
      floorsBelowGround,
      designInfo,
      majorCrimes,
      lesserCrimes,
      taxesDelinquent,
      paymentsDelinquent,
      environmentalHazards,
      floodZone,
      seismicZone,
      assetNotes,
    } = fields;
    try {
      await ava.deals.update({
        sym,
        assetType: AssetType.fromString(assetType.label.toUpperCase().replace(' ', '_')),
        propertyTitle: PropertyTitle[propertyTitle.value],
        numberOfUnits: +numberOfUnits,
        occupancy: {units: +occupancy},
        totalSize: {
          unit: 'SQUARE_FEET',
          amount: +totalSize,
        },
        footprintSize: {
          unit: 'SQUARE_FEET',
          amount: +footprintSize,
        },
        lotSize: {
          unit: 'SQUARE_FEET',
          amount: +lotSize,
        },
        address: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          zip: address.zip,
        },
        architect,
        structuralEngineer,
        constructionManager,
        generalContractor,
        leasingRepresentative,
        propertyManager,
        completedYear: +completedYear,
        renovatedYear: +renovatedYear,
        floorsAboveGround: +floorsAboveGround,
        floorsBelowGround: +floorsBelowGround,
        designInfo: {type: 'PLAIN', content: designInfo},
        disclaimer: {
          majorCrimes: majorCrimes.toUpperCase(),
          lesserCrimes: lesserCrimes.toUpperCase(),
          taxesDelinquent: taxesDelinquent.toUpperCase(),
          paymentsDelinquent: paymentsDelinquent.toUpperCase(),
          environmentalHazards: environmentalHazards.toUpperCase(),
          floodZone: floodZone.toUpperCase(),
          seismicZone: seismicZone.toUpperCase(),
        },
        assetNotes: {
          type: 'PLAIN',
          content: assetNotes,
        },
      });
      dispatch(setUpdatedAssetInfo(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedAssetInfo(statusMessage.error));
    }
  }
);

export const updateFinancialInfo = createAsyncThunk(
  'project/update',
  async ({sym, fields, dispatch}: {sym: string; fields: FinancialInfoType; dispatch: Dispatch}): Promise<void> => {
    const {
      investmentCategory,
      acquisitionPrice,
      acquisitionDate,
      acquisitionStatus,
      latestAppraisalValue,
      latestAppraisalDate,
      currentNetOperatingIncome,
      proFormaNetOperatingIncome,
      capRate,
      proFormaCapRate,
      financialNotes,
      financialExpectations,
    } = fields;
    try {
      await ava.deals.update({
        sym,
        investmentCategory: InvestmentCategory[investmentCategory.value],
        acquisitionPrice: `USD ${acquisitionPrice.replace(/[\s,]/g, '')}`,
        acquisitionDate: dateChecker(acquisitionDate) === true && acquisitionDate.length ? acquisitionDate : null,
        acquisitionStatus: AcquisitionStatus[acquisitionStatus.value],
        latestAppraisalValue: `USD ${latestAppraisalValue.toString().replace(/[\s,]/g, '')}`,
        latestAppraisalDate:
          dateChecker(latestAppraisalDate) === true && latestAppraisalDate.length ? latestAppraisalDate : null,
        currentNetOperatingIncome: `USD ${currentNetOperatingIncome.toString().replace(/[\s,]/g, '')}`,
        proFormaNetOperatingIncome: `USD ${proFormaNetOperatingIncome.toString().replace(/[\s,]/g, '')}`,
        capRate: +capRate,
        proFormaCapRate: +proFormaCapRate,
        financialNotes: {
          type: 'PLAIN',
          content: financialNotes,
        },
        offerExpectations: {
          notes: {
            type: 'PLAIN',
            content: financialExpectations,
          },
        },
      });
      dispatch(setUpdatedFinancialInfo(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedFinancialInfo(statusMessage.error));
    }
  }
);

export const updateSources = createAsyncThunk(
  'project/update',
  async ({
    sym,
    fields,
    dispatch,
  }: {
    sym: string;
    fields: {sources: Array<Source>};
    dispatch: Dispatch;
  }): Promise<void> => {
    const {sources} = fields;
    try {
      await ava.deals.update({
        sym,
        capStack: {
          source: sources.map((source: Source) => ({
            ...source,
            type: source.type.value ? source.type.value : null,
            principal: source.principal ? source.principal : null,
            maturityDate:
              dateChecker(source.maturityDate) === true && source.maturityDate.length ? source.maturityDate : null,
          })),
        },
      });
      dispatch(setUpdatedSourcesAndUses(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedSourcesAndUses(statusMessage.error));
    }
  }
);

export const updateUses = createAsyncThunk(
  'project/update',
  async ({sym, fields, dispatch}: {sym: string; fields: any; dispatch: Dispatch}): Promise<void> => {
    const {uses} = fields;
    try {
      await ava.deals.update({
        sym,
        capStack: {
          use: uses.map(({use, customUse, principal}: Use) => ({
            use: use ? (use.toString() === 'Custom' && customUse ? customUse : use) : null,
            principal: principal || null,
          })),
        },
      });
      dispatch(setUpdatedSourcesAndUses(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedSourcesAndUses(statusMessage.error));
    }
  }
);

export const createGuarantor = createAsyncThunk(
  'project/update',
  async ({
    sym,
    fields,
    guarantors,
    dispatch,
  }: {
    sym: string;
    fields: GuarantorsType;
    guarantors: Array<any>;
    dispatch: Dispatch;
  }): Promise<void> => {
    const {name, address, netWorth, liquidity, creditScore} = fields;
    try {
      await ava.deals.update({
        sym,
        guarantors: {
          value: [
            ...guarantors,
            {
              name,
              address: {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                state: address.state,
                zip: address.zip,
              },
              netWorth: `USD ${netWorth.replace(/[\s,]/g, '')}`,
              liquidity: `USD ${liquidity.replace(/[\s,]/g, '')}`,
              creditScore,
            },
          ],
        },
      });
      dispatch(setUpdatedGuarantors(statusMessage.success));
      dispatch(setUpdatedGuarantorsAddCompleted(true));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedGuarantors(statusMessage.error));
    }
  }
);

export const updateGuarantors = createAsyncThunk(
  'project/update',
  async ({sym, guarantors, dispatch}: {sym: string; guarantors: Array<any>; dispatch: Dispatch}): Promise<void> => {
    try {
      await ava.deals.update({
        sym,
        guarantors: {
          value: [...guarantors],
        },
      });
      dispatch(setUpdatedGuarantors(statusMessage.success));
      dispatch(setUpdatedGuarantorsEditCompleted(true));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedGuarantors(statusMessage.error));
    }
  }
);

export const updateSponsorProfile = createAsyncThunk(
  'project/update',
  async ({sym, fields, dispatch}: {sym: string; fields: SponsorProfileType; dispatch: Dispatch}): Promise<void> => {
    const {
      sponsorRole,
      name,
      sponsorType,
      website,
      address,
      assetsUnderOwnership,
      bio,
      sponsorLawsuits,
      sponsorFelony,
      sponsorCreditScore,
    } = fields;
    try {
      await ava.deals.update({
        sym,
        sponsorRole: sponsorRole.toUpperCase().replace(' ', '_'),
        sponsorProfile: {
          sponsorType: sponsorType.toUpperCase(),
          name,
          website,
          address: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            zip: address.zip,
          },
          assetsUnderOwnership: `USD ${assetsUnderOwnership.replace(/[\s,]/g, '')}`,
          description: {
            type: 'PLAIN',
            content: bio,
          },
        },
        disclaimer: {
          sponsorLawsuits: sponsorLawsuits.toUpperCase(),
          sponsorFelony: sponsorFelony.toUpperCase(),
          sponsorCreditScore: sponsorCreditScore.toUpperCase(),
        },
      });
      dispatch(setUpdatedSponsorProfile(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      dispatch(setUpdatedSponsorProfile(statusMessage.error));
    }
  }
);

export const createProject = createAsyncThunk('project/create', async ({fields}: {fields: any}): Promise<string> => {
  const {name} = fields;
  try {
    return await ava.deals.create({
      name: name || '',
      address: fields && {
        line1: fields.line1 || '',
        line2: '',
        city: fields.city || '',
        state: fields.state || '',
        zip: fields.zip || '',
      },
      principalNeeded: 'USD 1234',
    });
  } catch (error: any) {
    toast.error(error.message);
    return '';
  }
});

export const getCapitalSources = createAsyncThunk(
  'project/getCapitalSources',
  async ({projectSym, filters}: {projectSym: string; filters: Filters}): Promise<GetCapitalSources | undefined> => {
    try {
      const response = await ava.providers.search(projectSym, filters);
      const syms = Array.from(response.syms);
      const providers = await Promise.all(syms.map((sym) => ava.providers.get(sym)));

      return {providers, next: response?.next};
    } catch (err: any) {
      toast.error(`${err.message}`);
    }
  }
);

export const inviteSponsorUser = createAsyncThunk(
  'project/inviteSponsorUser',
  async ({sym, email}: {sym: string; email: string}): Promise<void> => {
    try {
      await ava.deals.update({
        sym,
        inviteSponsorUserEmail: email,
      });
    } catch (err: any) {
      toast.error(`${err.message}`);
    }
  }
);

export const deleteSponsorUser = createAsyncThunk(
  'project/inviteSponsorUser',
  async ({sym, email}: {sym: string; email: string}): Promise<void> => {
    try {
      await ava.deals.update({
        sym,
        removeSponsorUserEmail: email,
      });
    } catch (err: any) {
      toast.error(`${err.message}`);
    }
  }
);

export const updateImages = createAsyncThunk(
  'project/update',
  async ({sym, images, dispatch}: {sym: string; images: Array<File>; dispatch: Dispatch}): Promise<void> => {
    try {
      Promise.all(images.map((image) => ava.deals.images.add(sym, image)));
      // dispatch(setUpdatedImages(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      // dispatch(setUpdatedImages(statusMessage.error));
    }
  }
);

export const updateAttachments = createAsyncThunk(
  'project/update',
  async ({sym, attachments, dispatch}: {sym: string; attachments: Array<any>; dispatch: Dispatch}): Promise<void> => {
    try {
      Promise.all(attachments.map((attachment) => ava.deals.attachments.add(sym, attachment)));
      // dispatch(setUpdatedAttachments(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      // dispatch(setUpdatedAttachments(statusMessage.error));
    }
  }
);

export const deleteImage = createAsyncThunk(
  'project/update',
  async ({sym, imageSym, dispatch}: {sym: string; imageSym: string; dispatch: Dispatch}): Promise<void> => {
    try {
      await ava.deals.images.delete(sym, imageSym);
      // dispatch(setUpdatedImages(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      // dispatch(setUpdatedImages(statusMessage.error));
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  'project/update',
  async ({sym, attachmentSym, dispatch}: {sym: string; attachmentSym: string; dispatch: Dispatch}): Promise<void> => {
    try {
      await ava.deals.attachments.delete(sym, attachmentSym);
      // dispatch(setUpdatedAttachments(statusMessage.success));
    } catch (error: any) {
      toast.error(error.message);
      // dispatch(setUpdatedAttachments(statusMessage.error));
    }
  }
);
