import React, {FC, ReactElement} from 'react';
import {Field, FieldProps} from 'formik';

type FormikFieldProps = {
  name: string;
  children: ReactElement;
};

const FormikField: FC<FormikFieldProps> = ({children, name}) => (
  <Field name={name}>{({field, meta}: FieldProps) => React.cloneElement(children, {meta, field})}</Field>
);

export default FormikField;
