import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {InterestRateSubsteps, QuoteTermsSteps, yesNoOptions} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setRateResets} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectRateResets} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.yes
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
        substep: InterestRateSubsteps[InterestRateSubsteps.resetYear],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
        substep: InterestRateSubsteps[InterestRateSubsteps.rateDetails],
      };

const Resets = () => {
  const defaultValue = useSelector(selectRateResets);
  const [isAnyResets, setIsAnyResets] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isAnyResets]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Are there any interest rate resets?</p>
      <RadioGroup
        isAnyValueSelected={isAnyResets}
        setIsAnyValueSelected={setIsAnyResets}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isAnyResets.length}
        slideData={{[InterestRateSubsteps[InterestRateSubsteps.rateResets]]: isAnyResets}}
        action={setRateResets}
        nextSlide={handleSelectedValue(isAnyResets[0])}
      />
    </div>
  );
};

export default Resets;
