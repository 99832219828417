import React, {FC, useState} from 'react';
import cx from 'classnames';
import {makeStyles} from '@mui/styles';
import BasicButton from 'shared/components/BasicButton';
import {Skeleton} from '@mui/material';
import styles from './PhotoGallery.module.scss';

const useStyles = makeStyles({
  button: {
    width: '130px',
    height: '40px',
    marginRight: 0,
  },
  skeleton: {
    borderRadius: '10px',
  },
});

type PhotoGalleryProps = {
  images: Array<string>;
  setGalleryItemNumber: (itemNumber: number) => void;
};

const PhotoGallery: FC<PhotoGalleryProps> = ({images, setGalleryItemNumber}) => {
  const [loadedImages, setLoadedImages] = useState<Array<number>>([]);

  const classes = useStyles();

  const isImageLoaded = (index: number) => loadedImages.includes(index);

  return (
    <>
      {!!images.length && (
        <div className={styles.gallery}>
          <div className={styles.Title}>
            <div className="LenderView__infoContentTitle">Photos</div>
            <BasicButton className={cx(classes.button, 'blue')} variant="contained">
              Download All
            </BasicButton>
          </div>
          <div className={styles.gallery__content}>
            {images.map((imageUrl, index) => (
              <React.Fragment key={imageUrl}>
                <div className={styles.gallery__item}>
                  {!isImageLoaded(index) && (
                    <div className="LenderView__loader">
                      <Skeleton variant="rectangular" sx={{borderRadius: '10px'}} width="100%" height="100%" />
                    </div>
                  )}
                  <img
                    onClick={() => setGalleryItemNumber(index)}
                    className={styles.gallery__image}
                    src={imageUrl}
                    alt="Galery item"
                    onLoad={() => setLoadedImages([...loadedImages, index])}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PhotoGallery;
