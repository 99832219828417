import {FC, useMemo} from 'react';
import {Formik} from 'formik';

// Core
import {DealType} from 'core/_types/base';
import {FinancialInfoType} from 'core/_types/UnderwritingTypes';

import FinancialInfoForm from './FinancialInfoForm';

type FinancialInfoProps = {
  project: DealType;
};

const FinancialInfo: FC<FinancialInfoProps> = ({project}) => {
  const initialValues: FinancialInfoType = {
    investmentCategory: {
      value: project?.investmentCategory,
      label: project?.formatInvestmentCategory(),
    },
    acquisitionPrice: project?.acquisitionPrice.format().replace('$', ''),
    acquisitionDate: project?.acquisitionDate.formatIso(),
    acquisitionStatus: {
      value: project?.acquisitionStatus,
      label: project?.formatAcquisitionStatus(),
    },
    latestAppraisalValue: project?.latestAppraisalValue.toNumber(),
    latestAppraisalDate: project?.latestAppraisalDate.formatIso(),
    currentNetOperatingIncome: project?.currentNetOperatingIncome.format().replace('$', ''),
    proFormaNetOperatingIncome: project?.proFormaNetOperatingIncome.format().replace('$', ''),
    capRate: project?.capRate.formatAsNumber(),
    proFormaCapRate: project?.proFormaCapRate.formatAsNumber(),
    // totalProjectBudget: project?.totalProjectBudget.toNumber(),
    financialNotes: project?.financialNotes.formatAsPlainText(),
    // capitalizationInvestmentCategory: '',
    // loanAmountFrom: project?.principalNeeded.lowerBound.format().replace('$', ''),
    // loanAmountTo: project?.principalNeeded.upperBound.format().replace('$', ''),
    // principalNeeded: project?.principalNeeded.format().replace('$', ''),
    // capitalizationType: 'range',
    // financialInstrument: project?.financialInstruments,
    // investments: [
    //   {
    //     id: 1,
    //     type: '',
    //     amount: '',
    //     entity: '',
    //   },
    // ],
    // loanTerm: project?.offerExpectations.loanTermRange.format(),
    // amortization: project.offerExpectations.repaymentSchedule,
    // capitalizationPeriod: [1, 11],
    financialExpectations: project?.offerExpectations?.notes.formatAsPlainText(),
  };

  const capRateValuation = useMemo(() => project?.capRateValuation.format(), [project]) || '-';
  const valuationPerUnit = useMemo(() => project?.valuationPerUnit.formatUnit(), [project]) || '-';
  const valuationPerUnitValue = useMemo(() => project?.valuationPerUnit.value.format(), [project]) || '-';
  const ltv = useMemo(() => project?.ltv.formatMaxDecimalPlaces(3), [project]) || '-';
  const ltc = useMemo(() => project?.ltc.formatMaxDecimalPlaces(3), [project]) || '-';
  const debtYield = useMemo(() => project?.debtYield.formatMaxDecimalPlaces(3), [project]) || '-';
  const loanTermRange = useMemo(() => project?.offerExpectations.loanTermRange.format(), [project]) || '-';

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <FinancialInfoForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
          capRateValuation={capRateValuation}
          valuationPerUnit={valuationPerUnit}
          valuationPerUnitValue={valuationPerUnitValue}
          ltv={ltv}
          ltc={ltc}
          debtYield={debtYield}
          loanTermRange={loanTermRange}
        />
      )}
    </Formik>
  );
};

export default FinancialInfo;
