import {useState} from 'react';
import NumericInput from 'shared/components/NumericInput';
import RadioInput from 'shared/components/RadioInput';
import NextButton from 'shared/components/NextButton';
import {QuoteTermsSteps, dateOptions, PrepayPenaltySubsteps} from 'core/_consts';
import {getMaxLimit, validateNumericInput} from 'core/utils';
import {setLockoutDuration} from 'store/quote-terms-service/quoteTermsSlice';
import {selectLockoutDuration} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';

const LockoutDuration = () => {
  const defaultValues = useSelector(selectLockoutDuration);
  const [dateFormat, setDateFormat] = useState(defaultValues.dateFormat);
  const [lockoutDurationValue, setLockoutDurationValue] = useState(defaultValues.lockoutDurationValue);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How long is the lockout?</p>
      <div className="FormSlide__section">
        <NumericInput
          defaultValue={lockoutDurationValue || ''}
          preventionLimit={getMaxLimit(dateFormat)}
          onValueChange={(values) => setLockoutDurationValue(Number(values.value))}
          isValid={validateNumericInput(Number(lockoutDurationValue), getMaxLimit(dateFormat))}
          width="60px"
        />
        <RadioInput defaultValue={dateFormat} setRadioInputValue={setDateFormat} values={Object.values(dateOptions)} />
      </div>
      <NextButton
        isDisabled={!validateNumericInput(Number(lockoutDurationValue), getMaxLimit(dateFormat))}
        slideData={{
          [PrepayPenaltySubsteps[PrepayPenaltySubsteps.lockoutDuration]]: {dateFormat, lockoutDurationValue},
        }}
        action={setLockoutDuration}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.initialDeposit],
        }}
      />
    </div>
  );
};

export default LockoutDuration;
