import {useFormikContext} from 'formik';
import {useEffect} from 'react';
import {Prompt} from 'react-router-dom';

export const UnsavedChangesPrompt = ({
  message = 'It looks like you have been editing something. If you leave before saving, your changes will be lost.',
}) => {
  const {dirty, submitCount} = useFormikContext();

  useEffect(() => {
    if (dirty && submitCount === 0) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [dirty, submitCount]);

  return <Prompt when={false} message={message} />;
};
