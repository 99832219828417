import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {CashManagementSubsteps, QuoteTermsSteps, yesNoOptions} from 'core/_consts';
import {setIsLockboxNeeded} from 'store/quote-terms-service/quoteTermsSlice';
import {selectIsLockboxNeeded} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.yes
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.cashManagement],
        substep: CashManagementSubsteps[CashManagementSubsteps.selectLockboxType],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.borrowerStipulations],
      };

const IsLockboxNeeded = () => {
  const defaultValue = useSelector(selectIsLockboxNeeded);
  const [isLockbox, setIsLockbox] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isLockbox]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Is a lockbox for cash managament needed?</p>
      <RadioGroup
        isAnyValueSelected={isLockbox}
        setIsAnyValueSelected={setIsLockbox}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isLockbox.length}
        slideData={{[CashManagementSubsteps[CashManagementSubsteps.isLockboxNeeded]]: isLockbox}}
        action={setIsLockboxNeeded}
        nextSlide={handleSelectedValue(isLockbox[0])}
      />
    </div>
  );
};

export default IsLockboxNeeded;
