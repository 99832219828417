import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {extensionsAmountOptions, LoanTermSubsteps, QuoteTermsSteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setExtensionsAmount} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectExtensionsAmount} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const ExtensionsAmount = () => {
  const defaultValue = useSelector(selectExtensionsAmount);
  const [amountOfExtensions, setAmountOfExtensions] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [amountOfExtensions]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How many extensions?</p>
      <RadioGroup
        additionalClassName="reducedButtons"
        isAnyValueSelected={amountOfExtensions}
        setIsAnyValueSelected={setAmountOfExtensions}
        radioButtonTitles={Object.values(extensionsAmountOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!amountOfExtensions.length}
        slideData={{[LoanTermSubsteps[LoanTermSubsteps.extensionsAmount]]: amountOfExtensions}}
        action={setExtensionsAmount}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
          substep: LoanTermSubsteps[LoanTermSubsteps.lengthAndFee],
        }}
      />
    </div>
  );
};

export default ExtensionsAmount;
