import {PropertyTitle} from '../../_types/selectOptionsTypes/AssetInfoTypes';

export const propertyTitleOptions: Array<PropertyTitle> = [
  {value: 'UNSET', label: 'Unset'},
  {value: 'FEE_SIMPLE', label: 'Fee simple'},
  {value: 'LEASEHOLD', label: 'Leasehold'},
];

// TODO: Refactor this to match Ava's new AssetType enum
export const assetTypeOptions = [
  {value: 'OFFICE', label: 'Office'},
  {value: 'INDUSTRIAL', label: 'Industrial'},
  {value: 'RETAIL', label: 'Retail'},
  {value: 'HOSPITALITY', label: 'Hospitality'},
  {value: 'LAND', label: 'Land'},
  {value: 'SELF_STORAGE', label: 'Self Storage'},
  {value: 'RELIGIOUS', label: 'Religious'},
  {value: 'HOSPITAL', label: 'Hospital'},
  {value: 'OTHER', label: 'Other'},
  {value: 'APPARTMENTS', label: 'Apartments'},
  {value: 'CONDOMINIUMS', label: 'Condominiums'},
  {value: 'SENIOR_APARTMENTS', label: 'Senior Apartments'},
  {value: 'STUDENT_HOUSING', label: 'Student Housing'},
  {value: 'ASSISTED_LIVING', label: 'Assisted Living'},
  {value: 'RENTAL_PORTFOLIO', label: 'Rental Portfolio'},
  {value: 'MOBILE_HOME_PARK', label: 'Mobile Home Park'},
  {value: 'CO_LIVING', label: 'Co-Living'},
];
