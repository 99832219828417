import {useState, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import * as yup from 'yup';

// Core
import {ROUTERS} from 'core/_consts';

// Store
import {selectorGetProject} from 'store/project-service/selector';

// Components
import {ReactComponent as LogoIcon} from 'shared/media/Logo.svg';
import {StyledCircularProgress} from 'shared/components/CircularLoader';
import BasicButton from 'shared/components/BasicButton';
import TextInput from 'shared/components/TextInput';

import s from './SignUp.module.scss';

const SignUp = () => {
  const history = useHistory();
  const project = useSelector(selectorGetProject);
  const [loaderState] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: useMemo(() => project?.acquisitionDate.formatIso(), [project]) || '',
    min: '',
    max: '',
    email: '',
    password: '',
  };

  const validationSchema = yup.object().shape({
    dateOfBirth: yup.string().required('Email is required!'),
    email: yup.string().required('Email is required!'),
    password: yup.string().required('Password is required!'),
  });

  if (loaderState && !project) return <StyledCircularProgress />;

  return (
    <div className={s.signUp}>
      <div className={s.signUp__container}>
        <div className={s.header}>
          <LogoIcon className={s.header__logo} />
          <p className={s.header__title}>StackSource</p>
        </div>
        <div className={s.signUp__form}>
          <h2 className={s.signUp__heading}>Create an account</h2>
          <p className={s.signUp__subtitle}>
            Already have an account?
            <span className={s.signUp__sublink} onClick={() => history.push(`${ROUTERS.LOGIN}`)}>
              Sign in
            </span>
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, {resetForm}) => {
            resetForm();
          }}
        >
          {({values, handleChange}) => (
            <>
              <Form className={s.form}>
                <div className={s.form__field}>
                  <TextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    onValueChange={handleChange}
                    value={values.firstName}
                    width="280px"
                  />
                </div>
                <div className={s.form__field}>
                  <TextInput
                    label="Last Name"
                    name="lastName"
                    type="text"
                    onValueChange={handleChange}
                    value={values.lastName}
                    width="280px"
                  />
                </div>
                <div className={s.form__field}>
                  <label htmlFor="acquisitionPicker" className={s.signUp__label}>
                    Acquisition Date
                  </label>
                  <input
                    id="dateOfBirth"
                    type="date"
                    name="dateOfBirth"
                    value={values.dateOfBirth}
                    onChange={handleChange}
                    className={s.signUp__datePicker}
                    width="280px"
                  />
                </div>
                <div className={s.form__field}>
                  <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    onValueChange={handleChange}
                    value={values.email}
                    width="280px"
                  />
                </div>
                <div className={s.form__field}>
                  <TextInput
                    label="Password"
                    name="password"
                    type="password"
                    onValueChange={handleChange}
                    value={values.password}
                    width="280px"
                  />
                </div>
                <div className={s.form__actions}>
                  <p className={s.form__actions__container}>
                    Password strength: <span className={s.form__password}> Good</span>
                  </p>
                </div>
                <BasicButton type="submit" variant="contained" className={s.form__submit}>
                  Register
                </BasicButton>
              </Form>
              <div className={s.footer}>
                <p className={s.footer__policy}>
                  By registering, you agree to our{' '}
                  <a
                    className={s.signUp__link}
                    href="https://www.stacksource.com/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms
                  </a>{' '}
                  and{' '}
                  <a
                    className={s.signUp__link}
                    href="https://www.stacksource.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>
                </p>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SignUp;
