import {FC, useState, useEffect} from 'react';
import {Divider} from '@mui/material';
import {makeStyles} from '@mui/styles';
import cx from 'classnames';
import {
  LoanAmountSubsteps,
  QuoteTermsSteps,
  ROUTERS,
  AmortizationSubsteps,
  CashManagementSubsteps,
  InterestRateSubsteps,
  LoanFeesSubsteps,
  LoanTermSubsteps,
  PrepayPenaltySubsteps,
  RecourseSubsteps,
  RequiredReservesSubsteps,
  storageKey,
} from 'core/_consts';
import {vaultService} from 'core/service/storage';

import {useHistory, Link, matchPath, useLocation, useParams} from 'react-router-dom';
import BasicButton from 'shared/components/BasicButton';
import BasicTooltip from 'shared/components/BasicTooltip';
import DataCell from 'pages/LenderView/QuoteTermsWrapper/Steps/NonBindingOffer/DataCell';
import {queryParams} from 'core/utils';
import {URLParams} from 'core/_types/base';
import s from './NonBindingOffer.module.scss';

const useStyles = makeStyles({
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#b3d5f8',
    color: 'white',
    cursor: 'default',
  },
});

const dataCellTitles = [
  {
    title: 'Principal',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.loanAmount],
    substep: LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints],
  },
  {
    title: 'Loan Term',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
    substep: LoanTermSubsteps[LoanTermSubsteps.loanTermDuration],
  },
  {
    title: 'Interest Rate',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
    substep: InterestRateSubsteps[InterestRateSubsteps.rateDetails],
  },
  {
    title: 'Amortization',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.amortization],
    substep: AmortizationSubsteps[AmortizationSubsteps.amortizationPeriod],
  },
  {
    title: 'Recourse',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.recourse],
    substep: RecourseSubsteps[RecourseSubsteps.availableRecourse],
  },
  {
    title: 'Pre-payment',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
    substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty],
  },
  {title: 'Deposit', data: 'mock', step: QuoteTermsSteps[QuoteTermsSteps.initialDeposit]},
  {
    title: 'Fees',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.loanFees],
    substep: LoanFeesSubsteps[LoanFeesSubsteps.selectLoanFees],
  },
  {
    title: 'Required Reserves',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.requiredReserves],
    substep: RequiredReservesSubsteps[RequiredReservesSubsteps.selectRequiredReserves],
  },
  {
    title: 'Cash Management',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.cashManagement],
    substep: CashManagementSubsteps[CashManagementSubsteps.isLockboxNeeded],
  },
  {
    title: 'Borrower Stipulations',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.borrowerStipulations],
  },
  {
    title: 'Legal Term Sheet',
    data: 'mock',
    step: QuoteTermsSteps[QuoteTermsSteps.otherRequirements],
  },
  {
    title: 'Other Requirements',
    data: '3 months of interest reserves will go toward the first 3 months’ payments. (Sponsor’s first out-of-pocket debt service payment will be in month 4.) 1% fee associated with extension options.',
    step: QuoteTermsSteps[QuoteTermsSteps.otherRequirements],
    reversedStyles: true,
  },
];

type NonBindingOfferProps = {
  setUserOffers: (value: Array<string>) => void;
  position: string;
};

const NonBindingOffer: FC<NonBindingOfferProps> = ({setUserOffers, position}) => {
  const [isEditingMode, setIsEditingMode] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const params = useParams<URLParams>();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || '';

  const classes = useStyles();

  useEffect(
    () =>
      setIsEditingMode(
        !!matchPath(location.pathname, {
          path: `${ROUTERS.NON_BINDING_OFFER}/editing`,
        })
      ),
    [location]
  );

  const saveChanges = () => {
    setIsEditingMode(false);
    history.push(
      `${ROUTERS.PROJECT}:${params.dealSym}/view/non-binding-offer${queryParams({formPosition: position})}${
        location.hash
      }`
    );
  };

  const withdraw = () => {
    history.push(`${ROUTERS.PROJECT}:${params.dealSym}/view${location.hash}`);
    vaultService.removeItems([storageKey.quoteTermsData]);
  };

  const duplicateOffer = () => {
    setUserOffers(['1', '2']);
  };

  return (
    <div className={s.nonBindingOffer}>
      <div className={cx(s.lenderProfile, 'mb25')}>
        <div className={s.lenderProfile__image} />
        <div className={s.lenderProfile__credentials}>
          <p className="fs18 fw600">Lender Name</p>
          <p className={s.lenderProfile__modified}>last modified date</p>
        </div>
      </div>
      {isEditingMode && (
        <div className={cx(s.nonBindingOffer__infoBanner, 'mb25')}>
          <p className="fs18 text-bold">To make changes, use the edit link in the section you want to change.</p>
        </div>
      )}
      <div className={cx(s.nonBindingOffer__dataTable, 'mb35')}>
        {dataCellTitles.map((cell) => (
          <DataCell
            currentPosition={position}
            step={cell.step}
            substep={cell.substep}
            key={cell.title}
            data={cell.data}
            title={cell.title}
            isEditingMode={isEditingMode}
            reversedStyles={cell.reversedStyles}
          />
        ))}
      </div>
      <div className={cx(s.navigationPanel, 'flexWrap')}>
        <div className={s.navigationPanel__links}>
          <Link
            className="link fs18"
            to={`${ROUTERS.PROJECT}:${params.dealSym}/view${queryParams({
              step: QuoteTermsSteps[QuoteTermsSteps.loanAmount],
              substep: LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints],
              formPosition: position,
            })}${location.hash}`}
          >
            Create a new offer
          </Link>
          <Divider orientation="vertical" flexItem />
          <Link
            className="link fs18"
            to={`${ROUTERS.PROJECT}:${params.dealSym}/view/non-binding-offer${queryParams({formPosition: position})}${
              location.hash
            }`}
            onClick={duplicateOffer}
          >
            Duplicate this offer
          </Link>
          <BasicTooltip
            title="You can duplicate an offer and then make changes when you want to create multiple offers with similar terms."
            arrow
            placement="top"
          >
            <div className={classes.hint}>?</div>
          </BasicTooltip>
        </div>
        <div className={s.navigationPanel__stack}>
          {!isEditingMode && (
            <>
              <BasicButton className={cx(s.navigationPanel__button, 'white')} variant="contained" onClick={withdraw}>
                Withdraw
              </BasicButton>
              <BasicButton
                className={cx(s.navigationPanel__button, 'blue')}
                variant="contained"
                onClick={() =>
                  history.push(`${location.pathname}/editing${queryParams({formPosition})}${location.hash}`)
                }
              >
                Make Changes
              </BasicButton>
            </>
          )}
          {isEditingMode && (
            <BasicButton className={cx(s.navigationPanel__button, 'blue')} variant="contained" onClick={saveChanges}>
              Changes Complete
            </BasicButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonBindingOffer;
