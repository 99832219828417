import {Button} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {THEMES} from '../helpers';

const BasicButton = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    width: (props) => props.width,
    '&.white': {
      backgroundColor: 'white',
      color: '#5c6166',
      border: '1px solid #b4b4b4',
    },
    '&.blue': {
      backgroundColor: '#1c81ff',
      color: 'white',
    },
    '&.darkBlue': {
      backgroundColor: THEMES.darkBlue,
      color: 'white',
    },
    '&.grey': {
      backgroundColor: '#848484',
      color: 'white',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
    '&.nextButton': {
      width: '140px',
      '@media(max-width: 375px)': {
        width: '100%',
      },
    },
    '&.text': {
      padding: 0,
      color: THEMES.darkBlue,
      textDecoration: 'none',
    },
    '&.filter': {
      display: 'none',
      '@media(max-width: 425px)': {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0',
        fontSize: '16px',
      },
    },
    '&.link': {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: 0,
      minWidth: 0,
      color: THEMES.darkBlue,
      font: '400 14px Source Sans Pro',
      textTransform: 'none',
      textDecoration: 'underline',
    },
  },
})(Button);

export default BasicButton;
