/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
class VaultService {
  storage = localStorage;

  setItem<T>(key: string, value: T): void {
    try {
      return this.storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
    }
  }

  updateItem<T>(key: string, value: T): void {
    try {
      const prevValue = JSON.parse(this.storage.getItem(key) as string);
      const newValue = {...prevValue, ...value};
      return this.storage.setItem(key, JSON.stringify(newValue));
    } catch (e) {
      console.warn(e);
    }
  }

  removeItem(key: string, propertyToDelete: string): void {
    const selectedItem: any = this.getItem(key);
    delete selectedItem?.[propertyToDelete];
    vaultService.setItem(key, selectedItem);
  }

  removeItems(keys: Array<string>): void {
    keys.forEach((key) => this.storage.removeItem(key));
  }

  // TODO: Remove any
  getItem<T = string>(key: string): T | any | undefined {
    try {
      return JSON.parse(this.storage.getItem(key) as string) as T;
    } catch (e) {
      console.warn(e);
      return;
    }
  }

  clearStorage(): void {
    this.storage.clear();
  }
}

const vaultService = new VaultService();

export {vaultService};
