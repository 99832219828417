import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from '@mui/material';

import {ReactComponent as WebsiteIcon} from 'assets/icons/website.svg';
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg';
import {ReactComponent as EmailIcon} from 'assets/icons/email.svg';

import {deleteSponsorUser} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';

import s from './DealTeam.module.scss';

type MemberData = {
  displayName?: string;
  website?: string;
  phoneNumber?: any;
  email: string;
};

type TeamMemberProps = {
  data: MemberData;
  memberRole: string;
  userSettings: any;
  index?: any;
  sponsors?: any;
  setSponsorsEmails?: any;
};

const TeamMember: FC<TeamMemberProps> = ({data, memberRole, userSettings, index, sponsors, setSponsorsEmails}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);

  const deleteUser = (): void => {
    dispatch(deleteSponsorUser({sym: project.sym, email: data.email}));
    const newSponsors = sponsors.map((item: any) => item.email);
    newSponsors.splice(index, 1);
    console.log(newSponsors);
    setSponsorsEmails(newSponsors);
  };

  return (
    <div className={s.DealTeam__item}>
      <div>
        <div className={s.DealTeam__item_header}>
          <div className={s.DealTeam__member}>
            <p>{data.displayName}</p>
            <small className={s.DealTeam__member_role}>{memberRole}</small>
          </div>

          <Button
            sx={{
              marginTop: '-5px',
              font: '400 14px Source Sans Pro',
              textTransform: 'none',
              textDecoration: 'underline',

              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={deleteUser}
          >
            Delete user
          </Button>
        </div>
      </div>

      <div>
        {data.website && (
          <div className={s.DealTeam__info}>
            <WebsiteIcon className={s.DealTeam__info_icon} />
            <a href="stacksource.com" className={s.DealTeam__info_link}>
              {data.website}
            </a>
          </div>
        )}
        {!data.phoneNumber.isEmpty && (
          <div className={s.DealTeam__info}>
            <PhoneIcon className={s.DealTeam__info_icon} />
            <a href="stacksource.com" className={s.DealTeam__info_link}>
              {data.phoneNumber.format(userSettings)}
            </a>
          </div>
        )}
        <div className={s.DealTeam__info}>
          <EmailIcon className={s.DealTeam__info_icon} />
          <a href="stacksource.com" className={s.DealTeam__info_link}>
            {data.email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
