import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Pages
import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {
  AssetTypes,
  HospitalityInvestmentPropertyTypes,
  isValue,
  OfficeInvestmentPropertyTypes,
  TenantsTypes,
} from 'pages/CreateNewOffer/helpers';

// Shared
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import StepTextInput from 'shared/components/StepTextInput';

// Style
import s from './First.module.scss';

type FirstProps = {
  goNext: () => void;
};

const First: FC<FirstProps> = ({goNext}) => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.assetType);

  const office = AssetTypes[AssetTypes.Office];
  const hospitality = AssetTypes[AssetTypes.Hospitality];
  const hotel = HospitalityInvestmentPropertyTypes[HospitalityInvestmentPropertyTypes['Flagged/Branded Hotel']];
  const investment = OfficeInvestmentPropertyTypes[OfficeInvestmentPropertyTypes.Investment];
  const singleTenant = TenantsTypes[TenantsTypes['Single Tenant']];

  const assetTypeTitles = (Object.keys(AssetTypes) as Array<keyof typeof AssetTypes>).filter(isValue);

  const investmentTitles =
    values.assetType.value === office
      ? (Object.keys(OfficeInvestmentPropertyTypes) as Array<keyof typeof OfficeInvestmentPropertyTypes>).filter(
          isValue
        )
      : (
          Object.keys(HospitalityInvestmentPropertyTypes) as Array<keyof typeof HospitalityInvestmentPropertyTypes>
        ).filter(isValue);

  const tenantsTitles = (Object.keys(TenantsTypes) as Array<keyof typeof TenantsTypes>).filter(isValue);

  const mockOptions = ['option 1', 'option 2', 'option 3'];

  const hotelFormContent = (
    <div className={s.first__form}>
      <div className={s.first__inputs}>
        <StepSelect
          placeholder="Select Hotel Flag"
          label="Hotel Flag"
          name="hotelFlag"
          value={values.hotelFlag}
          theme={THEMES.white}
          options={mockOptions}
          handleChange={handleChange}
        />
        <div className={s.first__container}>
          <label htmlFor="datePicker" className={s.first__label}>
            Know the expiration date
          </label>
          <input
            id="datePicker"
            type="date"
            name="hotelExpirationDate"
            value={values.hotelExpirationDate}
            onChange={handleChange}
            className={s.first__datePicker}
          />
        </div>
      </div>
      <UnsavedChangesPrompt />
    </div>
  );

  const singleTenantForm = (
    <div className={s.first__form}>
      <div className={s.first__tenantInput}>
        <StepTextInput
          id="tenants"
          name="tenants"
          label="Tenants"
          value={values.tenants}
          onValueChange={handleChange}
          required
          width="300px"
        />
      </div>
      <UnsavedChangesPrompt />
    </div>
  );

  const tenantsContent = (
    <div className={s.first}>
      <div className={s.first__header}>
        <p className={s.first__title}>Tenants</p>
      </div>
      <div className={s.first__cards}>
        {tenantsTitles.map((tenantsTitles) => (
          <SelectionCard
            key={tenantsTitles}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('tenantsType.value', tenantsTitles);
              setFieldValue('tenantsType.isPicked', true);
              setSelectedCard(tenantsTitles);
              if (tenantsTitles !== singleTenant) {
                goNext();
              }
            }}
            title={tenantsTitles}
            iconName={tenantsTitles.toLocaleLowerCase()}
          />
        ))}
      </div>
      {values.tenantsType.value === singleTenant && values.tenantsType.isPicked && singleTenantForm}
    </div>
  );

  return (
    <>
      {!values.assetType.isPicked ? (
        <div className={s.first}>
          <div className={s.first__header}>
            <p className={s.first__title}>What is the primary asset type of your project?</p>
            <p className={s.first__subtitle}>
              If your project’s is mixed use, choose the asset type which represents the greatest portion.
            </p>
          </div>
          <div className={s.first__cards}>
            {assetTypeTitles.map((assetTypeTitles) => (
              <SelectionCard
                key={assetTypeTitles}
                selectedCard={selectedCard}
                handleCardSelection={() => {
                  setFieldValue('assetType.value', assetTypeTitles);
                  setFieldValue('assetType.isPicked', true);
                  setSelectedCard(assetTypeTitles);
                  if (assetTypeTitles !== office && assetTypeTitles !== hospitality) {
                    goNext();
                  }
                }}
                title={assetTypeTitles}
                iconName={assetTypeTitles.toLocaleLowerCase()}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          {values.investmentProperty.value !== investment ? (
            <div className={s.first}>
              <div className={s.first__header}>
                {values.investmentProperty.value === hotel && values.investmentProperty.isPicked ? (
                  <p className={s.first__title}>Hotel Flag</p>
                ) : (
                  <p className={s.first__title}>Is this an investment property?</p>
                )}
                {values.assetType.value === office && values.investmentProperty.value !== investment && (
                  <p className={s.first__subtitle}>
                    Choose owner-occupied if the owner occupies more the 50% of the available square footage.
                  </p>
                )}
              </div>
              <div className={s.first__cards}>
                {investmentTitles.map((investmentTitles) => (
                  <SelectionCard
                    key={investmentTitles}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    handleCardSelection={() => {
                      setFieldValue('investmentProperty.value', investmentTitles);
                      setFieldValue('investmentProperty.isPicked', true);
                      setSelectedCard(investmentTitles);
                      if (investmentTitles !== hotel && investmentTitles !== investment) {
                        goNext();
                      }
                    }}
                    title={investmentTitles}
                    iconName={investmentTitles.toLocaleLowerCase().replace('/', '')}
                  />
                ))}
              </div>
              {values.investmentProperty.value === hotel && values.investmentProperty.isPicked && hotelFormContent}
            </div>
          ) : (
            tenantsContent
          )}
        </>
      )}
    </>
  );
};

export {First};
