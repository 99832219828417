import {FC, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {selectorGetProject} from 'store/project-service/selector';
import {QuoteTermsFormStatus, ROUTERS, windowSizes} from 'core/_consts';
import {useLocation, matchPath} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import useWindowWidth from 'core/utils/hooks/useWindowWidth';
import Header from 'shared/components/Header';
import ProjectNameHeader from './ProjectNameHeader';
import ProjectInfoCard from './ProjectInfoCard';
import PhotoSlider from './PhotoSlider';
import QuoteTermsButtons from './QuoteTermsButtons';
import Summary from './Summary';
import PhotoGallery from './PhotoGallery';
import SourcesAndUses from './SourcesAndUses';
import DesignAndConstruction from './DesignAndConstruction';
import Guarantor from './Guarantor';
import Sponsor from './Sponsor';
import Attachments from './Attachments';
import UsersPanel from './UsersPanel';
import LoanRequest from './LoanRequest';
import ProFormaSummary from './ProFormaSummary';
import KeyStats from './KeyStats';
import SingleTenant from './SingleTenant';
import QuoteTermsWrapper from './QuoteTermsWrapper';
import NonBindingOffer from './QuoteTermsWrapper/Steps/NonBindingOffer';
import OfferAccordion from './QuoteTermsWrapper/OfferAccordion';

import s from './LenderView.module.scss';
import GoogleMap from './GoogleMap';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
  },
});

const LenderView: FC = () => {
  // TODO: get valid data from server (at least consist of current offer (submited on the form)) (Array<DataObjects>)
  const [userOffers, setUserOffers] = useState(['1']);
  const [images, setImages] = useState<Array<string>>([]);
  const [galleryItemNumber, setGalleryItemNumber] = useState(-1);
  const [isAllOpened, setisAllOpened] = useState<boolean>(false);
  const project = useSelector(selectorGetProject);

  const location = useLocation();
  const formPosition = new URLSearchParams(location.search).get('formPosition') || QuoteTermsFormStatus.closed;

  const windowWidth = useWindowWidth();

  const isMobileView = windowWidth <= windowSizes.mobileMiddle;

  const isQuoteTermsFormOpened =
    formPosition === QuoteTermsFormStatus.topOpened || formPosition === QuoteTermsFormStatus.bottomOpened;

  const nonBindingOfferStatus = useMemo(
    () =>
      matchPath(location.pathname, {
        path: ROUTERS.NON_BINDING_OFFER,
      }),
    [location]
  );

  const classes = useStyles();

  useEffect(() => {
    if (Object.getOwnPropertyNames(project).length) {
      setImages(Array.from(project.imageList).map((image: any) => image.url));
    }
  }, [project]);

  return (
    <div className={s.app}>
      <div className={s.app__content}>
        <Header />
        <div className={s.lenderView}>
          <Modal
            open={galleryItemNumber !== -1}
            className={classes.modal}
            onClose={() => setGalleryItemNumber(-1)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={galleryItemNumber !== -1}>
              <div className={s.lenderView__sliderFade}>
                {images[0] ? (
                  <PhotoSlider images={images} />
                ) : project.address.line1 ? (
                  <GoogleMap project={project} />
                ) : (
                  ''
                )}
              </div>
            </Fade>
          </Modal>
          <div className={s.lenderView__wrapper}>
            <ProjectNameHeader project={project} />
            <LoanRequest project={project} />
            <div className={s.lenderView__preview}>
              <ProjectInfoCard project={project} />
              <PhotoSlider images={images} />
            </div>
            {!isQuoteTermsFormOpened && !nonBindingOfferStatus && (
              <QuoteTermsButtons quoteTermsFormStatus={formPosition} formPosition={QuoteTermsFormStatus.topOpened} />
            )}
            {formPosition === QuoteTermsFormStatus.topOpened &&
              (!nonBindingOfferStatus ? (
                <QuoteTermsWrapper />
              ) : (
                <div className={s.test}>
                  {userOffers.map((data, index, array) => (
                    <OfferAccordion
                      disabled={array.length <= 1}
                      key={data}
                      isExpanded={array.length > 1 ? array.length - 1 === index : true}
                      title="Non-binding Offer"
                    >
                      <NonBindingOffer position={formPosition} setUserOffers={setUserOffers} />
                    </OfferAccordion>
                  ))}
                </div>
              ))}
            <div className={s.lenderView__content}>
              <Summary isMobileView={isMobileView} />
              <KeyStats isMobileView={isMobileView} />
              <SourcesAndUses isMobileView={isMobileView} />
              <ProFormaSummary isMobileView={isMobileView} />
              <DesignAndConstruction isMobileView={isMobileView} />
              <SingleTenant isMobileView={isMobileView} />
              <Sponsor isMobileView={isMobileView} />
              <Guarantor isMobileView={isMobileView} />
              <PhotoGallery images={images} setGalleryItemNumber={setGalleryItemNumber} />
              <Attachments isMobileView={isMobileView} />
            </div>
            {!isQuoteTermsFormOpened && !nonBindingOfferStatus && (
              <QuoteTermsButtons quoteTermsFormStatus={formPosition} formPosition={QuoteTermsFormStatus.bottomOpened} />
            )}
            {formPosition === QuoteTermsFormStatus.bottomOpened &&
              (!nonBindingOfferStatus ? (
                <QuoteTermsWrapper />
              ) : (
                <div className={s.test}>
                  {userOffers.map((data, index, array) => (
                    <OfferAccordion
                      disabled={array.length <= 1}
                      key={data}
                      isExpanded={array.length > 1 ? array.length - 1 === index : true}
                      title="Non-binding Offer"
                    >
                      <NonBindingOffer position={formPosition} setUserOffers={setUserOffers} />
                    </OfferAccordion>
                  ))}
                </div>
              ))}
            <UsersPanel isMobileView={isMobileView} />
            <div className={s.lenderView__controller}>
              <div className="link">
                {isAllOpened && <span onClick={() => setisAllOpened(false)}>Close all</span>}
                {!isAllOpened && <span onClick={() => setisAllOpened(true)}>Open all</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LenderView;
