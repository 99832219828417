export const usesTypeOptions: Array<string> = [
  'Property acquisition',
  'CapEx',
  'Pay of existing mortgage',
  'TI/LC',
  'Working capital',
  'Construction',
  'Development soft costs',
  'Contingency',
  'Interest & financing costs',
  'Retained Equity',
  'Cash out',
  'Closing costs',
  'Custom',
];
