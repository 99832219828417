import {FC, useState, useLayoutEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Form, Formik} from 'formik';
import cx from 'classnames';

import {IconButton} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';

import {ROUTERS} from 'core/_consts';
import {lastSegmentOfPath} from 'core/utils';
import {NewProject} from 'core/_types/NewProject';

import StepSlider from 'shared/components/StepSlider';
import Header from 'shared/components/Header';
import {THEMES} from 'shared/helpers';

import ProjectView from 'pages/ProjectView';
import LenderView from 'pages/LenderView';
import {ProjectViewTabs} from 'pages/ProjectView/helpers';

import {First, Second, Third, Fourth, Fifth, Sixth, Seventh, Eighth, Ninth, Tenth, Eleventh, Twelfth} from '../Steps';
import {NewUserOfferSteps} from '../helpers';

import s from './CreateNewOfferWrapper.module.scss';

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    padding: 0,
    color: 'white',
    font: '600 18px Source Sans Pro',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width: 768px)': {
      display: '',
    },
  },
  close: {
    right: 20,
    top: 20,
    zIndex: 1,
    '@media(max-width: 425px)': {
      top: 14,
      right: 14,
    },
  },
  back: {
    left: 20,
    bottom: 20,
    gap: '5px',
  },
  next: {
    right: 20,
    bottom: 20,
    gap: '5px',
  },
});

const stepsTitles = Array(12)
  .fill('Step')
  .map((item, index) => `${item} ${index + 1}`);

type StepKeysType = keyof typeof NewUserOfferSteps;

const UserCreateNewOfferWrapper: FC = () => {
  const [isStartPoint, setIsStartPoint] = useState(false);
  const [step, setStep] = useState<StepKeysType>(NewUserOfferSteps[NewUserOfferSteps['asset-group']] as StepKeysType);

  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const renderStep = () => {
    switch (step) {
      case NewUserOfferSteps[NewUserOfferSteps['asset-group']]:
        return <First goNext={goNext} />;

      case NewUserOfferSteps[NewUserOfferSteps['asset-type']]:
        return <Second goNext={goNext} />;

      case NewUserOfferSteps[NewUserOfferSteps['financing-purpose']]:
        return <Third goNext={goNext} />;

      case NewUserOfferSteps[NewUserOfferSteps.address]:
        return <Fourth />;

      case NewUserOfferSteps[NewUserOfferSteps['property-income']]:
        return <Fifth />;

      case NewUserOfferSteps[NewUserOfferSteps['project-budget']]:
        return <Sixth />;

      case NewUserOfferSteps[NewUserOfferSteps['financing-request']]:
        return <Seventh />;

      case NewUserOfferSteps[NewUserOfferSteps['financing-details']]:
        return <Eighth />;

      case NewUserOfferSteps[NewUserOfferSteps.attachments]:
        return <Ninth />;

      case NewUserOfferSteps[NewUserOfferSteps['sponsor-role']]:
        return <Eleventh goNext={goNext} />;

      case NewUserOfferSteps[NewUserOfferSteps.guarantors]:
        return <Twelfth goNext={goNext} />;

      case NewUserOfferSteps[NewUserOfferSteps['project-summary']]:
        return <Tenth isFinal />;

      default:
        return null;
    }
  };

  const initialValues: NewProject = {
    targetCloseDate: '',
    line1: '',
    city: '',
    state: '',
    zip: '',
    assetType: {
      value: '',
      isPicked: false,
    },
    investmentProperty: {
      value: '',
      isPicked: false,
    },
    tenantsType: {
      value: '',
      isPicked: false,
    },
    tenants: '',
    hotelFlag: '',
    hotelExpirationDate: '',
    financingPurpose: '',
    sponsorRole: '',
    multiFamilyAcquisition: '',
    loanTerm: '',
    amortization: '',
    recourse: '',
    interestRate: '',
    notes: '',
  };

  const data = localStorage.getItem('projectInitialValues');
  const projectInitialValues = data && JSON.parse(data);

  const goNext = () => {
    const currentStep = NewUserOfferSteps[
      NewUserOfferSteps[step as keyof typeof NewUserOfferSteps] + 1
    ] as StepKeysType;

    setStep(currentStep);

    history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
  };

  const nextStep = (values: any) => {
    const currentStep = NewUserOfferSteps[
      NewUserOfferSteps[step as keyof typeof NewUserOfferSteps] + 1
    ] as StepKeysType;
    setStep(currentStep);

    localStorage.setItem('projectInitialValues', JSON.stringify(values));

    history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
  };

  const prevStep = () => {
    if (!isStartPoint) {
      const currentStep = NewUserOfferSteps[
        NewUserOfferSteps[step as keyof typeof NewUserOfferSteps] - 1
      ] as StepKeysType;
      setStep(currentStep);
      history.replace(`${ROUTERS.CREATE_NEW_PROJECT}/${currentStep}`);
    }
  };

  useLayoutEffect(() => {
    const currentStep =
      (lastSegmentOfPath(location.pathname) as StepKeysType) || NewUserOfferSteps[NewUserOfferSteps['asset-group']];
    setStep(currentStep);
    setIsStartPoint(currentStep === NewUserOfferSteps[NewUserOfferSteps['asset-group']]);
  }, [step]);

  if (
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs.underwriting]) ||
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs['capital-sources']]) ||
    location.pathname.includes(ProjectViewTabs[ProjectViewTabs.offers])
  )
    return <ProjectView />;

  if (location.pathname.includes('view')) return <LenderView />;

  return (
    <>
      <Header />
      <Formik
        enableReinitialize
        initialValues={projectInitialValues || initialValues}
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log('Values: ', values)}
      >
        {({values}) => (
          <Form>
            <div className={s.createNewOfferWrapper}>
              <IconButton
                className={cx(classes.button, classes.close)}
                disableRipple
                onClick={() => history.push(ROUTERS.DASHBOARD)}
                size="small"
              >
                <CloseIcon />
              </IconButton>

              <div className={s.createNewOfferWrapper__content}>{renderStep()}</div>
              <div className={s.createNewOfferWrapper__sliderWrapper}>
                {step && (
                  <StepSlider
                    titles={stepsTitles}
                    theme={THEMES.white}
                    steps={Object.values(NewUserOfferSteps)}
                    currentStep={NewUserOfferSteps[step as keyof typeof NewUserOfferSteps]}
                  />
                )}
              </div>

              {!isStartPoint && (
                <IconButton
                  className={cx(classes.button, classes.back, s.createNewOfferWrapper__backBtn)}
                  disableRipple
                  onClick={prevStep}
                  size="small"
                >
                  <ArrowBackIcon />
                  <span>Back</span>
                </IconButton>
              )}
              <IconButton
                className={cx(classes.button, classes.next, s.createNewOfferWrapper__nextBtn)}
                disableRipple
                onClick={() => nextStep(values)}
                size="small"
              >
                <span>Next</span>
                <ArrowForwardIcon />
              </IconButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserCreateNewOfferWrapper;
