import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Core
import {acquisitionStatusOptions} from 'core/utils/selectOptions/acquisitionStatusOptions';

// Components
import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {FinancePurpose, isValue} from 'pages/CreateNewOffer/helpers';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';

import s from './Third.module.scss';

type ThirdProps = {
  goNext: () => void;
};

const Third: FC<ThirdProps> = ({goNext}) => {
  const {values, handleChange, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.financingPurpose);
  const iconTitles = (Object.keys(FinancePurpose) as Array<keyof typeof FinancePurpose>).filter(isValue);

  return (
    <div className={s.third}>
      <div className={s.third__header}>
        <p className={s.third__title}>What is the purpose of the financing?</p>
      </div>
      <div className={s.third__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            key={iconTitle}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('financingPurpose', iconTitle);
              setSelectedCard(iconTitle);

              if (iconTitle !== 'Refinance') {
                goNext();
              }
            }}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>

      {values.financingPurpose === 'Refinance' && (
        <div className={s.third__form}>
          <div className={s.third__inputs}>
            <StepSelect
              placeholder="Select status"
              label="Select Acquisition Status"
              name="status"
              value={values.status}
              theme={THEMES.white}
              options={acquisitionStatusOptions}
              setValue={setFieldValue}
            />
            <div className={s.third__container}>
              <label htmlFor="datePicker" className={s.third__label}>
                Target Closing Date
              </label>
              <input
                id="datePicker"
                type="date"
                name="targetCloseDate"
                value={values.targetCloseDate}
                onChange={handleChange}
                className={s.third__datePicker}
              />
            </div>
          </div>
          <UnsavedChangesPrompt />
        </div>
      )}
    </div>
  );
};

export {Third};
