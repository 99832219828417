import {FC, useState} from 'react';
import {Box, Slider} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BasicButton from 'shared/components/BasicButton';
import {THEMES} from 'shared/helpers';
import CheckboxButton from 'shared/components/CheckboxButton';

import s from './Equity.module.scss';

const useStyles = makeStyles({
  slider: {
    color: THEMES.blue,
  },
  sliderSecondary: {
    color: THEMES.blue,
    marginTop: '3px',
  },
});

const Equity: FC = () => {
  const [years, setYears] = useState<number[]>([0, 50]);
  const [percent, setPercent] = useState<number>(0);

  const classes = useStyles();

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setYears(newValue as number[]);
  };

  const handleChange = (event: Event, newPercent: number | number[]) => {
    setPercent(newPercent as number);
  };

  return (
    <>
      <div className={s.equity}>
        <div className={s.equity__container}>
          <h2 id="parent-modal-title" className={s.equity__title}>
            Equity
          </h2>
          <h3 id="parent-modal-description" className={s.equity__subtitle}>
            Investment Type
          </h3>
          <div className={s.equity__btnGroup}>
            <CheckboxButton text="Preferred Equity" variant="modalFilterStatic" />
            <CheckboxButton text="LP Equity" variant="modalFilterStatic" />
            <CheckboxButton text="Co-GP Equity" variant="modalFilterStatic" />
          </div>
        </div>
        <div className={s.equity__container}>
          <h3 id="parent-modal-description" className={s.equity__subtitle}>
            Investment Horizon
          </h3>
          <div className={s.equity__sliderWrapper}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '65%',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider className={classes.slider} size="small" value={years} onChange={handleRangeChange} />
            </Box>
            <div className={s.equity__sliderContent}>
              <p className={s.equity__boxValue}>{years[0]}</p>
              <span>-</span>
              <p className={s.equity__boxValue}>{years[1]}</p>
              <span>years</span>
            </div>
          </div>
        </div>
        <div className={s.equity__container}>
          <h3 id="parent-modal-description" className={s.equity__subtitle}>
            CLTV
          </h3>
          <div className={s.equity__sliderWrapper}>
            <Box
              sx={{
                width: '65%',
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider
                className={classes.slider}
                defaultValue={0}
                size="small"
                step={1}
                min={0}
                max={100}
                color="primary"
                value={percent}
                onChange={handleChange}
              />
            </Box>
            <div className={s.equity__sliderContent}>
              <p className={s.equity__boxValue}>{percent}</p>
              <span className={s.equity__sliderContent_perc}>%</span>
              <p className={s.equity__sliderContent_price}>$30,000,000</p>
            </div>
          </div>
        </div>
        <div className={s.equity__container}>
          <div className={s.equity__subtitleGroup}>
            <h3 className={s.equity__subtitle}>Third-part Equity</h3>
            <h3 className={s.equity__subtitle}>Sponsor Equity</h3>
          </div>
          <div className={s.equity__sliderWrapper_third}>
            <p className={s.equity__sliderContent_value}>$4,000,000</p>
            <Box
              sx={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 425px)': {
                  paddingLeft: '5px',
                  width: '95%',
                },
              }}
            >
              <Slider
                className={classes.sliderSecondary}
                defaultValue={0}
                size="small"
                step={1}
                min={0}
                max={100}
                color="primary"
              />
            </Box>
            <p className={s.equity__sliderContent_value}>$6,000,000</p>
          </div>
          <div className={s.equity__percentEquity}>
            <div>
              <h3 className={s.equity__percentPartyTitle}>% of Equity</h3>
              <div className={s.equity__boxWrapper}>
                <p className={s.equity__boxValue}>40</p>
                <span>%</span>
              </div>
            </div>
            <div className={s.equity__secondCol}>
              <h3 className={s.equity__percentPartyTitle}>% of Equity</h3>
              <div className={s.equity__boxWrapper}>
                <p className={s.equity__boxValue}>60</p>
                <span>%</span>
              </div>
            </div>
          </div>
          <div className={s.equity__percentEquity}>
            <div>
              <h3 className={s.equity__percentPartyTitle}>% of Capital Stack</h3>
              <div className={s.equity__boxWrapper}>
                <p className={s.equity__boxValue}>10</p>
                <span>%</span>
              </div>
            </div>
            <div className={s.equity__secondCol}>
              <h3 className={s.equity__percentPartyTitle}>% of Capital Stack</h3>
              <div className={s.equity__boxWrapper}>
                <p className={s.equity__boxValue}>15</p>
                <span>%</span>
              </div>
            </div>
          </div>

          <div className={s.equity__btnContainer}>
            <h3 className={s.equity__subtitle}>Preferred Return Type</h3>
            <div className={s.equity__btnGroup}>
              <CheckboxButton text="Current Pay" variant="modalFilterStatic" />
              <CheckboxButton text="Accrued" variant="modalFilterStatic" />
            </div>
          </div>
        </div>
      </div>
      <footer className={s.footer}>
        <BasicButton className="darkBlue">View Results</BasicButton>
        <BasicButton>Reset</BasicButton>
      </footer>
    </>
  );
};

export default Equity;
