export enum DashboardViews {
  Cards,
  Table,
}

export enum ExpectedRepaymentSchedule {
  UNSET = 'Unset',
  SELF_LIQUIDATING = 'Self liquidating',
  FULL_TERM_IO = 'Full term IO',
  PARTIAL_TERM_IO = 'Partial term IO',
  FIFTEEN_YEARS = '15 years',
  TWENTY_YEARS = '20 years',
  TWENTY_FIVE_YEARS = '25 years',
  THIRTY_PLUS_YEARS = '30+ years',
}

export enum ExpectedInterestRate {
  UNSET = 0,
  FIXED = 1,
  FLOATING = 2,
}

export enum ExpectedRecourse {
  UNSET = 0,
  NO_PREFERENCE = 2,
  PREFER_NON_RECOURSE = 3,
  REQUIRE_NON_RECOURSE = 1,
}

export enum ProFormaLowestValue {
  CAP_RATE_VALUATION = 0,
  STABILIZED_CAP_RATE_VALUATION = 1,
}

export enum UsCitizenshipStatus {
  OTHER = 'Other',
  US_CITIZEN = 'US Citizen',
  IMMIGRANT_LEGAL = 'Immigrant legal',
  NON_IMMIGRANT_VISA = 'Non Immigrant visa',
}

export enum DisclaimerItem {
  UNSET = 2,
  YES = 0,
  NO = 1,
}
