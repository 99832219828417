import {FC} from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import CheckIcon from '@mui/icons-material/Check';
import {THEMES} from 'shared/helpers';

import s from './StepSlider.module.scss';

const StyledCheckIcon = withStyles({
  root: {
    width: '18px',
    height: '18px',
    '@media(max-width: 425px)': {
      width: '15px',
      height: '15px',
    },
    '@media(max-width: 375px)': {
      display: 'none',
    },
  },
})(CheckIcon);

const isCellPassed = (currentStep: number, currentMapIndex: number) => currentStep > currentMapIndex;
const isCellEqualToCurrentStep = (currentStep: number, currentMapIndex: number) => currentStep === currentMapIndex;
const isLineNeededToBeRendered = (currentMapIndex: number, sumOfAllSteps: number) =>
  currentMapIndex !== sumOfAllSteps - 1;

type StepSliderProps = {
  steps: Array<string | number>;
  currentStep: number;
  theme: typeof THEMES[keyof typeof THEMES];
  titles: Array<string>;
};

const StepSlider: FC<StepSliderProps> = ({steps, currentStep, theme, titles}) => {
  const stepsArray = steps.filter((item) => typeof item !== 'number');
  const iconColor = theme === THEMES.white ? 'black' : 'white';

  return (
    <div className={s.stepSlider}>
      {stepsArray.map((item, index) => (
        <div
          key={item}
          className={cx(s.cell, {
            [s.cell_passed]: isCellPassed(currentStep, index),
            [s.cell_withoutLine]: !isLineNeededToBeRendered(index, stepsArray.length),
          })}
        >
          <div className={s.cell__container}>
            <div
              className={cx(s.cell__thumb, s[theme], {
                [s.cell__thumb_current]: isCellEqualToCurrentStep(currentStep, index),
              })}
            >
              {isCellPassed(currentStep, index) && <StyledCheckIcon sx={{color: iconColor}} />}
            </div>
            {isLineNeededToBeRendered(index, stepsArray.length) && <div className={cx(s.cell__line, s[theme])} />}
            {isCellEqualToCurrentStep(currentStep, index) && (
              <p className={cx(s.cell__title, s[theme])}>{titles[currentStep]}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepSlider;
