import {FC} from 'react';
import {useDispatch} from 'react-redux';

import {ava, Org, Share} from 'core/service/ava';

// Store
import {removeShare} from 'store/shares-service/asyncActions';

// Shared
import BasicButton from 'shared/components/BasicButton';

import s from './RemoveShare.module.scss';

type RemoveShareProps = {
  handleClose: () => void;
  share?: Share;
  provider?: Org;
};

const RemoveShare: FC<RemoveShareProps> = ({handleClose, share, provider}) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removeShare({ava, shareSym: share?.sym}));
    handleClose();
  };

  return (
    <div className={s.removeShare}>
      <h2>Are you sure you want to remove {provider?.name} as a capital source for this deal?</h2>
      <footer className={s.footer}>
        <BasicButton className="blue" type="submit" width="150px" onClick={handleDelete}>
          Yes
        </BasicButton>
        <BasicButton className="white" onClick={handleClose} width="120px">
          No
        </BasicButton>
      </footer>
    </div>
  );
};

export default RemoveShare;
