import {FC} from 'react';

// Types
import {FormikElement} from 'core/enums/formik-controller.enum';

// Components
import NumericInput from 'shared/components/NumericInput';
import SelectInput from 'shared/components/SelectInput';
import RadioInput from 'shared/components/RadioInput';
import FormikField from './FormikField';
import TextInput from '../TextInput';

type ControllerProps = {
  control: FormikElement;
  [rest: string]: any;
};

const Controller: FC<ControllerProps> = ({control, ...rest}) => {
  const switchInputType = () => {
    switch (control) {
      case FormikElement.TEXT_INPUT:
        return <TextInput width={rest.width} {...rest} />;
      case FormikElement.NUMERIC_INPUT:
        return <NumericInput width={rest.width} {...rest} />;
      case FormikElement.RADIO_INPUT:
        return <RadioInput values={rest.values} {...rest} />;
      case FormikElement.SELECT:
        return <SelectInput options={rest.options} {...rest} />;
      default:
        return <></>;
    }
  };

  return <FormikField name={rest.name}>{switchInputType()}</FormikField>;
};

export default Controller;
