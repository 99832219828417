import {ReactComponent as Warn} from 'assets/icons/triangle.svg';
import {ReactComponent as Chrome} from 'assets/icons/chrome.svg';
import {ReactComponent as Firefox} from 'assets/icons/firefox.svg';
import {ReactComponent as Edge} from 'assets/icons/microsoft.svg';
import {ReactComponent as Safari} from 'assets/icons/safari.svg';

import s from './Browser.module.scss';

const Browser = () => (
  <>
    <div className={s.browser}>
      <div className={s.browser__container}>
        <div>
          <h2 className={s.browser__title}>
            {' '}
            <Warn /> You’re Using an Unsupported Browser
          </h2>
          <p className={s.browser__text}>
            Sorry, it appears you are using an old browser or a browser which StackSource does not support. Use the
            latest version of one of the following browsers:
          </p>
          <ul className={s.browser__list}>
            <li className={s.browser__item}>
              <Chrome />
              <a className={s.browser__link} href="https://www.google.com/intl/us/chrome/">
                Google Chrome
              </a>
            </li>
            <li className={s.browser__item}>
              <Firefox />
              <a className={s.browser__link} href="https://www.mozilla.org/en-US/firefox/new/">
                Mozzila Firefox
              </a>
            </li>
            <li className={s.browser__item}>
              <Edge />
              <a className={s.browser__link} href="https://www.microsoft.com/en-us/edge">
                Microsoft Edge
              </a>
            </li>
            <li className={s.browser__item}>
              <Safari />
              <a className={s.browser__link} href="https://support.apple.com/en-us/HT204416">
                Apple Safari
              </a>
            </li>
          </ul>
          <p className={s.browser__text}>
            If you’re having trouble accessing the platform, contact us at{' '}
            <a className={s.browser__linkMail} href="https://www.stacksource.com/contact">
              {' '}
              hello@stacksource.com
            </a>
          </p>
        </div>
      </div>
    </div>
  </>
);
export default Browser;
