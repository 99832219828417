import {FC, useEffect, useState} from 'react';
import {Formik} from 'formik';

import {Source} from 'core/_types/selectOptionsTypes/SourcesType';

import SourcesForm from './SourcesForm';

type SourcesProps = {
  sources: Array<Source>;
};

export const Sources: FC<SourcesProps> = ({sources}) => {
  const [totalSources, setTotalSources] = useState('');

  useEffect(() => {
    if (sources.length) {
      const dollarUSLocale = Intl.NumberFormat('en-US');
      setTotalSources(dollarUSLocale.format(sources.reduce((acc, source) => acc + +source.principal.split(' ')[1], 0)));
    }
  }, [sources]);

  const initialValues: SourcesProps = {
    sources,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <SourcesForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
          totalSources={totalSources}
        />
      )}
    </Formik>
  );
};
