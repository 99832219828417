import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {yesNoOptions, QuoteTermsSteps, PrepayPenaltySubsteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {setAnyPenalty} from 'store/quote-terms-service/quoteTermsSlice';
import {selectAnyPenalty} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.no
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.initialDeposit],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.penaltyType],
      };

const AnyPenalty = () => {
  const defaultValue = useSelector(selectAnyPenalty);
  const [isAnyPenalty, setIsAnyPenalty] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [isAnyPenalty]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Is there a prepay penalty?</p>
      <RadioGroup
        isAnyValueSelected={isAnyPenalty}
        setIsAnyValueSelected={setIsAnyPenalty}
        radioButtonTitles={Object.values(yesNoOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!isAnyPenalty.length}
        slideData={{[PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty]]: isAnyPenalty}}
        action={setAnyPenalty}
        nextSlide={handleSelectedValue(isAnyPenalty[0])}
      />
    </div>
  );
};

export default AnyPenalty;
