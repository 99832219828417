import {FC, useState, useMemo} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import {Formik, Form} from 'formik';
import {quoteTermsStorageType} from 'core/_types/quoteTermsTypes';
import {rateDetailsSchema} from 'core/helpers/yupSchemas';
import NextButton from 'shared/components/NextButton';
import {setRateDetails} from 'store/quote-terms-service/quoteTermsSlice';
import {rateDetailsOptions, InterestRateSubsteps, QuoteTermsSteps, storageKey, yesNoOptions} from 'core/_consts';
import {vaultService} from 'core/service/storage';
import {useSelector} from 'react-redux';
import {selectRateDetails} from 'store/quote-terms-service/quoteTermsSelector';
import cx from 'classnames';
import FixedSpread from './FixedSpread';
import Floating from './Floating';
import FixedPercent from './FixedPercent';

const rateDetailsOptionsValues: Array<string> = Object.values(rateDetailsOptions);

const RateDetails: FC = () => {
  const defaultValue = useSelector(selectRateDetails);
  const [isAnyDetailSelected, setIsAnyDetailSelected] = useState<Array<string>>(
    defaultValue.selectedRateDetails || [
      rateDetailsOptions[Object.keys(defaultValue)[0] as keyof typeof rateDetailsOptions],
    ]
  );

  const isRateResetsAvailable = useMemo(() => {
    const rateResets = vaultService.getItem<quoteTermsStorageType>(storageKey.quoteTermsData)?.rateResets;
    return rateResets?.[0] === yesNoOptions.yes;
  }, []);

  const selectedSection = useMemo(
    () => Object.keys(rateDetailsOptions)[rateDetailsOptionsValues.indexOf(isAnyDetailSelected[0])],
    [isAnyDetailSelected]
  );

  const getSlideData = (values: any) => ({
    [InterestRateSubsteps[InterestRateSubsteps.rateDetails]]: {
      [selectedSection]: values[selectedSection as keyof typeof values],
    },
  });

  const renderSection = (currentSection: string) => {
    switch (currentSection) {
      case rateDetailsOptions.fixedPercent:
        return <FixedPercent />;
      case rateDetailsOptions.fixedSpread:
        return <FixedSpread />;
      case rateDetailsOptions.floating:
        return <Floating />;
      default:
        return null;
    }
  };

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading mb35">
        <p className="FormSlide__title">
          Enter the interest rate details
          <span className={cx('FormSlide__title_highlighted', {'d-none': !isRateResetsAvailable})}>
            &nbsp;for the first 5-year term
          </span>
          .
        </p>
        <p className="FormSlide__subTitle">You’ll be prompted to provide additional information.</p>
      </div>
      <div className="FormSlide__content">
        <RadioGroup
          isAnyValueSelected={isAnyDetailSelected}
          setIsAnyValueSelected={setIsAnyDetailSelected}
          radioButtonTitles={rateDetailsOptionsValues}
        />
        <Formik
          initialValues={defaultValue}
          validateOnMount
          enableReinitialize
          validationSchema={rateDetailsSchema}
          // TODO: add handle logic on submit
          // eslint-disable-next-line no-console
          onSubmit={(values) => console.log(values)}
        >
          {({errors, values}) => (
            <Form className="FormSlide__form">
              {!!isAnyDetailSelected.length && <>{renderSection(isAnyDetailSelected[0])}</>}
              <NextButton
                isDisabled={!isAnyDetailSelected.length || !!errors[selectedSection as keyof typeof errors]}
                slideData={getSlideData(values)}
                action={setRateDetails}
                nextSlide={{
                  step: QuoteTermsSteps[QuoteTermsSteps.interestRate],
                  substep: InterestRateSubsteps[InterestRateSubsteps.rateCalculation],
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RateDetails;
