import {LoanAmountSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import DollarAmount from './DollarAmount';
import SizingConstraints from './SizingConstraints';

type LoanAmountSubstepsKeys = keyof typeof LoanAmountSubsteps;

const LoanAmount: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<LoanAmountSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as LoanAmountSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case LoanAmountSubsteps[LoanAmountSubsteps.loanSizingConstraints]:
      return <SizingConstraints />;

    case LoanAmountSubsteps[LoanAmountSubsteps.dollarLoanAmount]:
      return <DollarAmount />;

    default:
      return null;
  }
};

export default LoanAmount;
