import {FC, useEffect, useRef, useState} from 'react';
import {Form, FormikHandlers, FormikHelpers, FormikValues} from 'formik';
import {Box, Grid, SelectChangeEvent} from '@mui/material';

import {AssetType} from '@burnaby/ava';

// Shared
import TextInput from 'shared/components/TextInput';
import {StepSelect} from 'shared/components/StepSelect';
import {THEMES} from 'shared/helpers';
import TextArea from 'shared/components/TextArea';
import QuestionMarkIcon from 'shared/components/QuestionMarkIcon';
import NumericInput from 'shared/components/NumericInput';

// Core
import {MaxValidationLimits} from 'core/_consts';
import {questionaryOptions} from 'core/utils/selectOptions/questionaryOptions';
import {BuildingTypes} from 'core/enums/buildingTypes';
import {AssetInfoType} from 'core/_types/UnderwritingTypes';
import {statusMessage} from 'core/enums/statusMessage';
import {assetTypeOptions, propertyTitleOptions} from 'core/utils/selectOptions/propertyTitleOptions';
import {PropertyTitle} from 'core/enums/propertyTitle';
import useGoogleAutocomplete from 'core/utils/hooks/useGoogleAutocomplete';
import useStatusMessage from 'core/utils/hooks/useStatusMessage';

// Store
import {useDispatch, useSelector} from 'react-redux';
import {selectorGetProject, selectorGetUpdate} from 'store/project-service/selector';
import {updateAssetInfo} from 'store/project-service/asyncActions';
import {setUpdatedAssetInfo} from 'store/project-service/projectSlice';

// Styles
import s from './AssetInfo.module.scss';

type AssetInfoFormProps = {
  values: AssetInfoType;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: Pick<FormikHelpers<FormikValues>, 'setFieldValue'>['setFieldValue'];
  initialValues: AssetInfoType;
  assetType: string;
};

const AssetInfoForm: FC<AssetInfoFormProps> = ({values, handleChange, setFieldValue, initialValues, assetType}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);
  const {assetInfo} = useSelector(selectorGetUpdate);
  const message = useStatusMessage(assetInfo);

  const [editingType, setEditingType] = useState(false);
  const getIconSource = (data: string) =>
    BuildingTypes[data.split(' ').join('') as keyof typeof BuildingTypes] || BuildingTypes.Other;

  const mainAddressField = useRef<HTMLInputElement>(null);
  const {line1} = values.address;
  useGoogleAutocomplete({mainAddressField, setFieldValue, line1});

  useEffect(() => {
    if (values !== initialValues) {
      dispatch(updateAssetInfo({sym: project.sym, fields: values, dispatch}));
      dispatch(setUpdatedAssetInfo(statusMessage.saving));
    }
  }, [values]);

  useEffect(
    () => () => {
      dispatch(setUpdatedAssetInfo(''));
    },
    []
  );

  return (
    <Form>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={6}>
          {editingType ? (
            <div className={s['Asset__edit-group']}>
              <div className={s.Asset__field}>
                <StepSelect
                  name="assetType"
                  options={assetTypeOptions}
                  label="Asset Type"
                  theme={THEMES.blue}
                  width="100%"
                  value={values.assetType.label}
                  handleChange={(e?: SelectChangeEvent<any>) =>
                    e &&
                    setFieldValue('assetType', {
                      // value: AssetType.fromString(e.target.value.toUpperCase().replace(' ', '_')),
                      label: e.target.value,
                    })
                  }
                />
              </div>
              <p className={`${s.Asset__link} ${s['Asset__link--save']}`} onClick={() => setEditingType(!editingType)}>
                save
              </p>
            </div>
          ) : (
            <>
              <p className={s.Asset__label}>Asset Type</p>
              <div className={s.Asset__type}>
                <div className={s.Asset__icon}>
                  <img src={`/images/infoCard/${getIconSource(values.assetType.label)}.svg`} alt="icon" />
                  <p>{values.assetType.label}</p>
                </div>
                <p className={s.Asset__link} onClick={() => setEditingType(!editingType)}>
                  change
                </p>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <StepSelect
            name="propertyTitle"
            options={propertyTitleOptions}
            theme={THEMES.blue}
            label="Property Title*"
            value={values.propertyTitle.label}
            handleChange={(e?: SelectChangeEvent<any>) =>
              e &&
              setFieldValue('propertyTitle', {
                value: PropertyTitle[e.target.value.toUpperCase().replace(' ', '_')],
                label: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="numberOfUnits"
            value={values.numberOfUnits}
            onValueChange={handleChange}
            theme={THEMES.grey}
            fullWidth
            label="Number of units*"
            preventionLimit={1000}
            textAlign="left"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="occupancy"
            value={values.occupancy}
            onValueChange={handleChange}
            theme={THEMES.grey}
            fullWidth
            label="Number of occupied units"
            preventionLimit={1000}
            textAlign="left"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            autoCompleteRef={mainAddressField}
            name="address.line1"
            value={values.address.line1}
            onValueChange={handleChange}
            fullWidth
            label="Address"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.line2"
            value={values.address.line2}
            onValueChange={handleChange}
            fullWidth
            label="Address 2 (Optional)"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="address.city"
            value={values.address.city}
            onValueChange={handleChange}
            fullWidth
            label="City"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="address.state"
            value={values.address.state}
            onValueChange={handleChange}
            fullWidth
            label="State"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput name="address.zip" value={values.address.zip} onValueChange={handleChange} fullWidth label="ZIP" />
        </Grid>
      </Grid>
      <p className={s.Asset__groupTitle}>Asset Size</p>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item display="flex" alignItems="flex-end" xs={3}>
          <NumericInput
            name="totalSize"
            value={values.totalSize}
            width="100px"
            label="Building Size"
            onValueChange={handleChange}
            theme={THEMES.grey}
            thousandSeparator
            preventionLimit={MaxValidationLimits.highestLimit}
          />
          <div className={s.Asset__unit}>sqrt</div>
        </Grid>
        <Grid item display="flex" alignItems="flex-end" xs={3}>
          <NumericInput
            name="footprintSize"
            value={values.footprintSize}
            onValueChange={handleChange}
            theme={THEMES.grey}
            width="100px"
            label="Footprint Size"
            thousandSeparator
            preventionLimit={MaxValidationLimits.highestLimit}
          />
          <div className={s.Asset__unit}>sqrt</div>
        </Grid>
        <Grid item display="flex" alignItems="flex-end" xs={3}>
          <NumericInput
            name="lotSize"
            value={values.lotSize}
            onValueChange={handleChange}
            theme={THEMES.grey}
            width="100px"
            label="Lot Size"
            thousandSeparator
            preventionLimit={MaxValidationLimits.highestLimit}
          />
          <div className={s.Asset__unit}>acres</div>
        </Grid>
      </Grid>
      <p className={s.Asset__groupTitle}>Design & Construction</p>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={6}>
          <TextInput
            name="architect"
            value={values.architect}
            onValueChange={handleChange}
            fullWidth
            label="Architect"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="structuralEngineer"
            value={values.structuralEngineer}
            onValueChange={handleChange}
            fullWidth
            label="Structural Engineer"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="constructionManager"
            value={values.constructionManager}
            onValueChange={handleChange}
            fullWidth
            label="Construction Manager"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="generalContractor"
            value={values.generalContractor}
            onValueChange={handleChange}
            fullWidth
            label="General Contractor"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="leasingRepresentative"
            value={values.leasingRepresentative}
            onValueChange={handleChange}
            fullWidth
            label="Leasing Representative"
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            name="propertyManager"
            value={values.propertyManager}
            onValueChange={handleChange}
            fullWidth
            label="Property Manager"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="completedYear"
            value={values.completedYear}
            onValueChange={handleChange}
            theme={THEMES.grey}
            label="Year Built"
            textAlign="left"
            preventionLimit={MaxValidationLimits.highestLimit}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="renovatedYear"
            value={values.renovatedYear}
            onValueChange={handleChange}
            theme={THEMES.grey}
            label="Year Renovated"
            textAlign="left"
            preventionLimit={MaxValidationLimits.highestLimit}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="floorsAboveGround"
            value={values.floorsAboveGround}
            onValueChange={handleChange}
            theme={THEMES.grey}
            fullWidth
            label="Floors Above Ground"
            preventionLimit={100}
            textAlign="left"
          />
        </Grid>
        <Grid item xs={6}>
          <NumericInput
            name="floorsBelowGround"
            value={values.floorsBelowGround}
            onValueChange={handleChange}
            theme={THEMES.grey}
            fullWidth
            label="Floors Below Ground"
            preventionLimit={100}
            textAlign="left"
          />
        </Grid>
        <Grid item xs={12}>
          <p className={s.Asset__label}>Total Floors</p>
          <p>{+values.floorsAboveGround + +values.floorsBelowGround}</p>
        </Grid>
        <Grid item xs={12}>
          <div className={s.Asset__label} style={{marginBottom: '5px'}}>
            Design Information <QuestionMarkIcon />
          </div>
          <TextArea
            name="designInfo"
            value={values.designInfo}
            width="100%"
            height="126px"
            onValueChange={handleChange}
            theme={THEMES.blue}
            resize="vertical"
          />
        </Grid>
      </Grid>
      <p className={s.Asset__groupTitle}>Project Questionnaire</p>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Was a major crime committed on the property in the last 5 years?</p>
          </Box>
          <StepSelect
            name="majorCrimes"
            value={values.majorCrimes === 'Unset' ? '' : values.majorCrimes}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Is there a persistent pattern of lesser crimes committed on the property?</p>
          </Box>
          <StepSelect
            name="lesserCrimes"
            value={values.lesserCrimes === 'Unset' ? '' : values.lesserCrimes}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Is the property delinquent on any taxes?</p>
          </Box>
          <StepSelect
            name="taxesDelinquent"
            value={values.taxesDelinquent === 'Unset' ? '' : values.taxesDelinquent}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Is the property delinquent on existing mortgage payments?</p>
          </Box>
          <StepSelect
            name="paymentsDelinquent"
            value={values.paymentsDelinquent === 'Unset' ? '' : values.paymentsDelinquent}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Does the property have any environmental hazard issues?</p>
          </Box>
          <StepSelect
            name="environmentalHazards"
            value={values.environmentalHazards === 'Unset' ? '' : values.environmentalHazards}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Is the property located in a flood zone?</p>
          </Box>
          <StepSelect
            name="floodZone"
            value={values.floodZone === 'Unset' ? '' : values.floodZone}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" alignItems="flex-end" minHeight="36px">
            <p className={s.Asset__label}>Is the property located in a high risk seismic zone?</p>
          </Box>
          <StepSelect
            name="seismicZone"
            value={values.seismicZone === 'Unset' ? '' : values.seismicZone}
            setValue={setFieldValue}
            options={questionaryOptions}
            theme={THEMES.blue}
          />
        </Grid>
      </Grid>
      <p className={s.Asset__groupTitle}>Misc.</p>
      <Grid container spacing={2.5} rowSpacing={3}>
        <Grid item xs={12}>
          <TextArea
            width="100%"
            height="126px"
            name="assetNotes"
            value={values.assetNotes}
            onValueChange={handleChange}
            label="Asset Notes"
            theme={THEMES.blue}
            resize="vertical"
          />
        </Grid>
        {!!message.length && (
          <Grid item xs={12} sx={{fontStyle: 'italic', color: '#757575'}}>
            <p>{message}</p>
          </Grid>
        )}
      </Grid>
    </Form>
  );
};

export default AssetInfoForm;
