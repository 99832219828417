import {Dispatch, FC, SetStateAction} from 'react';
import {Formik} from 'formik';

// Core
import {formattedAddress} from 'core/helpers/formatingFunctions';
import {GuarantorsType} from 'core/_types/UnderwritingTypes';

import GuarantorsForm from './GuarantorsForm';

type GuarantorsProps = {
  setOpen: (open: boolean) => void;
  guarantors?: GuarantorsType[];
  setGuarantors?: Dispatch<SetStateAction<GuarantorsType[]>>;
  completeStatus?: string;
  guarantorEdit?: number;
};

const Guarantors: FC<GuarantorsProps> = ({setOpen, guarantors, setGuarantors, completeStatus, guarantorEdit}) => {
  const initialValues: GuarantorsType =
    guarantorEdit !== undefined && guarantors && guarantorEdit >= 0
      ? {
          ...guarantors[guarantorEdit],
          address: formattedAddress(guarantors[guarantorEdit].address),
          creditScore: guarantors[guarantorEdit].creditScore,
        }
      : {
          name: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            zip: '',
          },
          netWorth: '',
          liquidity: '',
          creditScore: '',
        };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {
        // eslint-disable-next-line no-console
        console.log('a');
      }}
    >
      {(props) => (
        <GuarantorsForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          setOpen={setOpen}
          guarantors={guarantors}
          setGuarantors={setGuarantors}
          completeStatus={completeStatus}
          guarantorEdit={guarantorEdit}
        />
      )}
    </Formik>
  );
};

export default Guarantors;
