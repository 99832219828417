import {RepaymentSchedule} from '../../_types/selectOptionsTypes/AmortizationType';

export const amortizationOptions: Array<RepaymentSchedule> = [
  {value: 'UNSET', label: 'Unset'},
  {value: 'SELF_LIQUIDATING', label: 'Self liquidating'},
  {value: 'FULL_TERM_IO', label: 'Full term io'},
  {value: 'PARTIAL_TERM_IO', label: 'Partial term io'},
  {value: 'FIFTEEN_YEARS', label: '15 years'},
  {value: 'TWENTY_YEARS', label: '20 years'},
  {value: 'TWENTY_FIVE_YEARS', label: '25 years'},
  {value: 'THIRTY_PLUS_YEARS', label: '30+ years'},
];
