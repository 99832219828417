import {FC} from 'react';
import AccordionWrapper from 'shared/components/Accordion';
import {IsMobileViewProps} from '../helpers';
import styles from './SingleTenant.module.scss';

const SingleTenant: FC<IsMobileViewProps> = ({isMobileView}) => {
  const content = (
    <div className="LenderView__section">
      {!isMobileView && <p className="LenderView__infoContentTitle">Single Tenant</p>}
      <div className={styles.Tenant}>
        <div className={styles.Tenant__profile}>
          {/* TODO: replace mocked data */}
          <img
            className={styles.Tenant__photo}
            src="https://static.stacksource.com/user-uploads/4e/AO/bK/0p/Hml/kLBq8EGMoHDn9PeU36yDSWd6mzWnaBMhgk1-pSm0Z-J/0meJY2t05V1wssTA_120x120.jpeg"
            alt="Tenant avatar"
          />
          {/* TODO: replace mocked data */}
          <div className={styles.Tenant__info}>
            <div className="fw600">Corporate Business</div>
            <div>Business Type</div>
          </div>
        </div>
        {/* TODO: replace mocked data */}
        <div className={styles.Tenant__summary}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut debitis magni at tenetur fuga soluta, non id
          dicta error fugiat ea quam ab vero expedita incidunt dignissimos repellat consectetur? Eligendi?
        </div>
      </div>
    </div>
  );

  // TODO: add conditional section rendering when data will be available
  if (isMobileView) return <AccordionWrapper title="Single Tenant">{content}</AccordionWrapper>;

  return content;
};

export default SingleTenant;
