import {FC, useState} from 'react';
import {QuoteTermsSteps, lockboxTypeOptions, CashManagementSubsteps} from 'core/_consts';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {setSelectLockboxType} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectLockboxType} from 'store/quote-terms-service/quoteTermsSelector';

const SelectLockboxType: FC = () => {
  const defaultValue = useSelector(selectLockboxType);
  const [selectedLockboxTypes, setSelectedLockboxTypes] = useState<Array<string>>(defaultValue);

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Add the lockbox type.</p>
        <p className="FormSlide__subTitle mb25">Select all that apply.</p>
      </div>
      <RadioGroup
        maxWidth="420px"
        additionalClassName="lockboxType"
        isAnyValueSelected={selectedLockboxTypes}
        setIsAnyValueSelected={setSelectedLockboxTypes}
        radioButtonTitles={Object.values(lockboxTypeOptions)}
        buttonWidth="200px"
        multiSelect
      />
      <NextButton
        isDisabled={!selectedLockboxTypes.length}
        slideData={{[CashManagementSubsteps[CashManagementSubsteps.selectLockboxType]]: selectedLockboxTypes}}
        action={setSelectLockboxType}
        nextSlide={{
          step: QuoteTermsSteps[QuoteTermsSteps.borrowerStipulations],
        }}
      />
    </div>
  );
};

export default SelectLockboxType;
