import {FC, useMemo} from 'react';
import {Divider} from '@mui/material';

// Core
import {ExpectedRepaymentSchedule, ExpectedInterestRate, ExpectedRecourse} from 'core/_types/enum';
import {BuildingTypes} from 'core/enums/buildingTypes';

// Components
import {ReactComponent as ClosingDateIcon} from 'shared/media/ClosingDate.svg';

import s from './ProjectInfoCard.module.scss';

const defaultDetails = {
  loanTerm: 'Loan Term: Any',
  interestRate: 'Interest Rate: Any',
  amortization: 'Amortization : Any',
  recourse: 'Recourse: Any',
};

type ProjectInfoCardProps = {
  project: any;
};

const ProjectInfoCard: FC<ProjectInfoCardProps> = ({project}) => {
  const getIconSource = (data: string) =>
    BuildingTypes[data.split(' ').join('') as keyof typeof BuildingTypes] || BuildingTypes.Other;

  const labels = useMemo(() => [project?.formatAssetType(), project?.formatFinancingPurpose()], [project]);

  return (
    <div className={s.projectInfoCard}>
      <div className={s.labels}>
        {labels.map((label) => (
          <div className={s.labels__item} key={label}>
            <img src={`/images/infoCard/${getIconSource(label)}.svg`} alt="icon" />
            <p className={s.labels__text}>{label}</p>
          </div>
        ))}
      </div>
      <Divider />
      {!project.targetCloseDate?.isEmpty() && (
        <div className={s.closingDate}>
          <ClosingDateIcon className={s.closingDate__icon} />
          <p>
            <span className={s.closingDate__date}>
              {project.targetCloseDate?.formatHumanAbbr()}
              &nbsp;
            </span>
            target closing date
          </p>
        </div>
      )}
      <ul className={s.list}>
        <li className={s.list__item}>
          <span className={s.list__text}>
            {project.offerExpectations?.loanTermRange.format()
              ? `${project.offerExpectations.loanTermRange.format()} investment term`
              : defaultDetails.loanTerm}
          </span>
        </li>
        <li className={s.list__item}>
          <span className={s.list__text}>
            {project.offerExpectations?.interestRate === ExpectedInterestRate.UNSET
              ? defaultDetails.interestRate
              : `${project.offerExpectations?.formatInterestRate()} interest rate`}
          </span>
        </li>
        <li className={s.list__item}>
          <span className={s.list__text}>
            {project.offerExpectations?.repaymentSchedule === ExpectedRepaymentSchedule.UNSET
              ? defaultDetails.amortization
              : `${project.offerExpectations?.formatRepaymentSchedule()} amortization`}
          </span>
        </li>
        <li className={s.list__item}>
          <span className={s.list__text}>
            {project.offerExpectations?.recourse === ExpectedRecourse.UNSET
              ? defaultDetails.recourse
              : project.offerExpectations?.formatRecourse()}
          </span>
        </li>
      </ul>
      <p
        className={s.projectInfoCard__additionalInfo}
        dangerouslySetInnerHTML={{__html: project.offerExpectations?.notes.formatAsHtml()}}
      />
    </div>
  );
};

export default ProjectInfoCard;
