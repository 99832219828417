import {ChangeEvent, FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControlLabel, FormControl, RadioGroup, Radio} from '@mui/material';

// Core
import {UserType} from 'core/_types/base';

// Shared
import TextInput from 'shared/components/TextInput';
import BasicButton from 'shared/components/BasicButton';

// Store
import {inviteSponsorUser} from 'store/project-service/asyncActions';
import {selectorGetProject} from 'store/project-service/selector';

// Styles
import s from './NewUser.module.scss';

type NewUserProps = {
  handleClose: () => void;
  setSponsorsEmails?: (sponsors: UserType[]) => void;
  sponsors?: UserType[];
};

const NewUser: FC<NewUserProps> = ({handleClose, setSponsorsEmails, sponsors}) => {
  const dispatch = useDispatch();

  const project = useSelector(selectorGetProject);

  const [userEmail, setUserEmail] = useState('');
  const [value, setValue] = useState(0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(+e.target.value);
  };

  const inviteUser = (): void => {
    dispatch(inviteSponsorUser({sym: project.sym, email: userEmail}));
    handleClose();
    if (sponsors && setSponsorsEmails) {
      const newSponsors = sponsors.map((item: any) => item.email);
      newSponsors.push(userEmail);
      setSponsorsEmails(newSponsors);
    }
  };

  const labels = ['Sponsor Email', 'Sales Agent Email', 'Search by Name or Email'];

  return (
    <div className={s.newUser}>
      <FormControl>
        <RadioGroup
          className={s.newUser__radioGroup}
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value={0} control={<Radio />} label={<p className={s.newUser__radioLabel}>Sponsor</p>} />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={<p className={s.newUser__radioLabel}>Sales Agent</p>}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={<p className={s.newUser__radioLabel}>Capital Advisor</p>}
          />
        </RadioGroup>
      </FormControl>
      <TextInput
        label={labels[value]}
        name="email"
        type="text"
        value={userEmail}
        onValueChange={(e) => setUserEmail(e.target.value)}
        width="380px"
      />
      <footer className={s.footer}>
        <BasicButton className="blue" type="submit" width="150px" onClick={inviteUser}>
          Save
        </BasicButton>
        <BasicButton className="white" onClick={handleClose} width="120px">
          Cancel
        </BasicButton>
      </footer>
    </div>
  );
};

export default NewUser;
