import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Form, Formik} from 'formik';

// Core
import {MaxValidationLimits} from 'core/_consts';
import {validateNumericInput} from 'core/utils';

// Store
import {selectDollarLoanAmount} from 'store/quote-terms-service/quoteTermsSelector';

// Shared
import {THEMES} from 'shared/helpers';
import NumericInput from 'shared/components/NumericInput';
import {StepSelect} from 'shared/components/StepSelect';
import {UnsavedChangesPrompt} from 'shared/components/UnsavedChangesPrompt';

import s from './Sixth.module.scss';

const selectOptionsMock = ['Property Acquisition', 'CapEx', 'Status 3'];

const Sixth = () => {
  const defaultValue = useSelector(selectDollarLoanAmount);
  const [dollarAmountValue, setDollarAmountValue] = useState(Number(defaultValue) || '');

  return (
    <div className={s.sixth}>
      <div className={s.sixth__header}>
        <p className={s.sixth__title}>Project Budget</p>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          amount: '',
          uses: '',
        }}
        onSubmit={(values) => {
          // eslint-disable-next-line no-alert
          alert(JSON.stringify(values));
        }}
      >
        {({values, handleChange, setFieldValue}) => (
          <Form>
            <div className={s.sixth__inputs}>
              <StepSelect
                placeholder="Select uses"
                label="Uses*"
                name="uses"
                value={values.uses}
                theme={THEMES.white}
                options={selectOptionsMock}
                handleChange={(values) => handleChange(values)}
              />
              <div>
                <label htmlFor="label" className={s.sixth__label}>
                  Amount*
                </label>
                <NumericInput
                  theme={THEMES.white}
                  color={THEMES.white}
                  name="amount"
                  value={values.amount}
                  defaultValue={dollarAmountValue}
                  onValueChange={(values) => {
                    setDollarAmountValue(Number(values.value));
                    setFieldValue('amount', values.value);
                  }}
                  thousandSeparator
                  isValid={validateNumericInput(Number(dollarAmountValue), MaxValidationLimits.highestLimit)}
                  width="300px"
                  preventionLimit={MaxValidationLimits.highestLimit}
                  border="1px solid white"
                  placeholder="$"
                  textAlign="left"
                  background="none"
                />
              </div>
            </div>
            <UnsavedChangesPrompt />
          </Form>
        )}
      </Formik>
      <div className={s.sixth__totalWrapper}>
        <p className={s.sixth__total}>Total: $0</p>
        <div className={s.sixth__add}>
          <span className={s.sixth__addIcon}>+</span>
          <p className={s.sixth__addItem}>Add an item</p>
        </div>
      </div>
    </div>
  );
};

export {Sixth};
