import {FC, useState} from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import s from './Offers.module.scss';

type OffersProps = {
  offers: Array<any>;
};

const Offers: FC<OffersProps> = ({offers}) => {
  const [expanded, setExpanded] = useState('');

  return (
    <>
      <div className={s.offers}>
        <Accordion style={{borderRadius: '10px'}} expanded={expanded === 'test'}>
          <AccordionSummary sx={{'& .MuiAccordionSummary-content': {display: 'block'}}}>
            <div>
              <h2 className={s.offers__heading}>Central Capital Investments</h2>
              <div className={s.offers__grid}>
                <div>
                  <div className={s.offers__container}>
                    <p className={s.offers__title}>Loan Amount</p>
                    <p className={s.offers__subtitle}>Something</p>
                  </div>
                </div>
                <div>
                  <div className={s.offers__container}>
                    <p className={s.offers__title}>LTV</p>
                    <p className={s.offers__subtitle}>75%</p>
                  </div>
                </div>
                <div>
                  <div className={s.offers__container}>
                    <p className={s.offers__title}>LoanTerm</p>
                    <p className={s.offers__subtitle}>3 Years</p>
                  </div>
                </div>
                <div>
                  <div className={s.offers__container}>
                    <p className={s.offers__title}>Amortization</p>
                    <p className={s.offers__subtitle}>Full Term I/O</p>
                  </div>
                </div>
              </div>
              {!(expanded === 'test') && (
                <div className={s.offers__show}>
                  <p className={s.offers__headingMore}>Last modified 5 hours ago</p>
                  <button className={s.offers__button} onClick={() => setExpanded('test')}>
                    Show more
                  </button>
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={s.offers__grid}>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Interest Rate</p>
                  <p className={s.offers__subtitle}>-</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Stipulation</p>
                  <p className={s.offers__subtitle}>-</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Fees</p>
                  <p className={s.offers__subtitle}>$50,000</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Down Payment</p>
                  <p className={s.offers__subtitle}>$320,000</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Recourse</p>
                  <p className={s.offers__subtitle}>Full</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Prepayment</p>
                  <p className={s.offers__subtitle}>No penalty</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Required Reserves</p>
                  <p className={s.offers__subtitle}>None</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Cash Management</p>
                  <p className={s.offers__subtitle}>No LockBox</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Collateral</p>
                  <p className={s.offers__subtitle}>Something</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Other Requirements</p>
                  <p className={s.offers__subtitle}>None</p>
                </div>
              </div>
              <div>
                <div className={s.offers__container}>
                  <p className={s.offers__title}>Legal Term Sheet</p>
                  <p className={s.offers__subtitle}>No attachment</p>
                </div>
              </div>
            </div>
            <div className={s.offers__btnGroup}>
              <button className={s.offers__button}>Accept</button>
              <button className={s.offers__button}>Modify</button>
              <button className={s.offers__button}>Delete</button>
            </div>
            <div className={s.offers__show}>
              <p className={s.offers__headingLess}>Last modified 5 hours ago</p>
              <button className={s.offers__button} onClick={() => setExpanded('')}>
                Show less
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Offers;
