import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Pages
import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {isValue, MultiFamilyAcquisition} from 'pages/CreateNewOffer/helpers';

import s from './Twelfth.module.scss';

type TwelfthProps = {
  goNext: () => void;
};

const Twelfth: FC<TwelfthProps> = ({goNext}) => {
  const {values, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.multiFamilyAcquisition);
  const iconTitles = (Object.keys(MultiFamilyAcquisition) as Array<keyof typeof MultiFamilyAcquisition>).filter(
    isValue
  );

  return (
    <div className={s.twelfth}>
      <div className={s.twelfth__header}>
        <p className={s.twelfth__title}>How can we assist with this Multifamily Acquisition?</p>
      </div>
      <div className={s.twelfth__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            maxWidth="70px"
            key={iconTitle}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('multiFamilyAcquisition', iconTitle);
              setSelectedCard(iconTitle);
              goNext();
            }}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>
    </div>
  );
};

export {Twelfth};
