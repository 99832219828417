import {FC, Dispatch, DragEvent, ReactElement, SetStateAction} from 'react';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';

import {ReactComponent as DownloadIcon} from 'assets/icons/DownloadIcon.svg';
import {ReactComponent as RemoveIcon} from 'assets/icons/trash.svg';
import {ReactComponent as ImageIcon} from 'assets/icons/image.svg';
import {ReactComponent as GalleryIcon} from 'assets/icons/GalleryIcon.svg';
import {ReactComponent as PdfIcon} from 'assets/icons/pdf.svg';
import {ReactComponent as SpreadSheetIcon} from 'assets/icons/spreadsheet.svg';
import {ReactComponent as OtherIcon} from 'assets/icons/other_attachment.svg';
import {ReactComponent as TextIcon} from 'assets/icons/text.svg';

import {deleteAttachment, updateAttachments} from 'store/project-service/asyncActions';

import s from './Attachments.module.scss';

type AttachmentsProps = {
  projectSym: string;
  attachments: Array<any>;
  setAttachments: Dispatch<SetStateAction<Array<any>>>;
};

const Attachments: FC<AttachmentsProps> = ({projectSym, attachments, setAttachments}) => {
  const dispatch = useDispatch();

  const getIconByFileType = (fileType: string): ReactElement => {
    switch (fileType) {
      case 'pdf':
        return <PdfIcon />;
      case 'doc':
        return <TextIcon />;
      case 'docx':
        return <TextIcon />;
      case 'txt':
        return <TextIcon />;
      case 'rtf':
        return <TextIcon />;
      case 'xlsx':
        return <SpreadSheetIcon />;
      case 'jpg':
        return <ImageIcon />;
      case 'png':
        return <ImageIcon />;
      default:
        return <OtherIcon />;
    }
  };

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnd = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const {files} = event.dataTransfer;

    if (files) {
      const fileNames: Array<any> = [];
      Array.from(files).forEach((file) => {
        fileNames.push({
          name: file.name,
        });
      });

      dispatch(updateAttachments({sym: projectSym, attachments: Array.from(files), dispatch}));

      setAttachments([...attachments, ...fileNames]);
    }
  };

  const handleAttachmentsUpload = (files: FileList | null) => {
    if (files) {
      const attachmentSrc: Array<any> = [];
      Array.from(files).forEach((file) => {
        attachmentSrc.push({
          name: file.name,
          fileType: file.type,
          creationDate: file.lastModified,
        });
      });

      dispatch(updateAttachments({sym: projectSym, attachments: Array.from(files), dispatch}));

      setAttachments([...attachments, ...attachmentSrc]);
    }
  };

  const handleAttachmentRemove = (attachemt: any) => {
    dispatch(deleteAttachment({sym: projectSym, attachmentSym: attachemt.sym, dispatch}));

    setAttachments(attachments.filter((item) => item !== attachemt));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {() => (
        <Form>
          <div>
            <p className={s.attachments__text}>
              Use this page to attach files like your pro forma, rent roll, and other supporting documents.
            </p>
            <div className={s.attachments}>
              <p className={s.attachments__heading}>Upload</p>
              <div
                className={s.attachments__area}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                onDrop={handleDrop}
              >
                <label className={s.attachments__upload}>
                  <div>
                    <GalleryIcon className={s.attachments__upload__icon} />
                    <p>
                      Drag and drop the file or <strong className={s.attachments__upload__link}>Browse</strong>
                    </p>
                    <p className={s.attachments__upload__size}>Max. file size: 150 MB</p>
                  </div>
                  <input
                    id="attachmentsUpload"
                    type="file"
                    name="attachmentsUpload"
                    onChange={(e) => handleAttachmentsUpload(e.target.files)}
                    multiple
                  />
                </label>
              </div>
              {attachments.length ? (
                <div className={s.list}>
                  <p className={s.attachments__heading}>Uploaded Files</p>
                  {attachments &&
                    attachments.map((attachment) => (
                      <div key={attachment.url} className={s.list__item}>
                        <div className={s.attachment}>
                          <div className={s.attachment__details}>
                            {getIconByFileType(attachment.fileType)}
                            <div>
                              <p className={s.attachment__name} title={attachment.name}>
                                {attachment.name}
                              </p>
                              <p className={s.attachment__date}>{attachment.creationDate}</p>
                            </div>
                          </div>
                          <div>
                            <a href={attachment.url} target="_blank" rel="noreferrer" download>
                              <DownloadIcon className={s.attachment__icon} />
                            </a>
                            <RemoveIcon
                              className={s.attachment__icon}
                              onClick={() => handleAttachmentRemove(attachment)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Attachments;
