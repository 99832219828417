import {FC, useEffect, useState} from 'react';

// Core
import {ChecklistItemType} from 'core/_models/projectOverview';

// Assets
import {ReactComponent as CompleteIcon} from 'assets/icons/complete.svg';

// Components
import BasicButton from 'shared/components/BasicButton';
import ChecklistItem from './ChecklistItem';

import s from './Checklist.module.scss';

interface IFormatedAttachmentsProps {
  name: string;
  type: string;
  url: string;
  creationDate: string;
}

enum ChecklistItems {
  'Sponsor Profile',
  'Sources & Uses',
  'Asset Photos',
  'Pro Forma',
  'Rent Roll',
  'Sponsor Financials',
  'Financial Statements',
  'Schedule of Real Estate Owned',
}

type ChecklistItemsType = keyof typeof ChecklistItems;
type ChecklistProps = {
  project: any;
  emailVerified: boolean;
};

const othersSectionTitle = 'Others';

const Checklist: FC<ChecklistProps> = ({project, emailVerified}) => {
  const [uniqueDocSections, setUniqueDocSections] = useState<Array<string>>([]);

  useEffect(() => {
    if (!project.attachmentList) return;
    const formatedAttachments = project.attachmentList.reduce(
      (res: any, item: any) => [
        ...res,
        {
          name: item.name,
          url: item.url,
          type: item.formatPrimaryType,
          creationDate: item.creationTime.formatHumanAbbr(),
        },
      ],
      []
    );
    const uniqueDocSections: any = new Set([
      ...formatedAttachments.map((item: IFormatedAttachmentsProps) => item.type),
    ]);
    if (uniqueDocSections.has(undefined)) {
      uniqueDocSections.delete(undefined);
      uniqueDocSections.add(othersSectionTitle);
    }
    setUniqueDocSections(Array.from(uniqueDocSections));
  }, [project]);

  const handleCheck = (item: ChecklistItems) =>
    uniqueDocSections.indexOf(ChecklistItems[item] as ChecklistItemsType) === -1;

  const items = [
    {
      id: 1,
      label: 'Verify your email address',
      isChecked: emailVerified,
      link: (
        <a href="stacksource.com" className={s.Checklist__link}>
          Resend email verification
        </a>
      ),
      isWithUpload: false,
      isRequired: true,
    },
    {
      id: 2,
      label: '',
      isChecked: !!project.sponsorProfile.data,
      link: (
        <a href="stacksource.com" className={s.Checklist__link}>
          Sponsor profile
        </a>
      ),
      isWithUpload: false,
      isRequired: true,
    },
    {
      id: 3,
      label: '',
      isChecked: project.capStack?.sourceList?.size && project.capStack?.useList?.size,
      link: (
        <a href="stacksource.com" className={s.Checklist__link}>
          Sources & uses
        </a>
      ),
      isWithUpload: false,
      isRequired: true,
    },
    {
      id: 4,
      label: 'Asset photos',
      isChecked: project?.imageList?.size,
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
    {
      id: 5,
      label: 'Pro forma',
      isChecked: handleCheck(ChecklistItems['Pro Forma']),
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
    {
      id: 6,
      label: 'Rent roll',
      isChecked: handleCheck(ChecklistItems['Rent Roll']),
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
    {
      id: 7,
      label: 'Sponsor financials',
      isChecked: handleCheck(ChecklistItems['Sponsor Financials']),
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
    {
      id: 8,
      label: 'Financial statements',
      isChecked: handleCheck(ChecklistItems['Financial Statements']),
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
    {
      id: 9,
      label: 'Schedule of real estate owned',
      isChecked: handleCheck(ChecklistItems['Schedule of Real Estate Owned']),
      link: '',
      isWithUpload: true,
      isRequired: true,
    },
  ];

  const [checklistItems, setChecklistItems] = useState<Array<ChecklistItemType>>(items);
  const [isCompleted, setIsCompleted] = useState<boolean>();

  useEffect(() => {
    setChecklistItems(items);
  }, [items.toString(), project]);

  useEffect(() => {
    if (items.every((item) => item.isChecked)) {
      setIsCompleted(true);
    } else setIsCompleted(false);
  }, [checklistItems]);

  return (
    <div className={s.Checklist}>
      <div className={s.Checklist__header}>
        <h2 className={s.Checklist__heading}>Project Checklist</h2>
        <BasicButton className="grey" disabled={!items.some((item) => item.isChecked)}>
          Approve
        </BasicButton>
      </div>
      {isCompleted ? (
        <div className={s.Checklist__completed}>
          <CompleteIcon />
          <p>Your checklist is complete!</p>
          <a href="stacksource.com" className={s.Checklist__undo}>
            Undo
          </a>
        </div>
      ) : (
        <>
          <p className={s.Checklist__description}>
            The following items <strong>must be completed</strong> before your deal can be sent to capital sources.{' '}
            <a href="stacksource.com" className={s.Checklist__link}>
              <strong>Preview</strong>
            </a>
          </p>
          <div className={s.Checklist__list}>
            {checklistItems.map((item, index) => (
              <ChecklistItem key={item.id} item={item} index={index} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Checklist;
