import {FC, useState, useEffect} from 'react';
import {KeyStatsType, KeyStatsSection} from 'core/_models/lenderView';
import cx from 'classnames';
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';
import AccordionWrapper from 'shared/components/Accordion';
import styles from './KeyStats.module.scss';
import KeyStatsCell from './KeyStatsCell';
import {IsMobileViewProps} from '../helpers';

const filterValuesByBoolean = (values: KeyStatsSection | undefined) => {
  if (!values) return false;
  return !Object.values(values)
    .map((item) => item.map((entry) => entry.isZero).every(Boolean))
    .every(Boolean);
};

const KeyStats: FC<IsMobileViewProps> = ({isMobileView}) => {
  const project = useSelector(selectorGetProject);
  const [data, setData] = useState<KeyStatsType>();

  useEffect(() => {
    if (!Object.getOwnPropertyNames(project).length) return;
    setData({
      current: {
        primary: [
          {
            number: `${project.ltc.formatMaxDecimalPlaces(0).replace(' to ', '-').replaceAll('%', '')}%`,
            label: 'LTC',
            isZero: project.ltc.isZero(),
          },
          {
            number: `${project.ltv.formatMaxDecimalPlaces(0).replace(' to ', '-').replaceAll('%', '')}%`,
            label: 'LTV',
            isZero: project.ltv.isZero(),
          },
          {
            number: `${project.debtYield.formatMaxDecimalPlaces(1).replace(' to ', '-').replaceAll('%', '')}%`,
            label: 'Debt Yield',
            isZero: project.debtYield.isZero(),
          },
          {
            number: project.valuationPerUnit.value.format(),
            label: project.valuationPerUnit.formatUnit(),
            isZero: project.valuationPerUnit.value.isZero(),
          },
        ],
        secondary: [
          {
            number: project.acquisitionPrice.format(),
            label: 'Acquisition Price',
            isZero: project.acquisitionPrice.isZero(),
          },
          {
            number: `${project.acquisitionDate.formatHumanAbbr()} ${project.acquisitionDate.year}`,
            label: 'Acquisition Date',
            isZero: project.acquisitionDate.isEmpty(),
          },
          {
            number: project.formatAcquisitionStatus(),
            label: 'Acquisition Status',
            isZero: !project.formatAcquisitionStatus(),
          },
          {
            number: project.formatOccupancy(1),
            label: 'Occupancy',
            isZero: !project.formatOccupancy(1),
          },
          {
            number: project.currentNetOperatingIncome.format(),
            label: 'Current NOI',
            isZero: project.currentNetOperatingIncome.isZero(),
          },
          {
            number: project.capRate.format(),
            label: 'Cap Rate',
            isZero: project.capRate.isZero(),
          },
          {
            number: project.capRateValuation.format(),
            label: 'Cap Rate Valuation',
            isZero: project.capRateValuation.isZero(),
          },
          {
            number: project.totalProjectBudget.format(),
            label: 'Total Project Budget',
            isZero: project.totalProjectBudget.isZero(),
          },
        ],
      },
      stabilized: {
        primary: [
          {
            number: `${project.stabilizedLtv.formatMaxDecimalPlaces(0).replace(' to ', '-').replaceAll('%', '')}%`,
            label: 'LTV',
            isZero: project.stabilizedLtv.isZero(),
          },
          {
            number: `${project.stabilizedDebtYield
              .formatMaxDecimalPlaces(0)
              .replace(' to ', '-')
              .replaceAll('%', '')}%`,
            label: 'Debt Yield',
            isZero: project.stabilizedDebtYield.isZero(),
          },
        ],
        secondary: [
          {
            number: project.proFormaNetOperatingIncome.format(),
            label: 'Pro Forma NOI',
            isZero: project.proFormaNetOperatingIncome.isZero(),
          },
          {
            number: project.proFormaCapRate.format(),
            label: 'Pro Forma Cap Rate',
            isZero: project.proFormaCapRate.isZero(),
          },
          {
            number: project.stabilizedCapRateValuation.format(),
            label: 'Pro Forma Cap Rate Valuation',
            isZero: project.stabilizedCapRateValuation.isZero(),
          },
        ],
      },
    });
  }, [project]);

  const content = (
    <div className={cx(styles.keyStats, 'LenderView__section')}>
      {!isMobileView && <p className="LenderView__infoContentTitle">Key Stats</p>}
      <div className={styles.keyStats__sections}>
        {filterValuesByBoolean(data?.current) && (
          <div className={cx(styles.StatsBlock)}>
            <div className={styles.StatsBlock__title}>Current</div>
            <div className={styles.StatsBlock__section}>
              {data?.current.primary.map((item) => (
                <KeyStatsCell key={item.label} isPrimary label={item.label} value={item.number} isZero={item.isZero} />
              ))}
            </div>
            <div className={styles.StatsBlock__section}>
              {data?.current.secondary.map((item) => (
                <KeyStatsCell key={item.label} label={item.label} value={item.number} isZero={item.isZero} />
              ))}
            </div>
          </div>
        )}
        {filterValuesByBoolean(data?.stabilized) && (
          <div className={styles.StatsBlock}>
            <div className={styles.StatsBlock__title}>As-stabilized</div>
            <div className={styles.StatsBlock__section}>
              {data?.stabilized.primary.map((item) => (
                <KeyStatsCell key={item.label} isPrimary label={item.label} value={item.number} isZero={item.isZero} />
              ))}
            </div>
            <div className={styles.StatsBlock__section}>
              {data?.stabilized.secondary.map((item) => (
                <KeyStatsCell key={item.label} label={item.label} value={item.number} isZero={item.isZero} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const shouldSectionBeRendered =
    data && (filterValuesByBoolean(data.current) || filterValuesByBoolean(data.stabilized));

  if (!shouldSectionBeRendered) return null;
  if (isMobileView) return <AccordionWrapper title="Key Stats">{content}</AccordionWrapper>;

  return content;
};

export default KeyStats;
