import {Ava} from '@burnaby/ava';

export * from '@burnaby/ava';

const remote = process.env.REACT_APP_AVA_REMOTE;
if (!remote) {
  throw new Error('Required environment variable REACT_APP_AVA_REMOTE is unset');
}

Ava.configureRemote(remote);

export const ava = new Ava({remote});
