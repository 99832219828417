import {AmortizationSubsteps} from 'core/_consts';
import {FC, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Amount from './Amount';
import OnlyPeriod from './OnlyPeriod';
import Period from './Period';
import PeriodDuration from './PeriodDuration';

type AmortizationSubstepsKeys = keyof typeof AmortizationSubsteps;

const Amortization: FC = () => {
  const location = useLocation();
  const [substep, setSubstep] = useState<AmortizationSubstepsKeys | null>();

  useEffect(() => {
    setSubstep(new URLSearchParams(location.search).get('substep') as AmortizationSubstepsKeys);
  }, [location.search]);

  switch (substep) {
    case AmortizationSubsteps[AmortizationSubsteps.amortizationPeriod]:
      return <Period />;

    case AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriod]:
      return <OnlyPeriod />;

    case AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriodDuration]:
      return <PeriodDuration />;

    case AmortizationSubsteps[AmortizationSubsteps.amount]:
      return <Amount />;

    default:
      return null;
  }
};

export default Amortization;
