import {useEffect, useState} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NextButton from 'shared/components/NextButton';
import {AmortizationSubsteps, QuoteTermsSteps, RecourseSubsteps} from 'core/_consts';
import {setAmortizationPeriod} from 'store/quote-terms-service/quoteTermsSlice';
import {selectAmortizationPeriod} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

enum periodOptions {
  '15 years' = '15 years',
  '20 years' = '20 years',
  '25 years' = '25 years',
  '30 years' = '30 years',
  '35 years' = '35 years',
  '40 years' = '40 years',
  interestOnly = 'interest only',
  other = 'other',
}

const handleSelectedValue = (value: string) => {
  switch (value) {
    case periodOptions.interestOnly:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.recourse],
        substep: RecourseSubsteps[RecourseSubsteps.availableRecourse],
      };
    case periodOptions.other:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.amortization],
        substep: AmortizationSubsteps[AmortizationSubsteps.amount],
      };
    default:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.amortization],
        substep: AmortizationSubsteps[AmortizationSubsteps.interestOnlyPeriod],
      };
  }
};

const Period = () => {
  const defaultValue = useSelector(selectAmortizationPeriod);
  const [selectedPeriod, setSelectedPeriod] = useState<Array<string>>(defaultValue);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  useEffect(() => {
    nextButtonClickHandler(nextButtonRef);
  }, [selectedPeriod]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">What is the amortization period?</p>
      <RadioGroup
        maxWidth="565px"
        isAnyValueSelected={selectedPeriod}
        setIsAnyValueSelected={setSelectedPeriod}
        radioButtonTitles={Object.values(periodOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      <NextButton
        isHidden={!defaultValue.length}
        setNextButtonRef={setNextButtonRef}
        isDisabled={!selectedPeriod.length}
        slideData={{[AmortizationSubsteps[AmortizationSubsteps.amortizationPeriod]]: selectedPeriod}}
        action={setAmortizationPeriod}
        nextSlide={handleSelectedValue(selectedPeriod[0])}
      />
    </div>
  );
};

export default Period;
