import {FC, useEffect, useMemo, useState} from 'react';

// Material
import makeStyles from '@mui/styles/makeStyles';
import Accordion from '@mui/material/Accordion';
import {Backdrop, Fade, Modal} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Core
import {creditScoreOptions} from 'core/utils/selectOptions/guarantorsOptions';
import {GuarantorsType} from 'core/_types/UnderwritingTypes';
import {usesTypeOptions} from 'core/utils/selectOptions/usesTypeOptions';

import PhotoSlider from 'pages/LenderView/PhotoSlider';

// Components (Tabs)
import AssetInfo from './AssetInfo';
import Overview from './Overview';
import FinancialInfo from './FinancialInfo';
import Pictures from './Pictures';
import Attachments from './Attachments';
import TenantInfo from './TenantInfo';
import Guarantors from './Guarantors';
import SponsorProfile from './SponsorProfile';
import {SourcesAndUses} from './SourcesAndUses';

import s from './Underwriting.module.scss';

// Accordion root styles
const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    borderTop: '1px solid #dde4e4',
    boxShadow: 'none',
  },
  details: {
    border: 'none',
    boxShadow: 'none',
  },
}));

type UnderwritingProps = {
  project: any;
};

const Underwriting: FC<UnderwritingProps> = ({project}) => {
  const classes = useStyles();

  // Sources & Uses
  const [sources, setSources] = useState<Array<any>>([]);
  const [uses, setUses] = useState<Array<any>>([]);

  // Pictures
  const [galleryItemNumber, setGalleryItemNumber] = useState(-1);
  const [pictures, setPictures] = useState<Array<any>>([]);
  const [preview, setPreview] = useState(null);

  // Attachments
  const [attachments, setAttachments] = useState<Array<any>>([]);

  // TODO: move to helper funcions file
  const getFileType = (name: string): string => {
    const splitted = name.split('.');
    return splitted[splitted.length - 1];
  };

  // Guarantors
  const [guarantors, setGuarantors] = useState<GuarantorsType[]>([]);

  // Sponsor Profile
  const sponsorProfile = useMemo(() => project.sponsorProfile, [project]);

  // Getting & formatting the data
  useEffect(() => {
    // TODO: move date formatting to separate helper-function
    const formattedSources = project.capStack?.sourceList?.reduce(
      (res: any, item: any) => [
        ...res,
        {
          type: {value: item.type, label: item.formatType()},
          principal: `USD ${item.principal.toNumber().toString().replace(/[\s,]/g, '')}`,
          entity: item.entity,
          maturityDate: new Date(
            `${item.maturityDate.getYear()}-${item.maturityDate.getMonth()}-${item.maturityDate.getDayOfMonth()}`
          )
            .toISOString()
            .split('T')[0],
        },
      ],
      []
    );
    const formattedUses = project.capStack?.useList?.reduce(
      (res: any, item: any) => [
        ...res,
        {
          use: item.use,
          customUse: usesTypeOptions.includes(item.use) ? '' : item.use,
          principal: `USD ${item.principal.toNumber().toString().replace(/[\s,]/g, '')}`,
        },
      ],
      []
    );
    setSources(Array.from(formattedSources));
    setUses(Array.from(formattedUses));

    setPictures(Array.from(project.imageList));

    const formattedAttachments = project.attachmentList.reduce(
      (res: any, item: any) => [
        ...res,
        {
          name: item.name,
          fileType: getFileType(item.name),
          url: item.url,
          type: item.formatPrimaryType,
          creationDate: item.creationTime.formatHumanAbbr(),
        },
      ],
      []
    );
    setAttachments(formattedAttachments);

    if (Object.getOwnPropertyNames(project).length) {
      const formattedGuarantors: GuarantorsType[] = Array.from(project.guarantorList).reduce(
        (res: any, item: any) => [
          ...res,
          {
            name: item.name,
            address: item.address.formatLines(),
            netWorth: item.netWorth.formatNoDecimal(),
            liquidity: item.liquidity.formatNoDecimal(),
            creditScore: creditScoreOptions[item?.creditScore].label,
          },
        ],
        []
      ) as GuarantorsType[];
      setGuarantors(formattedGuarantors);
    }
  }, [project]);

  return (
    <div className={s.Underwriting}>
      <h2 className={s.Underwriting__heading}>Underwriting</h2>
      <Modal
        open={galleryItemNumber !== -1}
        className={s.modal}
        onClose={() => setGalleryItemNumber(-1)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={galleryItemNumber !== -1}>
          <div className={s.Underwriting__sliderFade}>
            <PhotoSlider initialActiveItem={galleryItemNumber} images={pictures} />
          </div>
        </Fade>
      </Modal>
      <div>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <p className={s.Tab__heading}>Overview</p>
          </AccordionSummary>
          <AccordionDetails>
            <Overview project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel2a-header">
            <p className={s.Tab__heading}>Asset information</p>
          </AccordionSummary>
          <AccordionDetails>
            <AssetInfo project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Financial information</p>
          </AccordionSummary>
          <AccordionDetails>
            <FinancialInfo project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Tenant Information</p>
          </AccordionSummary>
          <AccordionDetails>
            <TenantInfo project={project} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Sources & Uses</p>
          </AccordionSummary>
          <AccordionDetails>
            <SourcesAndUses sources={sources} uses={uses} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Pictures</p>
          </AccordionSummary>
          <AccordionDetails>
            <Pictures
              projectSym={project?.sym}
              pictures={pictures}
              preview={preview}
              setPictures={setPictures}
              setPreview={setPreview}
              setGalleryItemNumber={setGalleryItemNumber}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Attachments</p>
          </AccordionSummary>
          <AccordionDetails>
            <Attachments projectSym={project?.sym} attachments={attachments} setAttachments={setAttachments} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Guarantors</p>
          </AccordionSummary>
          <AccordionDetails>
            <Guarantors guarantors={guarantors} setGuarantors={setGuarantors} />
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.root}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel3a-header">
            <p className={s.Tab__heading}>Sponsor Profile</p>
          </AccordionSummary>
          <AccordionDetails>
            <SponsorProfile sponsorProfile={sponsorProfile} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Underwriting;
