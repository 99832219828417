import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';

import MenuIcon from '@mui/icons-material/Menu';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import makeStyles from '@mui/styles/makeStyles';
import {IconButton, MenuItem, MenuList, Popover} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {styled} from '@mui/styles';

import {ROUTERS} from 'core/_consts';
import {toSinatraUrl} from 'core/helpers/sinatra';

import {selectorGetProfile} from 'store/ava-service/selector';
import {logout} from 'store/ava-service/asyncActions';
import {setProject} from 'store/project-service/projectSlice';

import {ReactComponent as LogoIcon} from 'shared/media/Logo.svg';

import s from './Header.module.scss';

const StyledMenuIcon = styled(IconButton)({
  display: 'none',
  '@media(max-width: 768px)': {
    display: 'flex',
    position: 'absolute',
    right: '20px',
    padding: 0,
  },
});

const useStyles = makeStyles({
  hover: {
    '&:focus, &:hover': {
      backgroundColor: 'white',
    },
  },
});

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userProfile = useSelector(selectorGetProfile);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLButtonElement | null>(null);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorElMenu);
  const id = open ? 'dropdown-menu' : undefined;
  const idMenu = openMenu ? 'dropdown-menu' : undefined;

  const handleRoute = (): void => {
    // FIXME: temp solution just for better dev. routing
    dispatch(setProject(null) as any);
    history.push(ROUTERS.DASHBOARD);
  };

  return (
    <div className={s.header}>
      <div className={s.header__container}>
        <div className={s.header__left}>
          <StyledMenuIcon size="large" onClick={handleClickMenu}>
            <MenuIcon />
          </StyledMenuIcon>
          <Popover
            id={idMenu}
            open={openMenu}
            anchorEl={anchorElMenu}
            onClose={handleCloseMenu}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuList autoFocusItem>
              <MenuItem
                style={{justifyContent: 'flex-start', color: '#848484'}}
                onClick={() => history.push(`${ROUTERS.CREATE_NEW_PROJECT}/new-user-email`)}
              >
                + New Project
              </MenuItem>
              <MenuItem style={{justifyContent: 'flex-start', color: '#848484'}} onClick={handleRoute}>
                Projects
              </MenuItem>
            </MenuList>
          </Popover>
          <LogoIcon className={s.header__logo} />
          <p className={s.header__title}>StackSource</p>
        </div>
        {userProfile ? (
          <div className={s.header__right}>
            <p className={s.header__action} onClick={() => history.push(`${ROUTERS.CREATE_NEW_PROJECT}/asset-group`)}>
              <AddIcon /> New project
            </p>
            <p className={s.header__action} onClick={handleRoute}>
              Projects
            </p>
            <div className={s.header__dropdown}>
              <IconButton className={cx(classes.hover)} onClick={handleClick}>
                <img src="/images/profile-picture.png" alt="profile" style={{width: '40px', height: '40px'}} />
                <KeyboardArrowDownIcon className={cx(s.header__pathIcon, s.header__hide)} />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuList autoFocusItem>
                  <MenuItem
                    style={{justifyContent: 'flex-end', color: '#848484'}}
                    onClick={() => history.push(ROUTERS.PROFILE)}
                  >
                    View profile
                  </MenuItem>
                  <MenuItem
                    style={{justifyContent: 'flex-end', color: '#848484'}}
                    onClick={() => {
                      window.location.href = toSinatraUrl(window.location.href);
                    }}
                  >
                    <WebAssetIcon /> Use old UI
                  </MenuItem>
                  <MenuItem
                    style={{justifyContent: 'flex-end', color: '#848484'}}
                    onClick={() => {
                      dispatch(logout(dispatch));
                      history.push(ROUTERS.LOGIN);
                    }}
                  >
                    Sign out
                  </MenuItem>
                </MenuList>
              </Popover>
            </div>
          </div>
        ) : (
          <div className={s.header__right}>
            <p className={s.learnMore} onClick={() => history.push(`${ROUTERS.LOGIN}`)}>
              New to StackSource?{' '}
              <a href="https://stacksource.com" className={s.learnMore__link}>
                Learn More
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
