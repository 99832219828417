import {useState} from 'react';
import NumericInput from 'shared/components/NumericInput';
import RadioInput from 'shared/components/RadioInput';
import RadioGroup from 'shared/components/RadioGroup';
import {useDispatch, useSelector} from 'react-redux';
import NextButton from 'shared/components/NextButton';
import {LoanTermSubsteps, QuoteTermsSteps, dateOptions, yesNoOptions, storageKey} from 'core/_consts';
import {getMaxLimit, validateNumericInput} from 'core/utils';
import {setAnyExtensions, setExtensionsAmount, setTermDuration} from 'store/quote-terms-service/quoteTermsSlice';
import {vaultService} from 'core/service/storage';
import {selectAnyExtensions, selectTermDuration} from 'store/quote-terms-service/quoteTermsSelector';

const handleSelectedValue = (value: string) =>
  value === yesNoOptions.yes
    ? {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.extensionsAmount],
      }
    : {
        step: QuoteTermsSteps[QuoteTermsSteps.loanTerm],
        substep: LoanTermSubsteps[LoanTermSubsteps.lengthAndFee],
      };

const TermDuration = () => {
  const defaultValues = useSelector(selectTermDuration);
  const anyExtensions = useSelector(selectAnyExtensions);
  const [isAnyExtensions, setIsAnyExtensions] = useState<Array<string>>(anyExtensions);
  const [dateValue, setDateValue] = useState(defaultValues.dateValue);
  const [termValue, setTermValue] = useState(defaultValues.termValue);

  const dispatch = useDispatch();

  const onClickHandler = () => {
    vaultService.removeItem(storageKey.quoteTermsData, LoanTermSubsteps[LoanTermSubsteps.loanTermDuration]);
    if (isAnyExtensions[0] === 'no')
      vaultService.removeItem(storageKey.quoteTermsData, LoanTermSubsteps[LoanTermSubsteps.extensionsAmount]);
    dispatch(setExtensionsAmount([]));
  };

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">How long is the loan term?</p>
      <div className="FormSlide__section mb20">
        <NumericInput
          decimalScale={0}
          preventionLimit={getMaxLimit(dateValue)}
          onValueChange={(values) => setTermValue(Number(values.value))}
          isValid={validateNumericInput(termValue, getMaxLimit(dateValue))}
          defaultValue={termValue || ''}
          width="60px"
        />
        <RadioInput
          controlledInputValue={termValue}
          setRadioInputValue={setDateValue}
          defaultValue={dateValue}
          values={Object.values(dateOptions)}
        />
      </div>
      <p className="FormSlide__subTitle mb25">Any extensions?</p>
      <RadioGroup
        isAnyValueSelected={isAnyExtensions}
        setIsAnyValueSelected={setIsAnyExtensions}
        radioButtonTitles={Object.values(yesNoOptions)}
      />
      <NextButton
        sideEffect={onClickHandler}
        isDisabled={!isAnyExtensions.length || !validateNumericInput(termValue, getMaxLimit(dateValue))}
        slideData={{
          [LoanTermSubsteps[LoanTermSubsteps.customLoanTerm]]: {dateValue, termValue},
          [LoanTermSubsteps[LoanTermSubsteps.anyExtensions]]: isAnyExtensions,
        }}
        action={[setTermDuration, setAnyExtensions]}
        nextSlide={handleSelectedValue(isAnyExtensions[0])}
      />
    </div>
  );
};

export default TermDuration;
