import {FC, useEffect, useState} from 'react';
import cx from 'classnames';
import {FieldInputProps} from 'formik';
import {getMaxLimit, validateNumericInput} from 'core/utils';
import s from './RadioInput.module.scss';

const isItemActive = (selectedValue: string, currentValue: string) => selectedValue === currentValue;
const inputPositions = ['left', 'right'];

type RadioInputProps = {
  values: Array<string>;
  field?: FieldInputProps<any>;
  setFieldValue?: (name: string, value: string | number, isValidated: boolean) => void;
  setRadioInputValue?: (value: string) => void;
  defaultValue?: string;
  controlledInputValue?: number | null | string;
};

const RadioInput: FC<RadioInputProps> = ({
  values,
  setFieldValue,
  field,
  setRadioInputValue,
  defaultValue,
  controlledInputValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || field?.value || values[0]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (value: string) => {
    setSelectedValue(value);
    setRadioInputValue?.(value);
    setFieldValue?.(field?.name || '', value, true);
  };

  useEffect(() => {
    if (controlledInputValue) {
      const optionToPreventSwitch = values.filter((item) => item !== selectedValue)[0];
      setIsDisabled(!validateNumericInput(Number(controlledInputValue), getMaxLimit(optionToPreventSwitch)));
    } else {
      setIsDisabled(false);
    }
  }, [controlledInputValue]);

  return (
    <div className={s.radioInput}>
      {values.map((item, index) => (
        <div
          key={item.repeat(index)}
          className={cx(s.radioInput__cell, s[`radioInput__cell_${inputPositions[index]}`], {
            [s.radioInput__cell_selected]: isItemActive(selectedValue, item),
            [s.radioInput_disabled]: isDisabled,
          })}
          onClick={() => handleClick(values[index])}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default RadioInput;
