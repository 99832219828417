import {FC, useState} from 'react';
import {useFormikContext} from 'formik';

// Pages
import SelectionCard from 'pages/CreateNewOffer/SelectionCard';
import {isValue, UserRole} from 'pages/CreateNewOffer/helpers';

import s from './Eleventh.module.scss';

type EleventhProps = {
  goNext: () => void;
};

const Eleventh: FC<EleventhProps> = ({goNext}) => {
  const {values, setFieldValue}: any = useFormikContext();

  const [selectedCard, setSelectedCard] = useState(values.sponsorRole);
  const iconTitles = (Object.keys(UserRole) as Array<keyof typeof UserRole>).filter(isValue);

  return (
    <div className={s.eleventh}>
      <div className={s.eleventh__header}>
        <p className={s.eleventh__title}>Identify your role</p>
        <p className={s.eleventh__subtitle}>Are you the direct borrower, or are you representing the borrower?</p>
      </div>
      <div className={s.eleventh__cards}>
        {iconTitles.map((iconTitle) => (
          <SelectionCard
            key={iconTitle}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelection={() => {
              setFieldValue('sponsorRole', iconTitle);
              setSelectedCard(iconTitle);
              goNext();
            }}
            title={iconTitle}
            iconName={iconTitle.toLocaleLowerCase()}
          />
        ))}
      </div>
    </div>
  );
};

export {Eleventh};
