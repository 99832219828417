import {useState} from 'react';
import {Slider, Box, createTheme} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import s from './Seventh.module.scss';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
  },
});

const Seventh = () => {
  const [percent, setPercent] = useState<number>(0);
  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setPercent(newValue as number);
  };

  return (
    <div className={s.seventh}>
      <div className={s.seventh__header}>
        <p className={s.seventh__title}>Financing Request</p>
        <p className={s.seventh__subtitle}>Total Project Budget: $3,000,000</p>
      </div>
      <div className={s.seventh__rangeWrapper}>
        <div className={s.seventh__titleWrapper}>
          <p className={s.seventh__rangeTitle}>LTC*</p>
          <p className={s.seventh__percent}>{percent}%</p>
        </div>

        <ThemeProvider theme={muiTheme}>
          <Box sx={{width: '100%'}}>
            <Slider
              defaultValue={0}
              size="small"
              step={1}
              min={0}
              max={100}
              color="primary"
              value={percent}
              onChange={handleRangeChange}
            />
          </Box>
        </ThemeProvider>
      </div>
      <div className={s.seventh__secondaryHeader}>
        <p className={s.seventh__secondaryTitle}>Financing Required: $0</p>
        <p className={s.seventh__secondarySubtitle}>
          This is the amount of the loan you are requesting from stacksource.
        </p>
      </div>
    </div>
  );
};

export {Seventh};
