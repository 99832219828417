import {FC, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Button} from '@mui/material';
import cx from 'classnames';

import ModalWin from 'shared/components/Modal/ModalWin';
import CheckboxButton from 'shared/components/CheckboxButton';
import {THEMES} from 'shared/helpers';

import {ModalTypes} from '../helpers';
import {instruments, instrumentsConfig} from './helpers';

const providerModals: Partial<Record<instruments, ModalTypes>> = {
  [instruments.senior]: ModalTypes.senior,
  // [instruments.subordinate]: ModalTypes.subordinate,
  // [instruments.equity]: ModalTypes.equity,
};

const useStyles = makeStyles({
  item: {
    flexBasis: '140px',
  },
  primaryBtn: {
    color: '#848484',
    border: '1px solid gray',
    borderRadius: '20px',
    font: '400 16px Source Sans Pro',
    textTransform: 'none',

    '&::after': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      content: '"+"',
      position: 'absolute',
      right: '3px',
      fontSize: '30px',
      backgroundColor: '#eee',
      borderRadius: '50%',
      width: '28px',
      height: '28px',
    },

    '&:hover': {
      color: '#000',
      backgroundColor: THEMES.lightBlue,
    },
  },
  active: {
    color: 'black',
    backgroundColor: THEMES.lightBlue,

    '&::after': {
      color: '#fff',
      backgroundColor: THEMES.blue,
    },
  },
  longText: {
    paddingRight: '30px',
  },
});

type InstrumentButtonProps = {
  instrument: instruments;
  selected: instruments;
  setSelected: (instrument: instruments) => void;
  longText?: boolean;
};

export const InstrumentButton: FC<InstrumentButtonProps> = ({instrument, selected, setSelected, longText}) => {
  const classes = useStyles();

  const active = selected === instrument;
  const modal = providerModals[instrument];
  const [isOpen, setOpen] = useState(false);

  const text = instrumentsConfig[instrument].label;

  return modal == null ? (
    <CheckboxButton
      text={text}
      variant="capitalSourcesFilter"
      handleClick={() => setSelected(instrument)}
      isActive={active}
    />
  ) : (
    <>
      <ModalWin isOpen={isOpen} setOpen={setOpen} modal={modal} title="Matched Capital Sources" />
      <Button
        className={cx(classes.item, classes.primaryBtn, active && classes.active, longText && classes.longText)}
        onClick={() => {
          setSelected(instrument);
          setOpen(true);
        }}
      >
        {text}
      </Button>
    </>
  );
};
