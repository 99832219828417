import {BorrowerStipulationsLabels, MaxValidationLimits, QuoteTermsSteps} from 'core/_consts';
import {Form, Formik} from 'formik';
import NextButton from 'shared/components/NextButton';
import {setBorrowerStipulations} from 'store/quote-terms-service/quoteTermsSlice';
import {selectBorrowerStipulations} from 'store/quote-terms-service/quoteTermsSelector';
import {useSelector} from 'react-redux';
import Controller from 'shared/components/FormikController';
import {FormikElement} from 'core/enums/formik-controller.enum';
import {borrowerStipulationsSchema} from 'core/helpers/yupSchemas';

const doesInputNeedSign = (currentItem: string) =>
  currentItem === BorrowerStipulationsLabels.creditScore ? '' : '% of loan';

const BorrowerStipulations = () => {
  const defaultValues = useSelector(selectBorrowerStipulations);

  const isCreditScoreItem = (item: string) => item === BorrowerStipulationsLabels.creditScore;

  return (
    <div className="FormSlide">
      <div className="FormSlide__heading">
        <p className="FormSlide__title">Enter your borrower minimum requirements.</p>
        <p className="FormSlide__subTitle mb25">You can skip any that don&apos;t apply.</p>
      </div>
      <Formik
        initialValues={defaultValues}
        validateOnMount
        enableReinitialize
        validationSchema={borrowerStipulationsSchema}
        // TODO: add handle logic on submit
        // eslint-disable-next-line no-console
        onSubmit={(values) => console.log(values)}
      >
        {({values, setFieldValue}) => (
          <Form className="FormSlide__form">
            <div className="FormSlide__section flexWrap">
              {Object.values(BorrowerStipulationsLabels).map((item, index) => (
                <Controller
                  key={item}
                  name={`${Object.keys(defaultValues)[index]}`}
                  control={FormikElement.NUMERIC_INPUT}
                  width="140px"
                  label={item}
                  preventionLimit={isCreditScoreItem(item) ? MaxValidationLimits.thousand : MaxValidationLimits.percent}
                  textAlign="right"
                  setFieldValue={setFieldValue}
                  sign={doesInputNeedSign(item)}
                />
              ))}
            </div>
            <NextButton
              isDisabled={!Object.values(values).some(Boolean)}
              slideData={{[QuoteTermsSteps[QuoteTermsSteps.borrowerStipulations]]: values}}
              action={setBorrowerStipulations}
              nextSlide={{
                step: QuoteTermsSteps[QuoteTermsSteps.otherRequirements],
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BorrowerStipulations;
