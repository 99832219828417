import {FC, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {CircularProgress} from '@mui/material';

// Core
import {ava} from 'core/service/ava';
import {ROUTERS} from 'core/_consts';
import {DashboardViews} from 'core/_types/enum';
import {ProjectFilters} from 'core/_types/base';

// Store
import {
  selectorGetLoading,
  selectorGetNextProjects,
  selectorGetProject,
  selectorGetProjectFilters,
  selectorGetProjects,
} from 'store/project-service/selector';
import {getNextProjects, getProjects} from 'store/project-service/asyncActions';
import {getCurrentUser} from 'store/ava-service/asyncActions';
import {selectorGetProfile} from 'store/ava-service/selector';

// Components
import Header from 'shared/components/Header';
import SearchBar from './SearchBar';

// Styles
import s from './Dashboard.module.scss';
import app from '../../App.module.scss';
import ProjectList from './ProjectList';

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedProject = useSelector(selectorGetProject);
  const projects = useSelector(selectorGetProjects);
  const nextProjects = useSelector(selectorGetNextProjects);
  const filters = useSelector(selectorGetProjectFilters);
  const loading = useSelector(selectorGetLoading);
  const user = useSelector(selectorGetProfile);

  const [activeView] = useState(DashboardViews.Cards);
  const [loadMore, setLoadMore] = useState(false);

  // Temp loading state
  const [projectSelected, setProjectSelected] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (!user) {
      history.push(ROUTERS.LOGIN);
    }
  }, [user]);

  useEffect(() => {
    if (nextProjects) {
      setLoadMore(true);
    }
  }, [nextProjects]);

  useEffect(() => {
    if (selectedProject) {
      history.push(`/deal:${selectedProject.sym}/underwriting`);
    }
  }, [selectedProject]);

  const handleGetNextProjects = () => {
    dispatch(getNextProjects({nextProjects, projects}));
  };

  const handleGetFilteredProjects = (filters: ProjectFilters) => {
    dispatch(getProjects({filters}));
  };

  if (projectSelected)
    return (
      <div className={app.app__loader}>
        <CircularProgress />
      </div>
    );

  return (
    <div className={s.app}>
      <div className={s.app__content}>
        <div className={s.dashboard}>
          <Header />
          <div className={s.dashboard__projects}>
            <div>
              <SearchBar reset={!!filters} handleSearch={handleGetFilteredProjects} />
            </div>
            {activeView === DashboardViews.Cards && loading ? (
              <div className={s.dashboard__load}>
                <CircularProgress />
              </div>
            ) : (
              <ProjectList setProjectSelected={setProjectSelected} />
            )}
            {loadMore && !loading && (
              <div className={s.dashboard__actions}>
                <button className={s.loadMore} onClick={handleGetNextProjects}>
                  Load more
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
