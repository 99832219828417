import {FC} from 'react';
import {Formik} from 'formik';

// Core
import {SponsorProfileType} from 'core/_types/UnderwritingTypes';
import {Questionary} from 'core/enums/questionary';
import {SponsorCreditScore} from 'core/enums/sponsorCreditScore';
import {SponsorTypeLabels} from 'core/enums/sponsorType';
import {SponsorRoleLabels} from 'core/enums/sponsorRole';

// Store
import {useSelector} from 'react-redux';
import {selectorGetProject} from 'store/project-service/selector';

import SponsorProfileForm from './SponsorProfileForm';

// TODO: added type to sponsor profile
type SponsorProfileProps = {
  sponsorProfile: any;
};

const SponsorProfile: FC<SponsorProfileProps> = ({sponsorProfile}) => {
  const project = useSelector(selectorGetProject);

  const initialValues: SponsorProfileType = {
    sponsorRole: SponsorRoleLabels[project?.sponsorRole],
    sponsorType: SponsorTypeLabels[sponsorProfile?.sponsorType],
    name: sponsorProfile?.name,
    website: sponsorProfile?.website,
    address: {
      line1: sponsorProfile?.address.line1,
      line2: sponsorProfile?.address.line2,
      city: sponsorProfile?.address.city,
      state: sponsorProfile?.address.state,
      zip: sponsorProfile?.address.zip,
    },
    assetsUnderOwnership: sponsorProfile?.assetsUnderOwnership.value,
    sponsorCreditScore: SponsorCreditScore[project?.disclaimer.sponsorCreditScore],
    sponsorLawsuits: Questionary[project?.disclaimer.sponsorLawsuits],
    sponsorFelony: Questionary[project?.disclaimer.sponsorFelony],
    bio: sponsorProfile?.description.formatAsPlainText(),
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values));
      }}
    >
      {(props) => (
        <SponsorProfileForm
          values={props.values}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          initialValues={initialValues}
        />
      )}
    </Formik>
  );
};

export default SponsorProfile;
