import {useEffect, useState, useMemo} from 'react';
import RadioGroup from 'shared/components/RadioGroup';
import NumericInput from 'shared/components/NumericInput';
import {MaxValidationLimits, PrepayPenaltySubsteps, QuoteTermsSteps, RecourseSubsteps} from 'core/_consts';
import NextButton from 'shared/components/NextButton';
import {validateNumericInput} from 'core/utils';
import {setAvailableRecourse} from 'store/quote-terms-service/quoteTermsSlice';
import {useSelector} from 'react-redux';
import {selectAvailableRecourse} from 'store/quote-terms-service/quoteTermsSelector';
import {nextButtonClickHandler, NextButtonRefType} from 'pages/LenderView/helpers';

enum AvailableRecourseOptions {
  nonRecourse = 'non-recourse',
  fullRecourse = 'full recourse',
  partialRecourse = 'partial recourse',
}

const handleSelectedValue = (value: string) => {
  switch (value) {
    case AvailableRecourseOptions.nonRecourse:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.prepayPenalty],
        substep: PrepayPenaltySubsteps[PrepayPenaltySubsteps.anyPenalty],
      };
    default:
      return {
        step: QuoteTermsSteps[QuoteTermsSteps.recourse],
        substep: RecourseSubsteps[RecourseSubsteps.burnOffAvailable],
      };
  }
};

const AvailableRecourse = () => {
  const defaultValues = useSelector(selectAvailableRecourse);
  const [selectedRecourse, setSelectedRecourse] = useState<Array<string>>(defaultValues.selectedRecourse);
  const [partialRecoursePercent, setPartialRecoursePercent] = useState(defaultValues.partialRecoursePercent);
  const [nextButtonRef, setNextButtonRef] = useState<NextButtonRefType>(null);

  const isPartialRecourseSelected = useMemo(
    () => selectedRecourse.includes(AvailableRecourseOptions.partialRecourse),
    [selectedRecourse]
  );

  useEffect(() => {
    if (selectedRecourse.length && !isPartialRecourseSelected) nextButtonClickHandler(nextButtonRef);
  }, [selectedRecourse, isPartialRecourseSelected]);

  return (
    <div className="FormSlide">
      <p className="FormSlide__title mb35">Is there recourse?</p>
      <RadioGroup
        isAnyValueSelected={selectedRecourse}
        setIsAnyValueSelected={setSelectedRecourse}
        radioButtonTitles={Object.values(AvailableRecourseOptions)}
        nextButtonTrigger={() => nextButtonClickHandler(nextButtonRef)}
      />
      {isPartialRecourseSelected && (
        <div className="FormSlide__form">
          <p className="FormSlide__subTitle mb25 mt25">What percent?</p>
          <NumericInput
            width="80px"
            sign="%"
            defaultValue={partialRecoursePercent || ''}
            preventionLimit={MaxValidationLimits.percent}
            onValueChange={(values) => setPartialRecoursePercent(Number(values.value))}
            isValid={validateNumericInput(Number(partialRecoursePercent), MaxValidationLimits.percent)}
          />
        </div>
      )}
      <NextButton
        setNextButtonRef={setNextButtonRef}
        isHidden={!isPartialRecourseSelected && !defaultValues.selectedRecourse.length}
        isDisabled={
          !selectedRecourse.length ||
          (isPartialRecourseSelected &&
            !validateNumericInput(Number(partialRecoursePercent), MaxValidationLimits.percent))
        }
        slideData={{
          [RecourseSubsteps[RecourseSubsteps.availableRecourse]]: {
            selectedRecourse,
            partialRecoursePercent: isPartialRecourseSelected ? partialRecoursePercent : null,
          },
        }}
        action={setAvailableRecourse}
        nextSlide={handleSelectedValue(selectedRecourse[0])}
      />
    </div>
  );
};

export default AvailableRecourse;
